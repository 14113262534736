import {
    CHANGE_ACTION,
    MANAGEMENT_GRANTED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    USER_LOGINED
  } from "../actions/types";
  
  const initialState = {
    token: localStorage.getItem("DCtoken"),
    isAuthenticated: false,
    loading: true,
    // isAuthenticated: true,
    // loading: false,
    isGranted: false,
    action: "login"
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CHANGE_ACTION:
        return {
          ...state,
          action: payload
        }
      case LOGIN_SUCCESS:
        localStorage.setItem("DCtoken", payload.token);
        return {
          ...state,
          ...payload,
          isAuthenticated: true,
          loading: false,
        };
      case USER_LOGINED:
        return {
          ...state,
          ...payload
        }
      case MANAGEMENT_GRANTED: 
        return {
          ...state,
          isGranted: payload,
        }
      case LOGIN_FAIL:
        // case ACCOUNT_DELETED:
        // localStorage.removeItem("GPtoken");
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          isGranted: false,
          loading: true,
        };
      case LOGOUT:
        localStorage.removeItem("DCtoken");
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          isGranted: false,
          loading: true,
        };
      case AUTH_ERROR:
        // localStorage.removeItem("GPtoken");
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          isGranted: false,
          error: payload
        };
      default:
        return state;
    }
  }
  