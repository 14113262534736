import axios from "axios";
import { setAlert } from "./alert";

import {
    GET_TRANSACTION,
    GET_TRANSACTIONS,
    TRANSACTION_ERROR,
    TRANSACTIONS_ERROR,
    NEW_TRANSACTION,
    APPROVE_TRANSACTION,
    DELETE_TRANSACTION,
    GET_AGENT_TRANSACTIONS
} from './types';

// NEW TRANSACTION
export const newTransaction = (formdata) => async dispatch => {
    console.log(formdata);
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

    const body = JSON.stringify(formdata);

    try{
        const res = await axios.post(
            // 'http://localhost:5000/api/transactions', 
            '/api/transactions',
            body, 
            config);
        dispatch({
            type: NEW_TRANSACTION,
            payload: res.data
        })
        dispatch(setAlert('支付成功,请等待管理员审核','success'))
    }catch(err){
        dispatch({
            type: TRANSACTION_ERROR,
            payload: err.response
        })      
    }
}

// GET TRANSACTION
export const getTransaction = (id) => async dispatch => {
    try {
        const res = await axios.get(`/api/transactions/${id}`);
        // const res = await axios.get(`http://localhost:5000/api/transactions/${id}`);
        // console.log('getTransaction called in action');

        dispatch({
            type: GET_TRANSACTION,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: TRANSACTION_ERROR,
            payload: err.response
        })
    }
    
    document.getElementById('waiting-spinner').classList.add('display-none')
}

// GET TRANSACTIONS
export const getUserTransactions = () => async dispatch => {
    try {
        let res;
        
        res = await axios.get('/api/transactions');
        
        // const res = await axios.get('http://localhost:5000/api/transactions');
        // console.log('getUserTransactions called in action');

        dispatch({
            type: GET_TRANSACTIONS,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: TRANSACTIONS_ERROR,
            payload: err.response
        })
    }
    
    document.getElementById('waiting-spinner').classList.add('display-none')
}

// GET AGENT WITHDRAW REQUESTS
export const getAgentWithdrawRequests = () => async dispatch => {
    try {
        const res = await axios.get('/api/transactions/agents');
        // const res = await axios.get('http://localhost:5000/api/transactions/agents');
        // console.log('getAgentWithdrawRequests called in action');

        dispatch({
            type: GET_AGENT_TRANSACTIONS,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: TRANSACTIONS_ERROR,
            payload: err.response
        })     
    }
}

// APPROVE TRANSACTION
export const approveTransaction = (id) => async dispatch => {
    try {
        const res = await axios.post(`/api/transactions/approve/${id}`);
        // const res = await axios.post(`http://localhost:5000/api/transactions/approve/${id}`);
        // console.log('approveTransaction called in action');
        
        dispatch({
            type: APPROVE_TRANSACTION,
            payload: res.data
        })
        dispatch(setAlert('交易已审核通过', 'success'))
    }catch(err){
        dispatch({
            type: TRANSACTIONS_ERROR,
            payload: err.response
        })     
    }
}


// DELETE TRANSACTION
export const deleteTransaction = (id) => async dispatch => {
    // console.log("deleteTransaction called in action.")
      try{
          const res = await axios.delete(`/api/transactions/${id}`);
          // await axios.delete(`http://localhost:5000/api/transactions/${id}`);
  
          dispatch({
              type: DELETE_TRANSACTION,
              payload: id
          });
          dispatch(setAlert(res.data.msg, "success"));
      }catch (err) {
          dispatch({
            type: TRANSACTION_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status
            }
          });
        }
        document.getElementById('waiting-spinner').classList.add('display-none')
  }

//  CREATE NEW TRANSACTION (AUTOMATICLY GENERATED)
