import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setCategory } from '../../actions/blogs'
import { useNavigate } from 'react-router-dom'

const BlogNav = ({
    category,
    setCategory,
    user
}) => {

    useEffect(()=>{},[user, category])

    const navigate = useNavigate();

    const onClick = e => {
        const category = e.target.id;
        setCategory(category);
        navigate(`/blog/c/${category}`);
      }

    const onNewBlog = e => {
      navigate('/blog/p/new')
    }


  return (
    <ul id="blogs-nav" className="mt-1 border-top border-bottom blogs-nav">
      <li
        id="latest"
        className={category === 'latest' ? 'tablinks-small ml-1 active' : "tablinks-small ml-1"}
        onClick={(e) => onClick(e)}
      >
        最新
      </li>
      <li
        id="experience"
        className={category === 'experience' ? 'tablinks-small active' : "tablinks-small"}
        onClick={(e) => onClick(e)}
      >
        游玩快报
      </li>
      <li
        id="strategy"
        className={category === 'strategy' ? 'tablinks-small active' : "tablinks-small"}
        onClick={(e) => onClick(e)}
      >
        砂舞江湖
      </li>
      <li
        id="clubs-index"
        className={category === 'clubs-index' ? 'tablinks-small active' : "tablinks-small"}
        onClick={(e) => onClick(e)}
      >
        舞厅评测
      </li>
      <li
        id="announcement"
        className={category === 'announcement' ? 'tablinks-small active' : "tablinks-small"}
        onClick={(e) => onClick(e)}
      >
        本站公告
      </li>
      <li
        id="recruit"
        className={category === 'recruit' ? 'tablinks-small active' : "tablinks-small"}
        onClick={(e) => onClick(e)}
      >
        招聘
      </li>
      {!user.userLoading && (user.user.accountType==="super" || user.user.accountType==="admin" || user.user.accountType==="manager") ? <li id="new-blog-btn" className="tablinks-small link-btn-pink" onClick={e=>onNewBlog(e)}>发布</li> : ''}
    </ul>
  )
}

BlogNav.propTypes = {
    category: PropTypes.string.isRequired,
    setCategory: PropTypes.func.isRequired,
    user: PropTypes.object
}

const mapStateToProps = state => ({
    category: state.blog.category,
    user: state.user,
})

export default connect(mapStateToProps, {setCategory})(BlogNav)