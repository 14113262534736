import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import DancersItem from '../dancer/DancersItem'
import { deleteFavDancer } from '../../actions/auth'

const FavoriteDancers = ({favoriteDancers, dancers, deleteFavDancer}) => {
    const dancerArray = dancers.filter(item => favoriteDancers.includes(item._id))
    useEffect(()=>{},[dancers, dancerArray])
    // console.log(dancerArray)
    // const dancerArray = [{_id: "1"},{_id: "2"},{_id: "3"},{_id: "4"}]
  return dancerArray.length !== 0 ? (
    <div className='favoriteDancers'>
        {dancerArray.map(item=> {
            // console.log(item);
            return <DancersItem dancer={item} key={`fav-${item._id}`}/>
        })}
    </div>
  ) : "尚无已收藏舞女"
}

FavoriteDancers.propTypes = {
    favoriteDancers: PropTypes.array.isRequired,
    dancers: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    dancers: state.dancer.dancers
})

export default connect(mapStateToProps, {})(FavoriteDancers)