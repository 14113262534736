import React from 'react'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

import { scrollFunction, topFunction } from '../../functions/functions'

const Footer = () => {
    const screen = window.screen.width;

    const onMouseLeaveSiteHeader = e => {
        const largeElementStyle = document.getElementById('site-footer-large').style;
        const elementStyle = document.getElementById('site-footer-small').style;
        elementStyle.color = 'white';
        elementStyle.backgroundColor = '#fe8291';
        largeElementStyle.color = 'white';
    }

    const onMouseOverSiteHeader = e => {        
        const largeElementStyle = document.getElementById('site-footer-large').style;
        const elementStyle = document.getElementById('site-footer-small').style;
        elementStyle.color = '#fe8291';
        elementStyle.backgroundColor = 'white';
        largeElementStyle.color = '#fe8291';
    }

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
        scrollFunction();
    };

    return (
        <div className="footer">
            <button onClick={(e) => topFunction()} id="back-to-top" title="Go to top">
                <span>
                    <FontAwesomeIcon icon={faChevronUp} />
                </span>
                <span>Top</span>
            </button>
            <div className="footer-container">
            <p className='footer-content-wrapper'>
                <NavLink className='footer-link' to="/"  reloadDocument onMouseOver={e=>onMouseOverSiteHeader(e)} onMouseLeave={e=>onMouseLeaveSiteHeader(e)}>
                    <span className='footer-content' id='site-footer-large'>苦笑</span><small className='footer-content-small' id='site-footer-small'>人间</small><span className='footer-content-smaller ml-1'>舞厅资讯站</span>
                </NavLink>
            </p>
            
            {/* <p className={screen > 699 ? "footer-content footer-content-small text-align-left ml-5" : "footer-content footer-content-small"}>Copyright@</p> */}
            </div>
        </div>
    )
}


export default Footer;
