import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { withdrawForAgent } from '../../actions/management'


const AgentListItem = ({
    agent, 
    withdrawForAgent
    // edit=false,
    // updateUserAccountType
}) => {
    
    const [formData, setFormData] = useState({
        _id: agent ? agent._id : '',
        amount: 0
    })

    useEffect(()=>{console.log(formData)},[agent, formData])

    // const onUpdateUser = e => {
    //     e.preventDefault();
    //     updateUserAccountType(formData);
    // }


    // const userAccountTypeInput = edit ? (
    //     <form className='user-accountType-form' id={`change-userAccount-form-${agent._id}`} onSubmit={e=>onUpdateUser(e)}>
    //         <select name="change-userAccount-select" id={`change-userAccount-select-${agent._id}`} defaultValue={agent.accountType} onChange={e => setFormData({...formData, accountType: e.target.value})}>
    //             <option value="manager">总管理员/manager</option>
    //             <option value="admin">管理员/admin</option>
    //             <option value="agent">宣传员/agent</option>
    //             <option value="vip">VIP</option>
    //             <option value="normal">普通会员</option>
    //         </select>
    //         {formData.accountType === "vip" ? <select name="change-vipType-select" id={`change-vipType-select-${agent._id}`} defaultValue='yearly' onChange={e => setFormData({...formData, vipType: e.target.value})}>
    //             <option value="yearly">年费会员</option>
    //             <option value="monthly">月费会员</option>
    //         </select> : ""}
    //         <button type="submit" className='btn btn-pink'>更新</button>
    //     </form>
    // ) : agent.accountType

    const onWithdrawAmountChange = e => {
        const amount = e.target.value;
        setFormData({...formData, amount: amount}) 
    }

    const onWithdraw = e => {
        e.preventDefault();
        withdrawForAgent(formData);
    }

  return (
        <Fragment>
            <th scope='col' className='user-item-username'>{agent.username}</th> 
            <th scope='col' className='user-item-discount-code'>{agent.invitationCode}</th> 
            <th scope='col' className='user-item-balance'>{agent.totalCommission ? agent.totalCommission : 0}</th> 
            <th scope='col' className='user-item-withdrew'>{agent.totalWithdrew ? agent.totalWithdrew : 0}</th> 
            <th scope='col' className='user-item-withdrawable'>{agent.totalAvailable}</th> 
            <th scope='col' className='user-item-daily-amount'>{agent.dailyCases? agent.dailyCases : 0}</th> 
            <th scope='col' className='user-item-weekly-amount'>{agent.weeklyCases ? agent.weeklyCases : 0}</th> 
            <th scope='col' className='user-item-actions'>
                <form id={`${agent._id}-withdraw-form`}  onSubmit={e => onWithdraw(e)}>
                    <input type="number" min='1' max={agent.totalAvailable} name={`${agent._id}-withdraw-input`} id={`${agent._id}-withdraw-input`} className='table-form-input' onChange={e=> onWithdrawAmountChange(e)}/>
                    <button type="submit" className='btn btn-pink agent-tab-withdraw-btn'>提现</button>
                </form>    
            </th>         
        </Fragment>
  )
}

AgentListItem.propTypes = {
    agent: PropTypes.object.isRequired,
    withdrawForAgent: PropTypes.func.isRequired
}

export default connect(null, {withdrawForAgent})(AgentListItem)