import React, {useEffect, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'

import {reserveDancer} from '../../actions/management'

const WeChatReserve = ({
    user,
    dancerID,
    weChat,
    show
}) => {

    const startUpContent =            
            weChat === true || weChat === 'true' ? <div className="info-box-content">
                <p className='text-bold'>自动预约系统开发中，暂时用如下方式:</p>
                <p>（1）请确认注册时填写正确微信号，可随时在个人页面更正；</p>
                <p>（2）点击确定后客服会加您微信并和舞女拉群；</p>
                <p>（3）加群后给舞女转账xx元，舞女会加您微信；</p>
                <p>（4）预约更长约跳时间甚至预付定金，可提高成功率；</p>
                <p>（5）请评估舞女信用，降低舞女爽约、随意改约等风险。</p>
                <div className="grid-two">
                    <button className='btn btn-pink width-100 m-auto' onClick={e => onReserveDancer()}>
                        确定
                    </button>
                    <button className='btn btn-pink width-100 m-auto' onClick={e => onClose(`wechat-reserve-${dancerID}`)}>
                        取消
                    </button>
                </div>
            </div> : <div className="info-box-content">
                <p>请在首页筛选选择“在线可约”，女舞客尚不可预约，请点击“收藏”，收藏人数多者会尽快提供预约。</p>
                <div className="display-flex">
                    <button className='btn btn-pink width-100 mx-auto' onClick={e => onClose(`wechat-reserve-${dancerID}`)}>
                        确定
                    </button>
                </div>
                    
            </div>


    const [content, setContent] = useState(startUpContent)
    
    useEffect(()=>{},[content,weChat,dancerID])

    const onReserveDancer = () => {
        setContent(            
            <div className="info-box-content">
                <p>自动预约系统升级中，即将开放使用，敬请期待！</p>
                <div className="display-flex">
                    <button className='btn btn-pink width-100 mx-auto' onClick={e => onClose(`wechat-reserve-${dancerID}`)}>
                        确定
                    </button>
                </div>
            </div>
        )
    }

    const onClose = (id) => {
        const infoBox = document.getElementById(id);
        infoBox.classList.add('display-none');
        setContent(startUpContent);
    }
    

  return (
    <div className='please-buy-vip display-none' id={`wechat-reserve-${dancerID}`}>
        <div className="info-box">
            <div className="info-box-title">
                <h2>女舞客预约</h2>
                <button className="btn btn-close-white" onClick={e=>onClose(`wechat-reserve-${dancerID}`)}>
                    <FontAwesomeIcon icon={faSquareXmark} />
                </button>
            </div>
            {content}
            {/* <div className="info-box-content">
                <p>（1）请确认注册时填写正确微信号，可随时在个人页面更正；</p>
                <p>（2）点击确定后客服会加您微信并和舞女拉群；</p>
                <p>（3）加群后给舞女转账xx元，舞女会加您微信；</p>
                <p>（4）预约更长跳舞时间甚至预定金，可提高预约成功率；</p>
                <p>（5）请评估舞女信用，降低舞女爽约、随意改约等风险。</p>
                <div className="grid-two">
                    <button className='btn btn-pink width-100 m-auto' onClick={e => onReserveDancer(dancerID)}>
                        确定
                    </button>
                    <button className='btn btn-pink width-100 m-auto' onClick={e => onClose(`wechat-reserve-${dancerID}`)}>
                        取消
                    </button>
                </div>
            </div> */}
        </div>
    </div>
  )
}


WeChatReserve.propTypes = {
    dancerID: PropTypes.string.isRequired,
    user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.user.user
})

export default connect(mapStateToProps, {})(WeChatReserve)
