import React, { useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Moment from "react-moment";
import moment from 'moment';

import { getUserTransactions, deleteTransaction } from '../../actions/transaction'

import Spinner from '../layout/Spinner';

const Transactions = ({
    userID,
    transaction:{
        transactions,
        transactionsLoading
    },
    getUserTransactions
}) => {

    // useEffect(()=>{

    // },[getUserTransactions, deleteTransaction])
    useEffect(()=>{
        getUserTransactions();
    },[getUserTransactions])
    useEffect(()=>{},[transactions, transactionsLoading])

  return !transactionsLoading ? ( transactions.length > 0 ? 
    <div className="transactions">
        {transactions.map( item => (
            <div className="transaction-item" key={item._id}>
                <p className='transaction-item-detail'>￥<span className={item.amount < 0 ? 'text-pink' : ''}>{item.amount}</span><Moment className='ml-1' format="YYYY/MM/DD">{moment(item.date)}</Moment>{!item.approved ? <span className='text-pink ml-1 p-2px bordered-danger rounded'>未确认</span> : ""}</p>
                {/* <span className='transaction-item-btns'>通过</span> */}
            </div>
        ))}
    </div> : <div className="transaction-item">
                <p className='transaction-item-detail'>尚无交易记录</p>
            </div>
  ) : <Spinner />
}

Transactions.propTypes = {
    userID: PropTypes.string.isRequired,
    transaction: PropTypes.object.isRequired,
    getUserTransactions: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    transaction: state.transaction,
})

export default connect(mapStateToProps, {getUserTransactions, deleteTransaction})(Transactions)