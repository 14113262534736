import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux'

import { deleteBlog, getBlog } from '../../actions/blogs'
import Spinner from '../layout/Spinner';
import Moment from 'react-moment';
import BlogNav from './BlogNav';

const Blog = ({
    blog,
    category,
    blogLoading,
    getBlog,
    deleteBlog,
    isGranted
}) => {
   
  const { blogID } = useParams();
    // console.log(blogID)
  
  const navigate = useNavigate();

  useEffect(()=>{
    getBlog(blogID);
  },[getBlog, blogID])

  useEffect(()=>{
    const articleBody = document.getElementById('article-body')
    if(articleBody && blog.content){
        console.log(blog.date.slice(0,10))
        articleBody.innerHTML = blog.content;
    }
  },[blog, blogLoading])

  const onEditBlog = (e,blogID) => {
    e.preventDefault();
    navigate(`/blog/edit/${blogID}`);
  }

  const onConfirmDeleteBlog = (e) => {
    e.preventDefault();
    document.getElementById('confirmation-box-delete-blog').classList.remove('display-none');
  }

  const onDeleteBlog = (e,blogID) => {
    e.preventDefault();
    document.getElementById('confirmation-box-delete-blog').classList.add('display-none');
    deleteBlog(blogID);
    setTimeout(()=>{
      navigate(`/blog/c/${category}`)
    },500)
  }

  const onCancelDeleteBlog = (e) => {
    e.preventDefault();
    document.getElementById('confirmation-box-delete-blog').classList.add('display-none');
  }



  return blog && !blogLoading ? (
    <div className='container blogs-container'>
        <BlogNav />
        <img src={`/uploads/blog/${blog.coverImg}`} alt=""  className='blog-coverImg'/>
        <h2 className='blog-title'>{blog.title}</h2>
        <div className="blog-title-footer">
            <p className="small">
                {/* <Moment format='YYYY年YY月DD日'>{blog.date.slice(0,10)}</Moment> */}
                发表时间：{blog.date.slice(0,4)}年{blog.date.slice(5,7)}月{blog.date.slice(8,10)}日
            </p>
            <p className="small">
                {/* <Moment format='YYYY年YY月DD日'>{blog.date.slice(0,10)}</Moment> */}
                {blog.dateEdit ? `修改时间：${blog.dateEdit.slice(0,4)}年${blog.dateEdit.slice(5,7)}月${blog.dateEdit.slice(8,10)}日` : `修改时间：${blog.date.slice(0,4)}年${blog.date.slice(5,7)}月${blog.date.slice(8,10)}日`}
            </p>
            <p className="small">作者：{blog.author}</p>
        </div>
        <div className="blog-content" id='article-body'>
        </div>
        {isGranted ? 
        <Fragment>
        <div className="btns">
          <button className="btn btn-pink" onClick={e=>onEditBlog(e,blog._id)}>编辑</button>
          <button className="btn btn-pink ml-2" onClick={e => onConfirmDeleteBlog(e)}>删除</button>
        </div><div className='confirmation-box-container display-none' id='confirmation-box-delete-blog'>
          <div className="confirmation-box info-box">
            <h1>三思而行</h1>
            <div className="confirmation-box-content info-box-content">
              <p>确认删除标题为"{blog.title}"的文章吗？</p>
              <div className="btns">
                <button className="btn btn-pink" onClick={e=>onCancelDeleteBlog(e)}>放弃</button>
                <button className="btn btn-pink ml-2" onClick={e => onDeleteBlog(e, blog._id)}>删除</button>
              </div>
            </div>
          </div>
        </div></Fragment> : ""}
    </div>
  ) : <Spinner />
}

Blog.propTypes = {
    blog: PropTypes.object,
    blogLoading: PropTypes.bool.isRequired,
    getBlog: PropTypes.func.isRequired,
    isGranted: PropTypes.bool.isRequired,
    deleteBlog: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired
}

const mapStateToProps = (state) =>({
    blog: state.blog.blog,
    blogLoading: state.blog.blogLoading,
    category: state.blog.category,
    isGranted: state.auth.isGranted
})

export default connect(mapStateToProps, {getBlog, deleteBlog})(Blog)