import {
    NEW_RESERVE,
    GET_RESERVE,
    GET_RESERVES,
    DELETE_RESERVE,
    RESERVE_PROCESSED,
    RESERVE_ERROR,
    RESERVES_ERROR,
    PHOTOS_ERROR,
    VIDEOS_ERROR,
    DANCERS_COUNT_ERROR,
    GET_DANCERS_COUNT,
    NEW_USER,
    CREATE_NEW_USER_ERROR,
    GET_DANCER_FOR_EDITTING,
    DANCER_FOR_EDITTING_ERROR,
    GET_AGENTS,
    GET_AGENTS_ERROR,
    GET_USERS_COUNT,
    USERS_COUNT_ERROR,    
    GET_USERS,
    GET_USERS_ERROR,
    DELETE_USER,
    UPADTE_USER_ACCOUNTTYPE,
    GET_TRANSACTIONS_MANAGEMENT,
    TRANSACTIONS_MANAGEMENT_ERROR,
    GET_AGENT_TRANSACTIONS,
    APPROVE_TRANSACTION,
    DELETE_TRANSACTION,
    TOTAL_INCOME,
    TOTAL_INCOME_ERROR,
    AGENT_WITHDRAW,
    DOWNLOAD_DANCERS,
    GET_DISCOUNTCODES,
    POST_DISCOUNTCODE_LIST,
    DISCOUNTCODE_ERROR
} from '../actions/types'

import { setAlert } from '../actions/alert';


const initialState = {
    agents: {},
    agentsLoading: true,
    users:[],
    usersLoading: true,
    editDancer:null,
    editDancerLoading: true,
    reserve: null,
    reserveLoading: true,
    reserves: [],
    reservesLoading: true,
    fileErrors: null,
    dancersCount: 0,
    usersCount: 0,
    totalIncome: 0,
    transactions: [],
    transactionsLoading: true,
    agentTransactions: [],
    agentTransactionsLoading: true,
    discountCodes: [],
    discountCodesLoading: true,
    errors: []
}

export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case GET_DISCOUNTCODES:
            return {
                ...state,
                discountCodes: payload.discountCodes
            }
        case POST_DISCOUNTCODE_LIST: 
            return {
                ...state,
                discountCodes: payload.discountCodes
            }
        case DISCOUNTCODE_ERROR:
            return {
                ...state,
                discountCodes:[],
                discountCodesLoading: true,
                errors: [...state.errors, payload]
            }
        case TOTAL_INCOME:
            return {
                ...state,
                totalIncome: payload,
            }
        case TOTAL_INCOME_ERROR:
            return {
                ...state,
                errors: [...state.errors, payload]
            }
        case NEW_USER:
            return {
                ...state,
                users: [
                    ...state.users,
                    payload
                ],
                usersLoading: false
            }
        case GET_USERS:
            return {
              ...state,
              usersLoading: false,
              users: payload
            }
        case GET_USERS_ERROR:
            return {
                ...state,
                users: [],
                usersLoading: true,
                errors: [...state.errors, payload]
            }
        case UPADTE_USER_ACCOUNTTYPE:
            return {
              ...state,
              users: [
                payload, 
                ...state.users.filter(item=>item._id !== payload._id)
              ],
              usersLoading: false
            }
        case DELETE_USER:
            return {
              ...state,
              usersLoading: false,
              users: [...state.users.filter(item => item._id !== payload)]
            }
        case GET_AGENTS:
            return {
                ...state,
                agents: payload.sort((a,b) => b.totalAvailable - a.totalAvailable),
                agentsLoading: false
            }
        case GET_AGENTS_ERROR: 
            return {
                ...state,
                errors: [...state.errors, payload],
                agentsLoading: true
            }
        case AGENT_WITHDRAW: 
            return {
                ...state,
                agents: [...state.agents.filter(a => a._id !== payload._id), payload].sort((a,b) => b.totalAvailable - a.totalAvailable)
            }
        case GET_DANCERS_COUNT:
            return {
                ...state,
                dancersCount: payload
            }
        case DANCERS_COUNT_ERROR:
        case USERS_COUNT_ERROR:
            return {
                ...state,
                errors: [...state.errors, payload]
            }
        case GET_USERS_COUNT:
            return {
                ...state,
                usersCount: payload
            }
        case GET_DANCER_FOR_EDITTING: 
            return {
                ...state,
                editDancer: payload,
                editDancerLoading: false
            }
        case DANCER_FOR_EDITTING_ERROR:
            return {
                ...state,
                editDancerLoading: true,
                errors: [...state.errors, payload]
            }
        case PHOTOS_ERROR:
        case VIDEOS_ERROR: 
            return {
                ...state,
                fileErrors: payload
            }
        case NEW_RESERVE:
            return {
                ...state,
                reserve: payload,
                reserves: [...state.reserves.filter(item=> item._id !== payload._id), payload],
                reserveLoading: false,
                reservesLoading: false
            }
        case GET_RESERVE:
            return {
                ...state,
                reserve: payload,
                reserveLoading: false,
            }
        case GET_RESERVES:
            return {
                ...state,
                reserves: payload,
                reservesLoading: false
            }
        case DELETE_RESERVE:
            return {
                ...state,
                reserves: [...state.reserves.filter(item=> item._id !== payload._id)],
                reservesLoading: false,
            }
        case RESERVE_ERROR:
            return {
                ...state,
                errors: [...state.errors, payload],
                reserveLoading: true
            }
        case RESERVES_ERROR:
            return {
                ...state,
                errors: [...state.errors, payload],
                reservesLoading: true
            }
        case RESERVE_PROCESSED:
            return {
                ...state,
                reserve: payload,
                reserves: [...state.reserves.filter(item => item._id !== payload._id), payload],
                reserveLoading: false,
                reservesLoading: false
            }
        case CREATE_NEW_USER_ERROR:
            return {
                ...state,
                errors: [...state.errors, payload]
            }
        case GET_TRANSACTIONS_MANAGEMENT:
            return {
                ...state,
                transactions: payload,
                transactionsLoading: false
            }
        case TRANSACTIONS_MANAGEMENT_ERROR:
            return {
                ...state,
                transactionsLoading: true,
                errors: [...state.errors, payload]
            }
        case GET_AGENT_TRANSACTIONS:
            return {
                ...state,
                agentTransactions:payload,
                agentTransactionsLoading: false
            }
        case APPROVE_TRANSACTION:
            return {
                ...state, 
                transactions: [...state.transactions.filter(trans => trans._id !== payload._id), payload],
                transactionsLoading: false
            }
        case DELETE_TRANSACTION:
            return {
                ...state,
                transactionsLoading: false,
                transactions: state.transactions.filter(item => item._id !== payload)
            }
        default: 
            return state;
    }
}

