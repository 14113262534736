import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Quill from "quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Resizer from "react-image-file-resizer";

import { setAlert } from "../../actions/alert";
import { createBlog, getBlog, clearBlog, uploadImage } from "../../actions/blogs";
import PlaceHolder from "../../img/imgPlaceholder.png";
import Spinner from "../layout/Spinner";
import BlogNav from "./BlogNav";

const CreateBlog = ({ auth, user, createBlog, match, uploadImage, blog }) => {
  const {blogID} = useParams();

  useEffect(()=>{
    if(blogID){
      getBlog(blogID)
    }
  },[getBlog, blogID])

  useEffect(()=>{},[blog]);

  const [formData, setFormData] = useState({
    _id: blog ? blog._id : "",
    title: blog ? blog.title : "",
    author: blog ? blog.author : "",
    date: blog ? new Date(blog.date) : new Date(),
    // date: new Date(),
    category: blog ? blog.category : "",
    coverImg: blog ? blog.coverImg : "",
    content: blog ? blog.content : "",
  });

  const [imageToUpload, setImageToUpload ] = useState({
    coverImg:''
  })

  useEffect(()=>{
    if(user && user.username){setFormData({
      ...formData, 
      author: user.username
    })}
  },[user])

  useEffect(()=>{
    console.log("formData: ", formData);
    // console.log('imageToUpload: ', imageToUpload);
    // console.log(`${date.getFullYear()}-${date.getMonth() <= 9 ? `0${date.getMonth()}` : date.getMonth()}-${date.getDate()}`)
  },[formData, imageToUpload])

  const {
    // _id,
    title,
    author,
    date,
    category,
    coverImg,
    content,
  } = formData;

  const categories = [
    { engName: "experience", name: "游玩快报" },
    { engName: "strategy", name: "砂舞江湖" },
    { engName: "clubs-index", name: "舞厅列表" },
    { engName: "announcement", name: "本站公告" },
    { engName: "recruit", name: "招聘" }
  ];

  //   let coverImageFormDate;
  const onImageChange = async (e) => {
    e.preventDefault();
    console.log("Image changed");
    const imageFile = e.target.files[0];

    if (imageFile) {
      const reader = new FileReader();
      const preview = document.getElementById("article-coverimg-preview")
      reader.addEventListener("load", function(){
        preview.src=reader.result;
        Resizer.imageFileResizer(imageFile, 600, 600, "png", 100, 0, (uri) => {
    
        setImageToUpload({
          ...imageToUpload,
          coverImg: uri
        })
        setFormData({
          ...formData,
          coverImg: uri.name,
          // coverImg: imageFile.name
        });
      },'file');  


      })
      reader.readAsDataURL(imageFile)

      
    } else {
      return;
    }
  };

  const onBlogFormChange = (e) => {
    e.preventDefault();
    const name = e.target.name;

    console.log("setFormData called in onBlogFormChange");
    if(name === "date"){
      setFormData({
        ...formData,
        date: new Date(e.target.value),
      });
    }else{
      setFormData({
        ...formData,
        [name]: e.target.value,
      });
    }
    // setAlert("formData changed", "success");
  };

  const onEditorChange = (value, delta, src, editor) => {
    // console.log(e.target.getContents());
    // console.log("setFormData called in onEditorChange");
    // console.log("formData right before onEditorChange setFormData", formData);
    // console.log(src);
    // if (src == "user" && value !== content) {
      setFormData({
        ...formData,
        content: value,
      });
    // }

    console.log('editor value changed',value)
  };

  const resizeAndSetImageToUpload = async () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    // input.setAttribute('multiple', 'multiple')
    input.click();
    input.onchange = async () => {
      const editor = editorRef.current.getEditor();
      const cursorPosition = editor.getSelection().index;//获取当前光标位置
      const reader = new FileReader();
      console.log('editor: ', editor.editor);
      // Array.from(input.files).forEach(item => {
      await Resizer.imageFileResizer(input.files[0], 1200, 600, "png", 100, 0, (uri) => {
        console.log("uri: ", uri);
        // setImageToUpload({
        //   ...imageToUpload,
        //   contentImg: [...imageToUpload.contentImg, uri]
        // });
        const imageFileFormData = new FormData();
        imageFileFormData.append('image', uri)
        uploadImage(imageFileFormData);
        // reader.addEventListener("load", function(){
        console.log(cursorPosition);
          // editor.insertEmbed(cursorPosition, "image", reader.result);//插入图片
        setTimeout(() => {
          editor.insertEmbed(
            cursorPosition, 
            "image", 
            reader.result
            // `/uploads/blog/${uri.name}`
          );//插入图片
          editor.setSelection(cursorPosition + 1);//光标位置加1
        },1000)  
        // })
        reader.readAsDataURL(uri)
      },'file');
      
    }
  }

  const editorRef = useRef(null);
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ size: ["small", false, "large","huge"] }], // custom dropdown
        // [{ size: [1,2,3,,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,109,20,21,22,23,24,25,26,27,28,29,30]}],
        [{ header: [1, 2, 3, 4] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ align: [] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        ["image"],
        ["clean"], // remove formatting button
      ],
      handlers: {
        image: resizeAndSetImageToUpload,
      }
    }

  }),[]);



  //   const formats = [
  //     "size",
  //     "header",
  //     "bold",
  //     "italic",
  //     "underline",
  //     "strike",
  //     "align",
  //     "color",
  //     "background",
  //     "list",
  //     "script",
  //     "image",
  //     "clean",
  //   ];

  //   useEffect(() => {
  //     if (!editor.quill) {
  //     const container = document.getElementById("editor");
  //       setEditor({
  //         quill: new Quill(container, {
  //           modules: { toolbar: toolbarOptions },
  //           theme: "snow",
  //         }),
  //       });
  //     } else {
  //       editor.quill.on("text-change", function () {
  //         console.log(editor.quill.getContents());
  //       });
  //     }
  //   }, []);

  // function createKnowhow(edit) {
  //   console.log("formData for createKnowhow", formData);
  //   article ? createBlog(formData, edit) : createBlog(formData);
  // }

  const onBlogSubmit = async (e) => {
    e.preventDefault();
    console.log('blog submit clicked')
    // delta = editor.quill.getContents();
    // setFormData({
    //   ...formData,
    //   content: delta,
    // });
    const imageFileFormData = new FormData();
    imageFileFormData.append('image', imageToUpload.coverImg)
    uploadImage(imageFileFormData);
    await createBlog(formData);
    setFormData({
      _id: '',
      title: "",
      author: "",
      date: new Date(),
      category: "",
      coverImg: "",
      content: "",
    })

  };

  return (
    <div id="blog-form-container" className="container blogs-container">
      <BlogNav />
      {/* blog form */}
      <form
        id="create-blog-form"
        className="blog-form-container"
        // onChange={(e) => onBlogFormChange(e)}
        onSubmit={(e) => onBlogSubmit(e)}
      >
          <h2 className="blog-form-title">新建博客文章</h2>
        <div className="blog-form">
          {/* article-info */}
          <div className="article-info">
            {/* title */}
            <div className="blog-form-item">
              <div className="blog-form-label">
                <label htmlFor="blog-title">
                  标题<span className="text-danger">*</span>
                </label>
              </div>
              <input
                type="text"
                name="title"
                id="blog-title"
                className="fullwidth-input"
                onChange={(e) => onBlogFormChange(e)}
                required
                placeholder="请输入文章标题"
                value={title}
              />
            </div>
              {/* category */}
            <div className="blog-form-item mb-0">
                <div className="blog-form-label">
                  <label htmlFor="blog-category">
                    分类<span className="text-danger">*</span>
                  </label>
                </div>
                <select
                  name="category"
                  id="blog-category"
                  value={category}
                  onChange={(e) => onBlogFormChange(e)}
                  required
                >
                  <option className="option" value="">-请选择-</option>
                  {categories.map((cat) => (
                    <option className="option"  key={`category-option-${cat.engName}`} value={cat.engName}>
                      {cat.name}
                    </option>
                  ))}
                </select>
            </div>
            
              {/* date */}
              {/* <div className="blog-form-flexable second-item"> */}
              <div className="blog-form-item">
                <div className="blog-form-label">
                  <label htmlFor="blog-date">
                    日期<span className="text-danger">*</span>
                  </label>
                </div>
                <input
                  type="date"
                  name="date"
                  id="blog-date"
                  // value={`${date.getFullYear()}-${date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate()}`}
                  value={date.toISOString().slice(0,10)}
                  onChange={(e) => onBlogFormChange(e)}
                />
              </div>
            {/* <div className="blog-form-item">
          </div> */}
            
          </div>
          {/* author-info */}
          {/* <div className="author-info">
            <div className="blog-form-item mb-0"> */}
              {/* author */}
              {/* <div className="blog-form-flexable">
                <div className="blog-form-label">
                  <label htmlFor="blog-author">
                    作者<span className="text-danger">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  name="author"
                  id="blog-author"
                  placeholder="请输入作者"
                  value={author}
                  onChange={(e) => onBlogFormChange(e)}
                  required
                />
              </div> */}
            {/* </div>
          </div> */}
        </div>
        {/* </form> */}
        {/* cover image form */}
        {/* <form
        className="blog-coverimg"
        onSubmit={(e) => onKnowhowImageUpload(e)}
      > */}
        <div className="blog-form-item">
          <div className="blog-form-label">
            <label htmlFor="">缩略图：</label>
          </div>
          <div className="">
            <input
              id="article-coverimg-upload"
              className="display-none"
              type="file"
              name="coverImg"
              accept="image/*"
              onChange={(e) => onImageChange(e)}
            />
            <div className="">
              <label

                htmlFor="article-coverimg-upload"
              >
                <img
                  src={blog ? `/uploads/blog/${coverImg}` : PlaceHolder}
                  alt="点击选择图片"
                  className="article-coverimg-upload"
                  id="article-coverimg-preview"
                />
              </label> 
              <div className="">
              <p className="blog-form-coverimg-note">
                <span className="text-danger">*</span>点击图片选择封面图，
                若不上传，则文中第一张图默认封面
            </p>
              </div>
            </div>
          </div>
         
          {/* <div className="blog-coverimg-submit">
            <input
            type="submit"
            className="btn btn-dark blog-upload-btn mb-1"
            name="blog-img-upload-btn"
            value="上传图片"
            onClick={(e) => onKnowhowImageUpload(e)}
          />
          </div> */}
        </div>
        {/* </form> */}

        {/* Editor */}
        <div className="article-editor">
          {/* <div id="editor"></div> */}
          <ReactQuill
            id="editor"
            ref={editorRef}
            bounds="#editor"
            modules={modules}
            value={content}
            onChange={onEditorChange}
          />
        </div>
        <div id="content-from-quill"></div>
        <div className="display-flex">
            <input
              type="submit"
              className="blog-submit btn btn-pink"
              onSubmit={(e) => onBlogSubmit(e)}
              value="发布文章"
            />
            {/* <Link
              to={
                auth.user.autherization === "adim" ? "/dashboard" : "/blog"
              }
              className="blog-submit btn btn-dark blog-upload-btn"
            >
              取消编辑
            </Link> */}
          </div>
      </form>
    </div>
  );
};

CreateBlog.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  createBlog: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  blog: PropTypes.object
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user.user,
  blog: state.blog.blog
});

export default connect(mapStateToProps, {
  createBlog, uploadImage
})(CreateBlog);
