import React,{useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import PaymentBox from './PaymentBox';
import { newTransaction } from '../../actions/transaction';
import { getDiscountCode } from '../../actions/discountCode';

import addRemarkImg from '../../img/add-remark.png';
import confirmPaymentImg from '../../img/confirm-payment.png';
import { setAlert } from '../../actions/alert';

const BuyVIP = ({
    isAuthenticated,
    user,
    newTransaction,
    getDiscountCode,
    discountCode,
    setAlert
}) => {

    const paymentAmount = {experience: 98, vip: 298, experienceDiscount: 58, vipDiscount: 198}
    const paymentBoxTitle = {experience: '体验版', vip: 'VIP版', experienceDiscount: '体验版折扣价', vipDiscount: 'VIP版折扣价'}

    useEffect(()=>{},[isAuthenticated])
    const [paymentBox, setPaymentBox] = useState('')
    useEffect(()=>{},[paymentBox])

    const [paymentFormData, setPaymentFormData] = useState({
        accountID: "",
        transactionType: '',
        amount: undefined,
        invitationCode: '',
    });
    const {transactionType, invitationCode} = paymentFormData;
    useEffect(()=>{console.log(paymentFormData)},[paymentFormData])
    useEffect(()=>{ if(user){setPaymentFormData({...paymentFormData, accountID: user._id})}},[user])
    const showLogin = () => {
        const loginBox = document.getElementById("login-box")
        loginBox.classList.remove('display-none')
    }

    const onPaymentFormChange = e => {
        e.preventDefault();
        const id = e.target.id;
        const transactionType = id.split('_')[0]
        const field = id.split('_')[1]
        // switch(field){
        //     case "invitationCode":
        //         setPaymentFormData({...paymentFormData, invitationCode: e.target.value})
        //     default:
        setPaymentFormData({...paymentFormData, [field]: e.target.value})
        // }
        // console.log(field);
    }

    const onPaymentFormSubmit = (e, id) => {
        e.preventDefault();
        console.log(paymentFormData);
        
        const element = document.getElementById(id);
        element.classList.add('display-none');

        newTransaction(paymentFormData);

    }

    const [ show, setShow ] = useState(false);



    const showPaymentBox = (paymentType) => {
        setShow(false)
        if(isAuthenticated){
            switch (paymentType) {
                case 'experience':
                    if(discountCode.codeActive){
                        setPaymentFormData({...paymentFormData, amount: paymentAmount['experienceDiscount'] ,transactionType: 'experienceDiscount'})
                    }else{
                        setPaymentFormData({...paymentFormData, amount: paymentAmount['experience'] ,transactionType: 'experience'})
                    }
                    break;
                case 'vip':
                    // if(discountCode.codeActive){
                    //     setPaymentFormData({...paymentFormData, amount: paymentAmount['vipDiscount'] ,transactionType: 'vipDiscount'})
                    // }else{
                    //     setPaymentFormData({...paymentFormData, amount: paymentAmount['vip'] ,transactionType: 'vip'})
                    // }
                    // break;
                    
                    document.getElementById('vip-inprogress-notice').classList.remove('display-none');
                // case 'experienceDiscount':
                //     setPaymentFormData({...paymentFormData, invitationCode: '', amount: paymentAmount['experienceDiscount'] ,transactionType: 'experienceDiscount'})
                //     break;                    
                // case 'vipDiscount':
                //     setPaymentFormData({...paymentFormData, invitationCode: '', amount: paymentAmount['vipDiscount'] ,transactionType: 'vipDiscount'})
                //     break;
                default: 
                    return;
            }
            setShow(true);
        }else{   
            setShow(false); 
            showLogin();
        }
    }

    const getDiscount = (e) => {
        e.preventDefault();
        console.log('getDiscount clicked');

        getDiscountCode(invitationCode);
    }

    
    useEffect(()=>{ 
        
        if(discountCode.error){
            setAlert(discountCode.error.msg, 'danger')
        }else{
            if(!discountCode.discountCodeLoading && discountCode.codeActive){
                let paymentAmountField;
                if(transactionType==='experience'){paymentAmountField = 'experienceDiscount';}else if(transactionType === 'vip'){paymentAmountField = 'vipDiscount'}
                // switch(transactionType){
                //     case 'experience':
                //         paymentAmountField = 'experienceDiscount';
                //         break;
                //     case 'vip':
                //         paymentAmountField = 'vipDiscount';
                //         break;
                //     default: 
                //         break;
                // }
                setPaymentFormData({...paymentFormData, amount: paymentAmount[paymentAmountField], transactionType: paymentAmountField})
            }
            if(!discountCode.discountCodeLoading && !discountCode.codeActive){
                setAlert('您输入的折扣码已失效，请更换折扣码后再次尝试！', 'danger')
            }
        }

        
    },[discountCode])

    const togglePaymentNotice = (e, action, id) => {
        e.preventDefault();
        const element = document.getElementById(id);
        console.log(id, element)
        // if(element){
            if( action === 'show'){
                    element.classList.remove('display-none');
            }else{
                element.classList.add('display-none');
            }
        // }
    }

    useEffect(()=>{
        const scrollToElement = document.getElementById('payment-form');
        scrollToElement.scrollIntoView({ behavior: "smooth"});
    },[show,transactionType])

  return (
    <div className='container'>
        <div className="grid-container-2 buy-vip">
            <div className="grid-2-item buy-vip-item">
                <div className="buy-vip-title">
                    <h2>体验版会员</h2>
                </div>
                <div className="buy-vip-content">
                 <h1 className='text-crossed'>{paymentAmount.experience}/年</h1>                    
                    <h1 className="text-pink"><span className='text-1rem mr-1'>折扣价</span><span className='text-extraLarge'>{paymentAmount.experienceDiscount}</span>/年</h1>
                    <div className='divider-pink'></div>
                    <h2 className=''>薄码图片</h2>
                    <h2 className=''>体验短评</h2>
                    <h2 className=''>舞厅攻略</h2>
                    <h2 className=''>女舞客预约</h2>
                </div>
                <button className="btn btn-pink btn-square" onClick={e=>showPaymentBox('experience')}>购买</button>
            </div>
            <div className="grid-2-item buy-vip-item">
                <div className="buy-vip-title">
                    <h2>VIP版会员</h2>
                </div>
                <div className="buy-vip-content">
                    <h1 className='text-crossed'>{paymentAmount.vip}/年</h1>
                    <h1 className="text-pink"><span className='text-1rem mr-1'>折扣价</span><span className='text-extraLarge'>{paymentAmount.vipDiscount}</span>/年</h1>
                    <div className='divider-pink'></div>
                    <h2 className=''>微码图片</h2>
                    <h2 className=''>体验短评+隐私敏感情报</h2>
                    <h2 className=''>舞厅攻略+VIP文章</h2>
                    <h2 className=''>女舞客预约有限特权</h2>
                </div>
                <button className="btn btn-pink btn-square" onClick={e=>showPaymentBox('vip')}>购买</button>
            </div>
        </div>
    
        <div className={show ? "" : 'display-none'}>  
        {/* {paymentBox} */}

            <form className={`payment-box ${show ? "" : 'display-none'}`} id='payment-form'>
                <h2 className={`payment-box-title`}>{paymentBoxTitle[transactionType]}</h2>
                {/* <div className="monthly-payment-box-content"> */}
                <img src={`/uploads/management/${transactionType}.png`} alt={`${transactionType} payment`} className={`${transactionType}-payment-qrcode`} />
                {/* </div> */}
                {invitationCode && discountCode.codeActive ? 
                <div className="display-flex">
                    <p className='mr-auto text-success'>*折扣码已生效</p>
                </div> : <div className="display-flex">
                    <input className='' type="text" name={`${transactionType}-payment_invitationCode`} id={`${transactionType}_invitationCode`} placeholder='请输入邀请码（选填）' value={invitationCode ? invitationCode : ''} onChange={e => onPaymentFormChange(e)} />
                    <button className='btn btn-pink mt-1' onClick={e => getDiscount(e)}>给我折扣</button>
                </div>
                }
                <button className='btn btn-pink px-1' onClick={e => togglePaymentNotice(e, 'show', 'postpayment-notice')} >已完成支付</button>
                


            </form>

            <div className="payment-page-instructions">
                <h2 className="text-pink">*操作说明：</h2>
                {/* <p>▲ 选择体验版会员/VIP会员,点击【购买】;</p> */}
                <p>▲ 输入传单折扣码，点击确定后，可看到折扣后的二维码；</p>
                <p>▲ 保存支付宝二维码到相册，在支付宝中扫码保存的二维码;</p>
                <p>▲ 在支付宝付款页面，点击【确认付款】上的【添加备注】，留下自己的用户名（注意不是昵称）;</p> 
                <img src={addRemarkImg} alt="添加备注图" />
                <img src={confirmPaymentImg} alt="确认付款图" />
                <p>▲ 如已扫码支付但未留正确用户名，将在1天内退款;</p>
                <p>▲ 在本页面，点击【已完成付款】;</p>
                <p>▲ 早9点-晚上11点付款的用户,将在30分钟内开通VIP。晚11点后,将在第二天早上9点半之前开通。</p>
            </div>
        </div>  

        <div className=" confirmation-box-container display-none" id='postpayment-notice'>
            <div className="info-box">
                <div className="info-box-title">
                    <h2>注意事项</h2>
                </div>
                <div className="info-box-content">
                    {/* <p>* 如已付款且备注了正确的用户名，请点确认。如已扫码未留用户名，将在30分钟内退款</p> */}
                    <p>* 如付款时已经备注用户名，即将开通会员。如未备注正确的用户名，款项将在一天内被退回</p>
                    <div className="display-flex">
                        <button className="btn btn-pink width-100 ml-auto mr-1" onClick={e => onPaymentFormSubmit(e, 'postpayment-notice')}>确认完成支付</button>
                        <button className='btn btn-pink width-100 mr-auto ml-1 ' onClick={e => togglePaymentNotice(e,'hide', 'postpayment-notice')} >取消</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="confirmation-box-container display-none" id="vip-inprogress-notice">
            <div className="info-box">
                <div className="info-box-title">
                    <h2>站点通知</h2>
                </div>
                <div className="info-box-content">
                    <p> VIP版本正在开发中，请先购买体验版，可体验90%功能。 VIP版本上线后，可补差价升级为VIP版本。差价金额为，当时的VIP会员与当时的体验版会员的差价。</p>
                    <div className="display-flex">
                        <button className='btn btn-pink width-100 mx-auto ' onClick={e => togglePaymentNotice(e,'hide', 'vip-inprogress-notice')} >关闭</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

BuyVIP.propTypes = {
    discountCode: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object,
    newTransaction: PropTypes.func.isRequired,
    getDiscountCode: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.user.user,
    discountCode: state.discountCode,
})

export default connect(mapStateToProps, {newTransaction, getDiscountCode, setAlert})(BuyVIP)