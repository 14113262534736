import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { useNavigate } from "react-router-dom";

import {getDancerWithNickname} from '../../actions/dancer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import DancersItem from '../dancer/DancersItem';
import NewDancer from "./NewDancer";
import { downloadDancerCSV } from '../../actions/management';


const DancerTab = ({  
    dancersCount,
    editDancer,
    getDancerWithNickname,
    downloadDancerCSV
}) => {

    const navigate = useNavigate();
    
    useEffect(()=>{},[editDancer, dancersCount])
    // const [newDancer, setNewDancer] = useState("")
    // useEffect(()=>{},[newDancer])
    
    const onClickNewDancer = (e) => {
        // e.preventDefault();
    //    setNewDancer(<NewDancer/>);
        // const newDancerElement = document.getElementById('new-dancer');
        // newDancerElement.classList.remove('display-none');
        navigate('/control-panel/new-dancer')
    }

    const [nickname, setNickname] = useState({
        nickname: ''
    })
    useEffect(()=>{},[nickname])

    const onDancerNicknameChange = e => {
        setNickname({...nickname, nickname: e.target.value})
    }

    const onDancerNicknameSubmit = (e) => {
        e.preventDefault();
        document.getElementById('waiting-spinner').classList.remove('display-none');
        getDancerWithNickname(nickname);
    }

    const onClickDownloadDancers = e => {
        e.preventDefault();
        downloadDancerCSV();
    }

    return (
        <div id="dancerTab-content" className="tabcontent">
            {/* 舞女以列表形式显示，依然包含筛选选项 */}
            <div className="dancerTab-header">
                <div className="add-dancer-btn-div display-flex">
                    <button className="btn btn-pink add-btn ml-0" id='add-dancer-btn' onClick={e => onClickNewDancer(e)}><FontAwesomeIcon icon={faSquarePlus} />添加舞女</button>
                    <h3>当前舞女总数 {dancersCount}</h3>
                </div>
                <div className="display-flex">
                    <button className="btn btn-pink add-btn mr-0" id='download-dancers-table-btn' onClick={e => onClickDownloadDancers(e)}>
                        <FontAwesomeIcon icon={faDownload} />下载舞女列表cvs文件
                    </button>
                </div>
                <form className='search-form display-flex ml-auto mr-0'id='search-dancer-with-nickname-form' onSubmit={e=>onDancerNicknameSubmit(e)} onChange={e=>onDancerNicknameChange(e)}>
                    
                    <input className='mr-1' name="nickname-input" type="text" placeholder='请输入舞女内部代号'/>
                    <button type="submit" className="btn btn-pink add-btn">查找舞女</button>
                </form>
            </div>
            {/* <NewDancer dancer={}/> */}
            <div className="dancerInfo">
                <div className='grid-container-dancers'>
                    {editDancer ? (editDancer.map(editDancer => (
                        <DancersItem dancer={editDancer} key={`editDancer-item-${editDancer.dancerID}`} controlpanel={true}/>
                    ))): ""}
                </div>
            </div>
        </div>
    )
}

DancerTab.propTypes = {
    editDancer: PropTypes.array,
    dancersCount: PropTypes.number.isRequired,
    getDancerWithNickname: PropTypes.func.isRequired,
    downloadDancerCSV: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    dancersCount: state.management.dancersCount,
    editDancer: state.management.editDancer,
})

export default connect(mapStateToProps, {getDancerWithNickname, downloadDancerCSV})(DancerTab)