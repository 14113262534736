import { 
    GET_DISCOUNTCODE,
    DISCOUNTCODE_ERROR
 } from "../actions/types";

const initialState = {
  codeActive:false,
  discountCodeLoading: true,
  error: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DISCOUNTCODE:
      return {
        ...state,
        codeActive: payload.active,
        discountCodeLoading: false
      }
    case DISCOUNTCODE_ERROR:
      return {
        ...state,
        codeActive: false,
        discountCodeLoading: true,
        error: payload
      };

    default:
      return state;
  }
}
