import axios from "axios";
import { setAlert } from "./alert";

import {
    GET_AGENTS,
    GET_AGENTS_ERROR,
    GET_USERS,
    GET_USERS_ERROR,
    NEW_RESERVE,
    GET_RESERVE,
    GET_RESERVES,
    DELETE_RESERVE,
    RESERVE_PROCESSED,
    RESERVE_ERROR,
    RESERVES_ERROR,
    GET_DANCERS_COUNT,
    DANCERS_COUNT_ERROR,
    DANCERS_ERROR,
    NEW_USER,
    CREATE_NEW_USER_ERROR,
    GET_TRANSACTIONS_MANAGEMENT,
    TRANSACTIONS_MANAGEMENT_ERROR,
    GET_USERS_COUNT,
    USERS_COUNT_ERROR,
    UPADTE_USER_ACCOUNTTYPE,
    UPDATE_USER_ACCOUNTTYPE_ERROR,
    GET_AGENT_TRANSACTIONS,
    TOTAL_INCOME,
    TOTAL_INCOME_ERROR,
    AGENT_WITHDRAW,
    WITHDRAW_ERROR,
    NEW_AGENT,
    DOWNLOAD_DANCERS

} from './types';

import setAuthToken from "../utilities/setAuthToken";
import { JSONtoCSV, download } from "../functions/functions";

const waitingSpinner = document.getElementById('waiting-spinner');

  //get all users for contral panel
  export const getUsers = () => async (dispatch) => {
    const token = localStorage.getItem("DCtoken")
    // console.log(token)  
    if (token) {
        setAuthToken(token);
      }
    try {
  
      const res = await axios.get("/api/users");
      // const res = await axios.get("http://localhost:5000/api/users");
      
      // console.log("number of users got from getUsers: ", res.data)

      dispatch({
          type: GET_USERS,
          payload: res.data
      });
  }catch (err) {
      // const errors = err.response;
  
      // if (errors) {
      //   errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      // }
  
      dispatch({
        type: GET_USERS_ERROR,
        // payload: { msg: err.response.statusText, status: err.response.status }
        payload: err.response
      });
    }
    if(waitingSpinner){waitingSpinner.classList.add('display-none')}
  }

// GET AGENTS
export const getAgents = () => async dispatch => {
  try {
    const res = await axios.get('/api/management/agents')
    // const res = await axios.get('http://localhost:5000/api/management/agents')
    // console.log('getAgents call in action');
    dispatch({
      type: GET_AGENTS,
      payload: res.data
    })
  } catch (err) {
    dispatch({
        type: GET_AGENTS_ERROR,
        payload: err.response
    })
  }
}

// export const getPayments = 


// GET USER TRANSACTIONS
export const getTransactions = () => async dispatch => {
  try {
      
      const res = await axios.get('/api/transactions/users');
      
      // const res = await axios.get('http://localhost:5000/api/transactions/users')

      dispatch({
          type: GET_TRANSACTIONS_MANAGEMENT,
          payload: res.data
      })
  }catch(err){
      dispatch({
          type: TRANSACTIONS_MANAGEMENT_ERROR,
          payload: err.response
      })
  }
  
  if(waitingSpinner){waitingSpinner.classList.add('display-none')}
}

// GET AGENT TRANSACTIONS
export const getAgentTransactions = () => async dispatch => {
  try {
      
      const res = await axios.get('/api/transactions/agents');
      
      // const res = await axios.get('http://localhost:5000/api/transactions/users')

      dispatch({
          type: GET_AGENT_TRANSACTIONS,
          payload: res.data
      })
  }catch(err){
      dispatch({
          type: TRANSACTIONS_MANAGEMENT_ERROR,
          payload: err.response
      })
  }
  
  if(waitingSpinner){waitingSpinner.classList.add('display-none')}
}

// GET RESERVES
export const getReserves = () => async dispatch => {
    try {
        const res = await axios.get('/api/management/reserves/');
        // const res = await axios.get('http://localhost:5000/api/management/reserves/');
        // console.log("getReserves called in action");

        dispatch({
            type: GET_RESERVES,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: RESERVES_ERROR,
            payload: err.response
        })
    }
}


// DOWNLOAD DANCERS
export const downloadDancerCSV = () => async dispatch => {
  try {
    if(waitingSpinner){waitingSpinner.classList.remove('display-none')}
    const res = await axios.get('api/management/download_dancer_csv');
    const csv = JSONtoCSV(
      res.data, 
      ["dancerID","nickname","codeName","overallRating","beautifulness","age","height","clothing","breasts","dragonGirl","showupClub","price","body","hotness",'weChat']
    )
    console.log(csv)
    const blob = new Blob([csv], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);
    download(url, 'dancers.csv');
    URL.revokeObjectURL(url);
    dispatch({
      type: DOWNLOAD_DANCERS,
      payload: ''
    })
    if(waitingSpinner){waitingSpinner.classList.add('display-none')}
  }catch(err){
    dispatch({
      type: DANCERS_ERROR,
      payload: err.response
    })
  }
}

// GET RESERVE
export const getReserve = (id) => async dispatch => {
    try {
        const res = await axios.get(`/api/management/reserve/${id}`);
        // const res = await axios.get(`http://localhost:5000/api/management/reserve/${id}`);
        // console.log("getReserve called in action");

        dispatch({
            type: GET_RESERVE,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: RESERVE_ERROR,
            payload: err.response
        })
    }
    if(waitingSpinner){waitingSpinner.classList.add('display-none')}
}

// DELETE RESERVE
export const deleteReserve = (id) => async dispatch => {
  // console.log("deleteReserve called in action.")
    try{
        await axios.delete(`/api/management/reserve/${id}`);
        // await axios.delete(`http://localhost:5000/api/management/reserve/${id}`);

        dispatch({
            type: DELETE_RESERVE,
            payload: id
        });
        dispatch(setAlert("RESERVE Deleted. 该舞女信息已删除", "success"));
    }catch (err) {
        dispatch({
          type: RESERVE_ERROR,
        //   payload: {
        //     msg: err.response.statusText,
        //     status: err.response.status
        //   }
            payload: err.response
        });
      }
      if(waitingSpinner){waitingSpinner.classList.add('display-none')}
}

// CREATE NEW RESERVE
export const newReserve = (formData) => async dispatch => {
    // console.log("formData received in createUpdateReserve: ", formData)
    try {
        const config = {
            headers: {
                "content-type": "application/json"
            }
        };
    
        const res = await axios.post("/api/management/reserve/", formData, config);
        // const res = await axios.post("http://localhost:5000/api/management/reserve/", formData, config);

        // dispatch(getReserves());
        dispatch({
          type: NEW_RESERVE,
          payload: res.data
        })

        // if(edit){
            dispatch(setAlert('预约请求已递交', 'success'))
        // }else{dispatch(setAlert('成功添加舞女', 'success'))}

    }catch (err) {
        const errors = err.response;
    
        if (errors) {
          errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
    
        dispatch({
          type: RESERVE_ERROR,
          // payload: { msg: err.response.statusText, status: err.response.status }
          payload: err.response
        });
      }
      if(waitingSpinner){waitingSpinner.classList.add('display-none')}
}

// APPROVE RESERVE
export const processReserve = (id) => async dispatch => {
    try {
        const res = await axios.post(`/api/management/reserve/${id}`)
        // const res = await axios.post(`http://localhost:5000/api/management/reserve/${id}`)
        dispatch({
            type: RESERVE_PROCESSED,
            payload: res.data
        })
    }catch (err) {
        dispatch({
          type: RESERVE_ERROR,
            payload: err.response
        });
      }
}

// GET DANCERS COUNT
export const getDancersCount = () => async dispatch => {
    try{
        const res = await axios.get('/api/management/dancersCount');
        // const res = await axios.get('http://localhost:5000/api/management/dancersCount');
        dispatch({
            type: GET_DANCERS_COUNT,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: DANCERS_COUNT_ERROR,
            payload: err.response
        })
    }
}


// GET DANCERS COUNT
export const getUsersCount = () => async dispatch => {
  try{
      const res = await axios.get('/api/management/usersCount');
      // const res = await axios.get('http://localhost:5000/api/management/usersCount');
      dispatch({
          type: GET_USERS_COUNT,
          payload: res.data
      })
  }catch(err){
      dispatch({
          type: USERS_COUNT_ERROR,
          payload: err.response
      })
  }
}

// GET TOTAL INCOME
export const getTotalIncome = () => async dispatch => {
  try{
    const res = await axios.get('/api/management/totalIncome');
    console.log('totalincome res: ', res);
    dispatch({
      type: TOTAL_INCOME,
      payload: res.data
    })
  }catch(err){
    dispatch({
      type: TOTAL_INCOME_ERROR,
      payload: err.response
    })
  }
}

  // Add New User from ControlPanel
  export const addNewUser = (formData) => async (dispatch) => {
    // console.log('register call in action');
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    const body = JSON.stringify(formData);
    // console.log('body: ',body);
  
    try {
      const res = await axios.post(
        // "http://localhost:5000/api/users",
        '/api/users/addUser',
        body,
        config
      );

      if(formData.accountType === 'agent'){
        dispatch({
          type: NEW_AGENT,
          payload: res.data
        })
      }else{
        dispatch({
          type: NEW_USER,
          payload: res.data
        })
      }

      dispatch(setAlert('成功添加账户', 'success'))
    } catch (err) {
      dispatch({
        type: CREATE_NEW_USER_ERROR,
        payload: err.response
      });
    }

    document.getElementById('new-user-box').classList.add('display-none')
  };

  // Update User AccountType
  export const updateUserAccountType = formData => async dispatch =>{
    console.log(formData);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(formData);
    try {
      const res = await axios.post(
        // "http://localhost:5000/api/users/update/accountType",
        '/api/users/update/accountType',
        body,
        config
      );
  
        dispatch(setAlert('成功修改账户级别', 'success'))
        dispatch({
          type: UPADTE_USER_ACCOUNTTYPE,
          payload: res.data
        });
    } catch (err) {
      // const errors = err.response;
  
      // if (errors) {
      //   errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      // }
      dispatch({
        type: UPDATE_USER_ACCOUNTTYPE_ERROR,
        payload: err.response
      });
    }

    document.getElementById('new-user-box').classList.add('display-none')
  }

  //  Withdraw for Agent
  export const withdrawForAgent = formData => async dispatch => {
    console.log('withdrawForAgent call in action:', formData);

    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    }
    const body = JSON.stringify(formData);
    try {
      const res = await axios.post('/api/management/withd_for_agent', body, config);
      dispatch({
        type: AGENT_WITHDRAW,
        payload: res.data
      })
      dispatch(setAlert(`成功通过提现`, 'success'));
    }catch(err){
      dispatch({
        type: WITHDRAW_ERROR,
        payload: err.response
      })
    }
  }