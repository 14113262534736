import React, { Fragment, useState, useEffect} from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'

import { login, changeAction } from '../../actions/auth';
import { register } from '../../actions/user';
import { setAlert } from '../../actions/alert';

const Login = ({ login, register,setAlert, auth: { isAuthenticated, action }, user: { errors }, changeAction}) => {
  const [loginFormData, setloginFormData] = useState({
    username: '',
    password: '',
    email: ''
  });

  // const [ action, setCurrentAction ] = useState(action)

  useEffect(()=>{
    if(errors.length > 0){
        errors.map(err => setAlert(err.data.msg, 'danger'))
    }
  },[errors])

  const [pw, setPw] = useState('')

  useEffect(() => {
    // console.log(pw)
    // console.log(action);
    // console.log(loginFormData)
  }, [pw,loginFormData,action])

  // useEffect(()=>{
  // },[loginFormData])

  const actions = {login: {en: 'login', zh: '登录'}, register: {en: 'register', zh: '注册'}}

  const { username, password, email } = loginFormData;

  const onPasswordChange = e => {
    setloginFormData({
      ...loginFormData,
      password: e.target.value
    })
    // setPw(e.target.value);
    // document.getElementById('password-checked').classList.add('display')
  };

  const onPasswordConfirm = e => {
    setPw(e.target.value);
    if(e.target.value === password){
      document.getElementById(e.target.id).classList.remove('background-pink');
      // setloginFormData({
      //   ...loginFormData,
      //   password: e.target.value
      // })
      
    }else{
      // setloginFormData({
      //   ...loginFormData,
      //   password: ''
      // })
      document.getElementById(e.target.id).classList.add('background-pink');
    } 
  }

  const onChange = (e) => {
    setloginFormData({ ...loginFormData, [e.target.name]: e.target.value})
  }

  const closelogin = e =>{
    e.preventDefault();
    setloginFormData({
      username: '',
      password: '',
      email: ''
    });
    setPw('')
    document.getElementById(`${action}-box`).classList.add('display-none');
    changeAction('login');
  };

  const onLogin = (e) => {
    e.preventDefault();
    if(e.target.id === 'login'){
        // console.log("Login button clicked. loginFormData: ", loginFormData);
        login(username, password);
    }else if(e.target.id === 'register'){
        // console.log("Register button clicked. loginFormData: ", loginFormData);
        if(password !== pw){
          setAlert('两次输入密码不匹配，请检查后重新递交','danger')
        }else{
          register(loginFormData);
        }
    }
    setTimeout(()=>{
      // setloginFormData({
      //   username: '',
      //   password: '',
      //   email: ''
      // });      
      // setPw('');
      document.getElementById(e.target.id).classList.remove('background-pink');

    },500)
  };

  const onChangeToLogin = () => {
    changeAction('login')
  }

  const onChangeToRegister = () => {
    changeAction('register')
  }

  // Redirect if logged in
  // if (isAuthenticated) {
  //   closelogin('login-box');
    // if (user.autherization === "admin") {
    //     //   return user.autherization === "admin" ? (
    //     //     <Redirect to="/dashboard" />
    //     //   ) : (
    //     //   );
    // return <Redirect to="/" />;
    // }
  // }

  return (
    <Fragment>
      <section id={action === 'login' ? 'login-box' : 'register-box'} className="login-container display-none">
        {/* <div className="login-dark-overlay"></div> */}
        <div className="login-window">
          <div className="popup-title">
            <h1 className="large text-primary">{actions[action].zh}</h1>
            <button className="btn btn-close" onClick={e=>closelogin(e)}>
            <FontAwesomeIcon icon={faSquareXmark} />
            </button>
          </div>
          <div className="login-grid border-bottom mb-1">
            <form className="login-form" id={actions[action].en} onSubmit={e => onLogin(e)}>
              <div className="login-form-grid">
                <label htmlFor="username">用户名：</label>
                <input
                  name="username"
                  type="text"
                  placeholder="用户名"
                  onChange={e => onChange(e)}
                  value={username}
                  required
                />
              </div>
              {action === 'register' ? <div className="login-form-grid">
                <label htmlFor=""></label>
                <p className='text-small text-danger p-0 m-0'>*用户名区分大小写</p>
              </div> : "" }
                
              {action === 'register' ?( <div className="login-form-grid">
                <label htmlFor="email">e-mail: </label>
                <input
                  name="email"
                  placeholder="e-mail"
                  type="email"
                  onChange={e => onChange(e)}
                  value={email}
                  required
                />
              </div>):""}
              <div className="login-form-grid">
                <label htmlFor="password">密码: </label>
                <input
                  name="password"
                  placeholder="密码"
                  type="text"
                  minLength="8"
                  onChange={e => onPasswordChange(e)}
                  value={password}
                  required
                />
              </div>
              
              {action === 'register' ?(<div className="login-form-grid">
                <label htmlFor="passwordConfirm">确认密码: </label>
                <input
                  name="passwordConfirm"
                  id="passwordConfirm"
                  placeholder="确认密码"
                  type="text"
                  minLength="8"
                  onChange={e => onPasswordConfirm(e)}
                  value={pw}
                  required
                />
              </div>) : ""}
              <div className="login-box-alternative-link">
                {action === "register" ? <p>已经有账户了？<btn className="btn btn-link text-pink text-underlined" onClick={e => onChangeToLogin()}>登录</btn></p> : <p>还没有账户？<btn className="btn btn-link text-pink text-underlined" onClick={e => onChangeToRegister()}>注册</btn></p>}
              </div>
              {/* <div className="login-form-grid">
                <div className=""></div> */}
                <div className='login-btn-grid-2'>
                  <button
                    type='button'
                    className="btn btn-pink mr-1"
                    onClick={e=>closelogin(e)}
                  >取消</button>
                  <button
                    type="submit"
                    className="btn btn-pink ml-1"
                    // disabled={(pw !== password && pw !== '') ? true : false}
                  >
                    {actions[action].zh}
                  </button>
                </div>
              {/* </div> */}
              </form>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  changeAction: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user
});

export default connect(mapStateToProps, { login, register, setAlert, changeAction })(Login);
