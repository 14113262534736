import {
    GET_BLOGS,
    GET_BLOGS_COUNT,
    GET_BLOG,
    BLOGS_ERROR,
    BLOG_ERROR,
    CREATE_BLOG,
    DELETE_BLOG,
    SET_CATEGORY,
    SET_BLOG_TOP,
    CLEAR_BLOGS
  } from '../actions/types';
  
//   category: [experience, strategy, clubs-index]

  const initialState = {
    blogs: [],
    blogsCount: 0,
    blogsLoading: false,
    blog: '',
    blogLoading: true,
    category: 'latest',
    blogsError: '',
    blogError: ''
  }

  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case GET_BLOGS:
            
            return {
                ...state,
                blogs: payload.blogs.sort((a,b)=>new Date(b.date) - new Date(a.date)),
                blogsCount: payload.count,
                // blogs: state.category === 'clubs-index' ? clubsIndex : state.category === 'experience' ? experience :  latest,
                blogsLoading: false
            }
        case GET_BLOG:
            return {
                ...state,
                blog: payload,
                blogLoading: false
            }
        case DELETE_BLOG:
            return {
                ...state,
                blog: "",
                blogs: state.blogs.filter(blog=>blog._id !== payload),
                blogsLoading: false
            }
        case CREATE_BLOG:
            return {
                ...state,
                blogs: [...state.blogs.blogs, payload],
                blogsLoading: false
            }
        case BLOGS_ERROR:
            return {
                ...state,
                blogsLoading: true,
                BLOGS_ERROR: payload
            }
        case BLOG_ERROR: 
            return {
                ...state,
                blogError: payload,
                blogLoading: true
            }
        case SET_CATEGORY:
            return {
                ...state,
                category: payload
            }
        case SET_BLOG_TOP:
                
            return {
                ...state,
                blogs: [...state.blogs.filter(blog => blog._id !== payload._id), payload].sort((a,b)=>new Date(b.date) - new Date(a.date)),
                // blogs: state.category === 'clubs-index' ? clubsIndex : state.category === 'experience' ? experience :  latest,
                blogsLoading: false
            }
        case CLEAR_BLOGS: 
            return {
                ...state,
                blogs: [],
                blogsLoading: true
            }
      default:
        return state;
    }
  }