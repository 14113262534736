import React, {Fragment, useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'

const PleaseBuyVIP = ({user,userLoading}) => {

    useEffect(()=>{},[user,userLoading])
    const navigate = useNavigate();

    const openBuyVIPWindow = () =>{
        document.getElementById('please-buy-vip').classList.add('display-none')
        navigate('/buy-vip')
    }

    const onClose = (a,b) => {
        if(a){const applyForFreeVIP = document.getElementById(a);
        applyForFreeVIP.classList.add('display-none');}
        if(b){const pleaseBuyVIP = document.getElementById(b);
        pleaseBuyVIP.classList.remove('display-none');}
    }

  return userLoading ? "" : (
    <Fragment>
        <div className='please-buy-vip display-none' id='please-buy-vip'>
            <div className="info-box">
                <div className="info-box-title">
                    <h2>您还不是VIP会员</h2>
                    <button className="btn btn-close-white" onClick={e=>onClose('please-buy-vip')}>
                        <FontAwesomeIcon icon={faSquareXmark} />
                    </button>
                </div>
                <div className="info-box-content">
                    <div className="info-box-content-rules">
                        <p>VIP权限：</p>
                        <p>①图片擦除马赛克。</p>
                        <p>②可查看完整评论。</p>
                        <p>③可在线预约女舞客。</p>
                    </div>
                    
                    <div className="grid-two">
                        <button className='btn btn-pink big-square-btn' onClick={e=>openBuyVIPWindow()}>
                            开通VIP
                        </button>
                        {user.freeVipTimer ? <button disabled className='btn btn-disabled big-square-btn' onClick={e => onClose('please-buy-vip','apply-for-free-vip')}>免费VIP计时中</button> : <button className='btn btn-pink big-square-btn' onClick={e => onClose('please-buy-vip','apply-for-free-vip')}>
                            申请免费VIP
                        </button>}
                    </div>
                </div>
            </div>
            {/* <div className="dark-overlay"></div> */}
        </div>
    </Fragment>
  )
}

PropTypes.propTypes = {
    user: PropTypes.object.isRequired,
    userLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    user: state.user.user,
    userLoading: state.user.userLoading
})

export default connect(mapStateToProps, {})(PleaseBuyVIP)