export const clearBtnActive = () => {
    const btns = document.getElementsByClassName("pagi-btn");
    for (let j = 0; j < btns.length; j++) {
      btns[j].classList.remove("pagi-btn-active");
    }
    return null;
  };

const clearBtnShow = () => {
  let j;
  const btns = document.getElementsByClassName("pagi-btn");
  for (j = 0; j < btns.length; j++) {
    btns[j].classList.remove("pagi-btn-show");
  }
};

export const download = (path, filename) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
}; 

export const JSONtoCSV = (arr, columns, delimiter = ',') =>
[
  columns.join(delimiter),
  ...arr.map(obj =>
    columns.reduce(
      (acc, key) =>
        `${acc}${!acc.length ? '' : delimiter}${!obj[key] ? '' : obj[key]}`,
      ''
    )
  ),
].join('\n');

// 输入两个合规时间Object，比较两个时间点之间的天数差距
export const differentInDays = (date1, date2) => {
  // const date1 = new Date(day1);
  // const date2 = new Date(day2);
  // console.log(date1, date2)

  const differentInTime = date1.getTime() - date2.getTime();

  // console.log(differentInTime)

  const differentInDays = Math.round(differentInTime / (1000 * 3600 * 24));

  // console.log(differentInDays)

  return differentInDays;
}

export const changeBtnShow = (pages) => {
  clearBtnShow();
  let j;
  const activeBtns = document.getElementsByClassName("pagi-btn-active");
  const id = parseInt(activeBtns[0].id.slice(9));
  if (id > 0 && id <= 3 && id <= pages) {
    if (id === 1) {
      // document.getElementById("pagi-btn-first").classList.remove("show");
      // document.getElementById("pagi-btn-prev").classList.remove("show");
      document.getElementById("pagi-btn-next").classList.add("show");
      document.getElementById("pagi-btn-last").classList.add("show");
    } else if (id === pages) {
      // document.getElementById("pagi-btn-first").classList.add("show");
      // document.getElementById("pagi-btn-prev").classList.add("show");
      document.getElementById("pagi-btn-next").classList.remove("show");
      document.getElementById("pagi-btn-last").classList.remove("show");
    } else {
      // document.getElementById("pagi-btn-first").classList.add("show");
      // document.getElementById("pagi-btn-prev").classList.add("show");
      document.getElementById("pagi-btn-next").classList.add("show");
      document.getElementById("pagi-btn-last").classList.add("show");
    }
    for (j = 1; j <= 5; j++) {
      if(document.getElementById(`pagi-btn-${j}`)){document.getElementById(`pagi-btn-${j}`).classList.add("pagi-btn-show");}
    }
    if(document.getElementById("pagi-prev-five")){document.getElementById("pagi-prev-five").classList.remove("show");}
    if(document.getElementById("pagi-next-five")){document.getElementById("pagi-next-five").classList.add("show");}
  } else if (id > 0 && id > pages - 3 && id <= pages) {
    if (id === pages) {
      // document.getElementById("pagi-btn-first").classList.add("show");
      // document.getElementById("pagi-btn-prev").classList.add("show");
      document.getElementById("pagi-btn-next").classList.remove("show");
      document.getElementById("pagi-btn-last").classList.remove("show");
    } else {
      // document.getElementById("pagi-btn-first").classList.add("show");
      // document.getElementById("pagi-btn-prev").classList.add("show");
      document.getElementById("pagi-btn-next").classList.add("show");
      document.getElementById("pagi-btn-last").classList.add("show");
    }
    for (j = pages; j >= pages - 5; j--) {
      if(document.getElementById(`pagi-btn-${j}`)){document.getElementById(`pagi-btn-${j}`).classList.add("pagi-btn-show");}
    }
    document.getElementById("pagi-next-five").classList.remove("show");
    document.getElementById("pagi-prev-five").classList.add("show");
  } else {
    // document.getElementById("pagi-btn-first").classList.add("show");
    // document.getElementById("pagi-btn-prev").classList.add("show");
    document.getElementById("pagi-btn-next").classList.add("show");
    document.getElementById("pagi-btn-last").classList.add("show");
    for (j = id - 2; j <= id + 2; j++) {
      document.getElementById(`pagi-btn-${j}`).classList.add("pagi-btn-show");
    }
    document.getElementById("pagi-next-five").classList.add("show");
    document.getElementById("pagi-prev-five").classList.add("show");
  }
  return null;
};

export const showLogin = (action) => {
  document.getElementById(`${action}-box`).classList.remove('display-none');
}

export function scrollFunction() {
  const backToTopBtn = document.getElementById("back-to-top");
  if (
    document.body.scrollTop > 20 ||
    document.documentElement.scrollTop > 20
  ) {
    return backToTopBtn ? (backToTopBtn.style.display = "block") : "";
  } else {
    return backToTopBtn ? (backToTopBtn.style.display = "none") : "";
  }
}

// When the user clicks on the button, scroll to the top of the document
export function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

//
export function toggleSidenav() {
  const sidenavUnderlay = document.getElementById('side-nav-underlay');
  const sidenav = document.getElementById("side-nav");

  if(sidenav){if (sidenav.style.right === "0px") {
    sidenavUnderlay.classList.add('display-none')
    return (sidenav.style.right = "-180px");
  } else {
    
    sidenavUnderlay.classList.remove('display-none')
    return (sidenav.style.right = "0px");
  }}
}

export function toggleMenuDropdown(id, classname, display = "block") {
  const dropdowns = document.getElementsByClassName(classname);
  for (let i = 0; i < dropdowns.length; i++) {
    if (dropdowns[i].id !== id) {
      dropdowns[i].style.display = "none";
    }
  }
  if (id) {
    const element = document.getElementById(id);
    if (element.style.display === display) {
      return (element.style.display = "none");
    } else {
      return (element.style.display = display);
    }
  } else {
    return;
  }
}