import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';

import AttendanceItem from './AttendanceItem'
import Spinner from '../layout/Spinner'

import { getClubs } from '../../actions/club'
import { getDancers, getDancer } from '../../actions/dancer'
import { differentInDays } from '../../functions/functions';

const Attendance = ({
    dancer,
    dancerLoading,
    dancers,
    dancersLoading,
    dancersUpdated,
    clubs,
    clubsLoading,
    getClubs,
    getDancers,
    getDancer,
    isGranted
}) => {
    // useEffect(()=>{},[dancer, dancers, users, comments, reserve,reserves, editDancer])

    const navigate = new useNavigate();
    if(!isGranted) {
        navigate('/');
    }
    
    
    const { searchText } = useParams();
    // console.log(searchText)

    useEffect(()=>{
        getClubs();
        getDancers();
    window.scrollTo(0,0)
    },[getClubs, getDancers])

    const [displayMode, setDisplayMode] = useState()

    const [filteredDancers, setFilteredDancers] = useState({
        filteredDancersDB: [],
        filteredDancersOnDisplay:[]
    })

    const { filteredDancersDB, filteredDancersOnDisplay } = filteredDancers;

    useEffect(()=>{},[clubsLoading, clubs])

    const ages = ['幼','妹','姐','御','嫂','姨','母'] 
    const beautifulnessDB = ['10', '9',  '8', '7',  '6',  '5']
    const periods = [1, 2, 3, 4, 5, 6, 7, 8]
    const periodsDB = ['近半月', '近1月', '近2月','近3月', '近4月', '近5月', '近6月','6个月以上']
    const ageDB = {
        '幼':0,'妹':1,'姐':2,'御':3,'嫂':4,'姨':5,'母':6
    }

    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1; // 月份从0开始，需要加1
    var day = today.getDate();

    // 格式化为"yyyy-mm-dd"的日期字符串
    var formattedDate = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);

    const [formData, setFormData] = useState({
        club:'all',
        date: formattedDate,
        session: 'all',
        sort: '工号',
        age: 'all',
        beautifulness: 'all',
        period: null
    })

    const { club, date, session, sort, age, beautifulness, period } = formData;


    const remainder = n => {
        return parseInt(n) % 1000
    }


    useEffect(()=>{
        // if(dancerID){
        //     if(!dancerLoading){
        //         console.log(dancer);
                
        //         setFilteredDancers({...filteredDancers, filteredDancersDB: [dancer], filteredDancersOnDisplay: [dancer]});
        //     }
        // }else{
            // console.log('formData: ',formData)
            let sortedDancers;

            if(!dancersLoading){

                sortedDancers = dancers.sort((a,b) => parseInt(a.dancerID.slice(1,6)) - parseInt(b.dancerID.slice(1,6)))

                if(club !== 'all'){
                    sortedDancers = sortedDancers.filter(dancer => {
                        // console.log(dancer.showupClub.includes(club)); 
                        return dancer.showupClub.includes(club)
                    });
                }

                // 按照年龄筛选dancer（没有工号的情况下）
                if(age !== 'all'){
                    // console.log(ageDB[age])
                    sortedDancers = sortedDancers.filter(dancer => parseInt(dancer.age) === parseInt(ageDB[age]))
                }

                // 按照颜值筛选dancer
                if(beautifulness !== 'all'){
                    sortedDancers = sortedDancers.filter(dancer => {
                        // console.log(dancer.beautifulness)
                        return dancer.beautifulness ? parseInt(dancer.beautifulness) === parseInt(beautifulness) : ""});
                }

                const now = new Date();
                if(period !== null){
                    sortedDancers = sortedDancers.filter(item => {
                      if(item.attendance.length > 0){
                        // console.log(item.dancerID, item.attendance[0])
                        // console.log(`${item.attendance[0].date.slice(0,4)}-${item.attendance[0].date.slice(4,6)}-${item.attendance[0].date.slice(-2)}`)
                        // const attendanceDate = new Date(`${item.attendance[0].date.slice(0,4)}-${item.attendance[0].date.slice(4,6)}-${item.attendance[0].date.slice(-2)}`);
                        const attendanceDate = new Date(item.attendance[0].date)
                        const days = differentInDays(now, attendanceDate);
                        // const daysToCompare = Math.pow(2, parseInt(period));
                        // console.log(days,)
        
                        switch(period){
                            case '1':
                            case 1:
                                return days <= 15
                            case '2':
                            case 2:
                                return days <= 30
                            case '3':
                            case 3:
                                return days <= 60
                          case '4':
                          case 4:
                            // console.log(periodsDB[3], days <= 90)
                            return days <= 90
                          case '5':
                          case 5:
                            // console.log(periodsDB[4],days <= 120)
                            return days <= 120
                          case '6':
                          case 6:
                            // console.log(periodsDB[5],days <= 150)
                            return days <= 150
                          case '7':
                          case 7:
                            // console.log(periodsDB[6],days <= 180)
                            return days <= 180
                          
                            case '8':
                            case 8:
                                if(days > 180){console.log(period, 'attendance found: ', item.attendance[0], item.dancerID, days, days > 180)}
                                return days > 180
                            default:
                                return days <= 7 * Math.pow( 2, parseInt(period) )
                            }
                            // return days <= 7 * Math.pow( 2, parseInt(period) )

                        }else{
                            switch(period){
                            case '8':
                            case 8:
                                return true;
                            default:
                                return false;
                            }
                        }
                  })
                }

                // console.log(sortedDancers)
                if(searchText){
                    // console.log(searchText)
                    setFilteredDancers({...filteredDancers, filteredDancersDB: sortedDancers, filteredDancersOnDisplay: sortedDancers.filter(dancer => dancer.nickname.includes(searchText) || `${dancer.codeName.split('-')[0]}${dancer.codeName.split('-')[1]}${dancer.codeName.split('-')[2]}`.includes(searchText))});
                }else{
                    setFilteredDancers({...filteredDancers, filteredDancersDB: sortedDancers, filteredDancersOnDisplay: sortedDancers});
                }
            // }

            console.log('current dancer numbers on display: ', sortedDancers.length)
        }
    },[
        formData, 
        dancersLoading, 
        dancers,
        dancersUpdated,
        dancer,
        dancerLoading
    ])

    useEffect(()=>{
        if(dancer && !dancerLoading){setFormData({...formData, club: dancer.showupClub.split('/')[0]})}
    }, [dancer, dancerLoading])



    const onAttendanceSearchChange = e => {

        e.preventDefault();
        // console.log('e.target.name: ', e.target.name)
        const fieldnameArray = e.target.name.split('-');
        const fieldname = fieldnameArray[fieldnameArray.length - 1]
        const value = e.target.value;

        setFormData({
            ...formData,
            [fieldname]: value
        })
        // console.log('attendance search form changed: ', fieldname, ': ', value)
    } 

    const onNicknameSearch = (e, text) => {
        
        e.preventDefault();
        const searchText = document.getElementById(text).value;
        // console.log(filteredDancersDB.filter(item => item.nickname.includes(searchText) || item.codeName.includes(searchText)))
        // console.log(searchText)

        setFilteredDancers(
            {...filteredDancers, filteredDancersOnDisplay: filteredDancersDB.filter(dancer => dancer.nickname.includes(searchText) || `${dancer.codeName.split('-')[0]}${dancer.codeName.split('-')[1]}${dancer.codeName.split('-')[2]}`.includes(searchText))
        })
    }
    
    
    const onResetDancerNicknameSearch = e => {
        e.preventDefault();
        setFilteredDancers({...filteredDancers, filteredDancersOnDisplay: filteredDancersDB })
    }

    const switchDisplayMode = mode => {
        // console.log(mode)
        setDisplayMode(mode);
    }


    useEffect(()=>{
        const checked = filteredDancersDB.filter(item => item.attendance.filter(att => att.date === date).length > 0) //att.club === club && att.session === session &&

        const notChecked = filteredDancersDB.filter(item => item.attendance.filter(att => att.club === club && att.session === session && att.date === date).length === 0);

        if(displayMode==='notChecked'){
            // console.log('notChecked: ', notChecked)
            setFilteredDancers({...filteredDancers, filteredDancersOnDisplay: notChecked});
        }else if(displayMode==='checked'){
            // console.log('checked: ', checked)
            setFilteredDancers({...filteredDancers, filteredDancersOnDisplay: checked});
        }else if(displayMode === 'all'){
            setFilteredDancers({...filteredDancers, filteredDancersOnDisplay: filteredDancersDB})
        }else{  return  }
    },[displayMode, filteredDancersDB])

    useEffect(()=>{
        // console.log(filteredDancersOnDisplay)
        // console.log(filteredDancers)
    // },[filteredDancersOnDisplay])
    },[filteredDancersOnDisplay])

  return (
    <div className='container'>
        <div className="attendance-search-container">
        <form className="attendance-search" id="attendance-search-form" onChange={e=>onAttendanceSearchChange(e)}>
            <div className="attendance-search-item">
                <label htmlFor="attendance-club" className="attendance-search-item-label">舞厅</label>
                <select name="attendance-club" id="attendance-club" value={club} onChange={e=>onAttendanceSearchChange(e)}>
                    <option value='all' key='attendance-club-all'>不限</option>
                    {clubs.map(item => <option value={item.name} key={item._id}>{item.name}</option>)}
                </select>
            </div>
            <div className="attendance-search-item">
                <label htmlFor="attendance-date" className="attendance-search-item-label">日期</label>
                <input name="attendance-date" id="attendance-date" type='date' defaultValue={date} onChange={e=>onAttendanceSearchChange(e)}/>
            </div>
            <div className="attendance-search-item">
                <label htmlFor="attendance-session" className="attendance-search-item-label">场次</label>
                <select name="attendance-session" id="attendance-session" value={session} onChange={e=>onAttendanceSearchChange(e)}>
                    <option value='all' key='attendance-session-all'>不限</option>
                    <option value='早场'>早场</option>
                    <option value='下午场'>下午场</option>
                    <option value='晚场'>晚场</option>
                </select>
            </div>
            <div className="attendance-search-item">
                <label htmlFor="attendance-sort" className="attendance-search-item-label">排序</label>
                <select name="attendance-sort" id="attendance-sort" value={sort} onChange={e=>onAttendanceSearchChange(e)} disabled>
                    <option value='工号'>工号</option>
                </select>
            </div>
            <div className="attendance-search-item">
                <label htmlFor="attendance-age" className="attendance-search-item-label">年龄</label>
                <select name="attendance-age" id="attendance-age" value={age} onChange={e=>onAttendanceSearchChange(e)}>
                    <option value='all' key='attendance-age-all'>不限</option>
                    {ages.map(item => <option value={item} key={`attendance-age-${ages.indexOf(item)}`}>{item}</option>)}
                </select>
            </div>
            <div className="attendance-search-item">
                <label htmlFor="attendance-beautifulness" className="attendance-search-item-label">颜值</label>
                <select name="attendance-beautifulness" id="attendance-beautifulness" value={beautifulness} onChange={e=>onAttendanceSearchChange(e)}>
                    <option value='all' key='attendance-beautifulness-all'>不限</option>
                    {beautifulnessDB.map(item => <option value={item} key={`attendance-beautifulness-${beautifulnessDB.indexOf(item)}`}>{item}</option>)}
                </select>
            </div>
            <div className="attendance-search-item">
                <label htmlFor="attendance-period" className="attendance-search-item-label">时效</label>
                <select name="attendance-period" id="attendance-period" value={period} onChange={e=>onAttendanceSearchChange(e)}>
                    <option value='all' key='attendance-period-all'>不限</option>
                    {periods.map(item => <option value={item} key={`attendance-period-${periods.indexOf(item)}`}>{periodsDB[periods.indexOf(item)]}</option>)}
                </select>
            </div>
            
        </form>
        <form id='attendance-text-search'>
            <div className='form-input display-flex'id='search-dancer-with-nickname-form'>
                <input className='ml-auto' id='nickname-input-attendance' name="nickname-input-attendance" type="text" placeholder='请输入舞女内部代号'/>
                <button className="btn btn-pink admin-search-btn" onClick={e=>onNicknameSearch(e, 'nickname-input-attendance')}>查找</button>
                <button className="btn btn-pink admin-search-btn" onClick={e=>onResetDancerNicknameSearch(e)}>重置</button>
            </div>
        </form>
        </div>
        
        
        <div className="attendance-container">
            {filteredDancersOnDisplay ? <div className="attendance-grid">
            {filteredDancersOnDisplay.map(dancer => <AttendanceItem dancer={dancer} key={dancer._id} formData={formData}/>)}
            
            </div> : <Spinner />}
            <div className="attendance-display-mode-switches">
                <button className="btn btn-green display-mode-switch-btn" onClick={e=>switchDisplayMode('notChecked')}>未签到</button>
                <button className="btn btn-green display-mode-switch-btn" onClick={e=>switchDisplayMode('checked')}>已签到</button>
                <button className="btn btn-green display-mode-switch-btn" onClick={e=>switchDisplayMode('all')}>全部</button>
            </div>
        </div>
    </div>
    )
}

Attendance.propTypes = {
    dancer: PropTypes.object,
    dancers: PropTypes.array,
    clubs: PropTypes.array.isRequired,
    dancerLoading: PropTypes.bool.isRequired,
    dancersLoading: PropTypes.bool.isRequired,
    clubsLoading: PropTypes.bool.isRequired,
    getClubs: PropTypes.func.isRequired,
    getDancers: PropTypes.func.isRequired,
    getDancer: PropTypes.func.isRequired,
    dancersUpdated: PropTypes.bool.isRequired,
    isGranted: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isGranted: state.auth.isGranted,
    clubs: state.club.clubs,
    dancer: state.dancer.dancer,
    dancerLoading: state.dancer.dancerLoading,
    dancers: state.dancer.dancers,
    dancersLoading: state.dancer.dancersLoading,
    clubsLoading: state.club.clubsLoading,
    dancersUpdated: state.dancer.dancersUpdated
})

export default connect(mapStateToProps, {getClubs, getDancers, getDancer})(Attendance)