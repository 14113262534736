import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_DANCER,
  GET_DANCERS,
  NEW_DANCER,
  DANCER_ERROR,
  DANCERS_ERROR,
  FILTER_DANCERS,
  DELETE_DANCER,
  UPLOAD_PHOTOS,
  PHOTOS_ERROR,
  UPLOAD_VIDEOS,
  VIDEOS_ERROR,
  ADD_TO_FAVORITE,
  REMOVE_FROM_FAVORITE,
  GET_DANCER_FOR_EDITTING,
  DANCER_FOR_EDITTING_ERROR,
  GET_DANCERS_COUNT,
  GET_DANCER_FOR_EDITTING_IN_DANCERS,
  GET_DANCER_FOR_EDITTING_IN_DANCERS_ERROR,
  SHOW_FAVORITE_DANCERS,
  FILTER_DANCERS_ERROR,
  CLEAR_DANCER,
  RESET_DANCERS_UPDATED,
  SET_DANCER,  
  RESET_DANCER,
  CHECK_ATTENDANCE
} from './types';


// GET DANCERS COUNT
// export const getDacnersCount = () => async (dispatch) => {
//   try{
//     const res = await axios.get('/api/dancers/count');
//     dispatch({
//       type: GET_DANCERS_COUNT,
//       payload: res.data
//     })
//   }catch(err){
//     dispatch({
//       type: DANCERS_ERROR,
//       payload: err.response,
//     });
//   }
// }

// GET DANCERS
export const getDancers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/dancers/');
    // const res = await axios.get('http://localhost:5000/api/dancers/');
    // // console.log.log('getDancers called in action');

    dispatch({
      type: GET_DANCERS,
      payload: res.data,
    });
    // dispatch({
    //   type: FILTER_DANCERS,
    //   payload:{
    //     wantToReserveDancers: null,
    //     formData: {
    //       showupClub: [],
    //       overallRating: [],
    //       beautfulness: [6,10],
    //       age: [18,45],
    //       height: [156,177],
    //       clothing: ['F','T','S'],
    //       breasts: ['B','C','D','E'],
    //       dragonGirl: null,
    //       weChat: null,
    //       favorite: null
    //     }
    //   }
    // })
    setTimeout(()=>{
      dispatch({
        type: RESET_DANCERS_UPDATED,
        payload: null
      })
    },3000)
  } catch (err) {
    dispatch({
      type: DANCERS_ERROR,
      payload: err.response,
    });
  }
};

// GET DANCER
export const getDancer = (dancerID) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/dancers/${dancerID}`);
    // const res = await axios.get(
    //   `http://localhost:5000/api/dancers/${dancerID}`
    // );
    // // console.log.log('getDancer called in action');

    dispatch({
      type: GET_DANCER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DANCER_ERROR,
      payload: err.response,
    });
  }
  document.getElementById('waiting-spinner').classList.add('display-none');
};

// Clear Dancer from store
export const clearDancer = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DANCER,
    payload: null
  })
}

// SET DANCER
export const setDancer = (dancerID) => async dispatch => {
  try {
    dispatch({
      type: SET_DANCER,
      payload: dancerID
    })
  }catch(err){
    dispatch({
      type: DANCER_ERROR,
      payload: err.response
    })
  }
}

// RESET DANCER
export const resetDancer = () => async dispatch => {
  try {
    dispatch({
      type: RESET_DANCER
    })
  }catch(err){
    dispatch({
      type: DANCER_ERROR,
      payload: err.response
    })
  }
}

// GET DANCER WITH NICKNAME
export const getDancerWithNickname = (nickname) => async (dispatch) => {
  try {
    // const body = JSON.parse({'nickname': nickname})
    // // console.log.log(nickname);
    const res = await axios.post('/api/dancers/nickname', nickname);
    // const res = await axios.post(
    //   'http://localhost:5000/api/dancers/nickname',
    //   nickname
    // );
    // // console.log.log('getDancerWithNickname called in action');
    // // console.log.log(res);

    dispatch({
      type: GET_DANCER_FOR_EDITTING,
      payload: res.data,
      // payload: 'test'
    });
  } catch (err) {
    dispatch({
      type: DANCER_FOR_EDITTING_ERROR,
      payload: err.response,
    });
  }
  document.getElementById('waiting-spinner').classList.add('display-none');
};


// GET DANCER WITH NICKNAME
export const getDancerWithNicknameInDancer = (text) => async (dispatch) => {
  try {
    // // const body = JSON.parse({'nickname': nickname})
    // // // console.log.log(nickname);
    // const res = await axios.post('/api/dancers/nickname', nickname);
    // // const res = await axios.post(
    // //   'http://localhost:5000/api/dancers/nickname',
    // //   nickname
    // // );
    // // console.log.log('getDancerWithNicknameInDancer called in action');
    // // console.log.log(res);

    dispatch({
      type: GET_DANCER_FOR_EDITTING_IN_DANCERS,
      payload: text,
      // payload: 'test'
    });
  } catch (err) {
    dispatch({
      type: GET_DANCER_FOR_EDITTING_IN_DANCERS_ERROR,
      payload: err.response,
    });
  }
  document.getElementById('waiting-spinner').classList.add('display-none');
};

export const showMyFavorite = favoriteDancers => async dispatch => {
  // console.log.log(favoriteDancers)
  try {
    dispatch({
      type: SHOW_FAVORITE_DANCERS,
      payload: favoriteDancers
    })
  }catch(err){
    dispatch({
      type: FILTER_DANCERS_ERROR,
      payload: err.response
    })
  }
}

// DELETE DANCER
export const deleteDancer = (id) => async (dispatch) => {
  // // console.log.log('deleteDancer called in action.');
  try {
    const res = await axios.delete(`/api/dancers/${id}`);
    // const res = await axios.delete(`http://localhost:5000/api/dancers/${id}`);
    

    dispatch({
      type: DELETE_DANCER,
      payload: res.data.id,
    });
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (err) {
    dispatch({
      type: DANCER_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
  document.getElementById('waiting-spinner').classList.add('display-none');
};

// CREATE NEW DANCER
export const createUpdateDancer = (formData, edit) => async (dispatch) => {
  // // console.log.log('formData received in createUpdateDancer: ', formData);
  try {
    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };

    // const res = await axios.post("/api/dancers/", formData, config);
    // const res = await axios.post(
    //   'http://localhost:5000/api/dancers/',
    //   formData,
    //   config
    // );

    // dispatch(getDancers());
    let res;

    if (edit) {
      console.log('edit')
      res = await axios.post('/api/dancers/edit', formData, config)
      dispatch(setAlert('成功更新舞女', 'success'));
    } else {
      res = await axios.post('/api/dancers/', formData, config)
      dispatch({
        type: NEW_DANCER,
        payload: res.data,
      });
      dispatch(setAlert('成功添加舞女', 'success'));
    }
  } catch (err) {
    const errors = err.response;

    // if (errors) {
    //   errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    // }

    dispatch({
      type: DANCER_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
      payload: err.response,
    });
    if(edit){dispatch(setAlert('舞女ID冲突，请删除舞女后重试！', 'dancer'))}
  }
  // document.getElementById('waiting-spinner').classList.add('display-none');
};

// CHECK DANCER ATTENDANCE
export const checkAttendance = (_id, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };

    const body = JSON.stringify(formData);

    const res = await axios.post(`/api/dancers/check_attendance/${_id}`, body, config);
    // console.log(res.data)
    dispatch({
      type: CHECK_ATTENDANCE,
      payload: res.data
    })

    setTimeout(()=>{
      dispatch({
        type: RESET_DANCERS_UPDATED,
        payload: null
      })
    },3000)
 
  } catch (err) {
    const errors = err.response;

    // if (errors) {
    //   errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    // }

    dispatch({
      type: DANCER_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
      payload: err.response,
    });
  }
}

// upload photos
export const uploadFiles = (photos, videos) => async (dispatch) => {
  // // console.log.log('uploadDancerPhotos called');
  // // console.log.log('photos: ', photos);
  // // console.log.log('videos: ', videos);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };



  try {
    // photos.map(async photo => {
    //   const photoFormData = new FormData()
    //   photoFormData.append('photo', photo)
    //   const photoRes = await axios.post(
    //       '/api/dancers/upload-photo',
    //       photoFormData,
    //       config
    //   )
    // })
    const photoRes = await axios.post(
      '/api/dancers/upload-photos',
      photos,
      config
    );
    // const videoRes = await axios.post(
    //   '/api/dancers/upload-videos',
    //   videos,
    //   config
    // );
    // const photoRes = await axios.post(
    //   'http://localhost:5000/api/dancers/upload-photos',
    //   photos,
    //   config
    // );
    // const videoRes = await axios.post(
    //   'http://localhost:5000/api/dancers/upload-videos',
    //   videos,
    //   config
    // );
    // // console.log.log(
    //   'res.data received from dancers/upload-photos api: ',
    //   photoRes.data
    // );

    // // console.log.log(
    //   'res.data received from dancers/upload-videos api: ',
    //   videoRes.data
    // );
    document.getElementById('waiting-spinner').classList.add('display-none');
    setTimeout(()=>{window.close();},5000)
    dispatch(
      {
        type: UPLOAD_PHOTOS,
        payload: photoRes.data.msg,
      }
      // console.log.log(photoRes.data)
    );
    dispatch(setAlert(photoRes.data.msg, 'success'));


    // dispatch(
    //   {
    //     type: UPLOAD_VIDEOS,
    //     payload: videoRes.data,
    //   }
    // );

    // dispatch(setAlert('Photos Uploaded. 成功上传舞女文件', 'success'));


  } catch (err) {
    // console.log.log(err);
    dispatch({
      type: PHOTOS_ERROR,
      payload: { msg: 'Files upload failed. 文件片上传失败', status: '500' },
    });
    document.getElementById('waiting-spinner').classList.add('display-none');
  }
};

//   // upload videos
//  export const uploadDancerVideos = (formData, id) => async dispatch => {
//   // console.log.log("uploadDancerVideos called")
//   // console.log.log(formData);

//   const config = {
//     headers:{
//       "Content-Type":"multipart/form-data"
//     }
//   }

//   try {
//     // const res = await axios.post(
//     //   '/api/dancers/upload-videos',
//     //   formData,
//     //   config
//     // );
//     const res = await axios.post(
//       'http://localhost:5000/api/dancers/upload-videos',
//       formData,
//       config
//     );
//     // console.log.log("res.data received from dancers/upload-videos api: ",res.data);

//     dispatch({
//       type: UPLOAD_VIDEOS,
//       payload: res.data
//     })

//     dispatch(setAlert("videos Uploaded. 成功上传舞女视频", "success"));

//   } catch (err) {

//     dispatch({
//       type: VIDEOS_ERROR,
//       payload: { msg: "videos upload failed. 视频上传失败", status: "500" }
//     });

//   }
//   document.getElementById('waiting-spinner').classList.add('display-none')
// }

// add dancer to user's favorite
export const addToFavDancers = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `/api/dancers/add_favorite/${id}`,
      // `http://localhost:5000/api/dancers/add_favorite/${id}`
    );
    // console.log.log('res.data received from dancers/add_favorite', res.data);
    dispatch({
      type: ADD_TO_FAVORITE,
      payload: res.data,
    });
  } catch (err) {
    // console.log.log(err);
    dispatch({
      type: DANCERS_ERROR,
      // payload: err.response,
      payload: err
    });
  }
};
// remove dancer from user's favorite
export const removeFromFavDancers = (id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `/api/dancers/remove_favorite/${id}`,
      // `http://localhost:5000/api/dancers/add_favorite/${id}`
    );
    // console.log.log('res.data received from dancers/remove_favorite', res.data);
    dispatch({
      type: REMOVE_FROM_FAVORITE,
      payload: res.data,
    });
  } catch (err) {
    // console.log.log(err);
    dispatch({
      type: DANCERS_ERROR,
      // payload: err.response,
      payload: err
    });
  }
};

// filter dancers
export const filterDancers = (filters) => async (dispatch) => {
  // console.log.log('filters:', filters);
  const {wantToReserveDancers, formData} = filters
  try {
    if(!wantToReserveDancers && formData.favorite){
      setAlert('您尚未收藏任何舞女','dancer');
      dispatch({
        type: FILTER_DANCERS,
        payload: {...filters, favorite: null}
      })
    }else{
      dispatch({
        type: FILTER_DANCERS,
        payload: filters,
      });      
    }

  } catch (err) {
    dispatch({
      type: FILTER_DANCERS_ERROR,
      // payload: err.response,
      payload: err
    });
  }
};
