import React from 'react'
import PropTypes from 'prop-types'

import UserWithdrawBox from './UserWithdrawBox'

const SharingTab = ({user}) => {

    const showWithdrawBox = () => {
        document.getElementById('user-withdraw-box').classList.remove('display-none');
    }
  return (
    <div className="sharing">
        <h3 className='mt-0'>转发链接</h3>
        <textarea name="sharing-text" id="sharing-text" rows="5" defaultValue={`www.kuxiao555.com 苦笑人间舞厅资讯站 近千名舞女收录排名 随时预约心仪舞女 VIP折扣码  ${user.invitationCode} 输入即享折扣`}></textarea>
        <button className='btn btn-pink'>复制</button>
        <h3>专属折扣码</h3>
        <div className="display-flex">
            <p className='mr-2 text-bold'>{user.invitationCode}</p>
            <p className='desc-text'>任何人使用此码购买VIP，您将获得现金分成</p>
        </div>
        <h3>VIP分成金额</h3>
        <div className="display-flex">
            <p className='mr-2 text-bold'>{user.totalVipCommission ? user.totalVipCommission : 0}</p>
            <p className='desc-text'>任何人使用您的折扣码注册VIP，您将获得80现金分成，可提现</p>
        </div>
        <h3>折扣券金额</h3>
        <div className="display-flex">
            <p className='mr-2 text-bold'>{user.totalDiscountCommission ? user.totalDiscountCommission : 0}</p>
            <p className='desc-text'>任何人使用您的折扣码，可获得50优惠</p>
        </div>
        <h3>可提现金额</h3>
        <div className="display-flex">
            <p className='mr-2 text-bold'>{user.totalAvailable ? user.totalAvailable : 0}</p>
            <button className="btn-pink withdraw-btn" onClick={e => showWithdrawBox()}>申请提现</button>
            {/* <p className='desc-text'>本月还可提现2次</p> */}
        </div>
        <h3>提现中金额</h3>
        <div className="display-flex">
            <p className='mr-2 text-bold'>{user.withdrawInProcess ? user.withdrawInProcess : 0}</p>
        </div>
        <UserWithdrawBox user={user} />
    </div>
  )
}

SharingTab.propTypes = {
    user: PropTypes.object.isRequired
}

export default SharingTab