import { setAlert } from '../actions/alert';
import {
    GET_TRANSACTION,
    GET_TRANSACTIONS,
    TRANSACTION_ERROR,
    TRANSACTIONS_ERROR,
    NEW_TRANSACTION,
    DELETE_TRANSACTION,
    GET_AGENT_TRANSACTIONS,
    APPROVE_TRANSACTION
} from '../actions/types';

const initialState = {
    transactions: [],
    transactionsLoading: true,
    error: null
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        // case GET_TRANSACTION:
        //     return {
        //         ...state,
        //         transactionLoading: false,
        //         transaction: payload
        //     }
        case GET_TRANSACTIONS:
            return {
                ...state,
                transactionsLoading: false,
                transactions: payload ? payload : []
            }
        // case GET_AGENT_TRANSACTIONS:
        //     return {
        //         ...state,
        //         agentTransactions:payload,
        //         agentTransactionsLoading: false
        //     }
        case NEW_TRANSACTION:
            return {
                ...state,
                transactionsLoading: false,
                transactions: state.transactions.unshift(payload),
                // transactionLoading: false,
                // transaction: payload
            }
        // case APPROVE_TRANSACTION:
        //     setAlert('交易已审核通过', 'success');
        //     return {
        //         ...state, 
        //         transactions: state.transactions.find(item => item._id === payload._id) ? [...state.transactions.filter(trans => trans._id !== payload._id), payload] : state.transaction,
        //         transactionsLoading: false
        //     }
        // case DELETE_TRANSACTION:
        //     return {
        //         ...state,
        //         transactionsLoading: false,
        //         transactions: state.transactions.filter(item => item !== payload)
        //     }
        // case TRANSACTION_ERROR:
        case TRANSACTIONS_ERROR:
            return {
                ...state,
                error: payload
            }
        default:
            return state;
    }
}