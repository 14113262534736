import axios from "axios";
import { setAlert } from "./alert";

import {
    GET_CLUBS,
    NEW_CLUB,
    CLUBS_ERROR,
    DELETE_CLUB,
} from './types';


export const getClubs = () => async dispatch => {
    try {
        const res = await axios.get('/api/clubs/');
        // const res = await axios.get('http://localhost:5000/api/clubs/');
        // console.log("getClubs called in action");

        dispatch({
            type: GET_CLUBS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: CLUBS_ERROR,
            // payload: {
            //     status: err.response.status,
            //     msg: err.response.msg
            // }
            payload: err.response
        })
    }
}

export const newClub = (formData) => async dispatch => {
    try{
        const config = {
            headers: {
              "content-type": "application/json",
            },
        };
        const res = await axios.post(
            "/api/clubs/",
            // "http://localhost:5000/api/clubs/",
            formData,
            config
        );
        dispatch({
            type: NEW_CLUB,
            payload: res.data
        });

        dispatch(
            setAlert("舞厅添加成功", 'success')
        )
    }catch(err){
        dispatch({
            type: CLUBS_ERROR,
            payload: err.response
        })
    }
}

export const deleteClub = (id) => async dispatch => {
    try {
        const res = await axios.delete(`/api/clubs/${id}`);
        // const res = await axios.delete('http://localhost:5000/api/clubs/${id}');
        // console.log("deleteClub call in action")
        dispatch({
            type: DELETE_CLUB,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: CLUBS_ERROR,
            payload: {
                status: err.response.status,
                msg: err.response.msg
            }
        })
    }
}