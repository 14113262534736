import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_COMMENT,
  GET_COMMENTS,
  COMMENT_ERROR,
  COMMENTS_ERROR,
  NEW_COMMENT,
  DELETE_COMMENT,
  APPROVE_COMMENT,
} from './types';

// NEW COMMENT
export const newComment = (formdata) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ ...formdata, approved: false });

  try {
    const res = await axios.post(
      '/api/comments',
      // 'http://localhost:5000/api/comments',
      body,
      config
    );

    // dispatch({
    //   type: NEW_COMMENT,
    //   payload: res.data,
    // });

    // document
    //   .getElementById(`new-comment-box-for-${formdata.dancer}`)
    //   .classList.add('display-none');

    dispatch(setAlert('评论已发布,管理员审核通过后可见', 'success'));
    
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: err.response,
    });
  }
};

// APPROVE COMMENT
export const approveComment = (formData) => async (dispatch) => {
  console.log('approveComment called in action. formData: ', formData);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify(formData);
  try {
    const res = await axios.post(
      '/api/comments/approve',
      // `http://localhost:5000/api/comments/approve/${id}`
      body,
      config
    );

    dispatch({
      type: APPROVE_COMMENT,
      payload: res.data.comment,
    });
    dispatch(setAlert(res.data.msg, 'success'))
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: err.response,
    });
  }
};

// GET COMMENT
export const getComment = (id) => async (dispatch) => {
  try {
    // const res = await axios.get(`/api/comments/${id}`);
    const res = await axios.get(`http://localhost:5000/api/comments/${id}`);
    console.log('getComment called in action');

    dispatch({
      type: GET_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: err.response,
    });
  }

  document.getElementById('waiting-spinner').classList.add('display-none');
};

// GET COMMENTS
export const getComments = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/comments');
    // const res = await axios.get('http://localhost:5000/api/comments');
    // console.log('getComments called in action');

    dispatch({
      type: GET_COMMENTS,
      payload: res.data,
    });
  document.getElementById('waiting-spinner').classList.add('display-none');
  } catch (err) {
    dispatch({
      type: COMMENTS_ERROR,
      payload: err.response,
    });
  }

};

// GET APPROVED COMMENTS
export const getApprovedComments = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/comments/approved');
    // const res = await axios.get('http://localhost:5000/api/comments');
    // console.log('getComments called in action');

    dispatch({
      type: GET_COMMENTS,
      payload: res.data,
    });
  document.getElementById('waiting-spinner').classList.add('display-none');
  } catch (err) {
    dispatch({
      type: COMMENTS_ERROR,
      payload: err.response,
    });
  }

};

// GET COMMENTS FOR DANCER PAGE
export const getDancerComments = (dancerID) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/comments/${dancerID}`);
    // const res = await axios.get(`http://localhost:5000/api/comments/${dancerID}`);
    // console.log('getComments called in action');

    dispatch({
      type: GET_COMMENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMMENTS_ERROR,
      payload: err.response,
    });
  }

  document.getElementById('waiting-spinner').classList.add('display-none');
};

// DELETE COMMENT
export const deleteComment = (id) => async (dispatch) => {
  console.log('deleteComment called in action.', id);
  try {
      const res = await axios.delete(`/api/comments/${id}`);
    // await axios.delete(`http://localhost:5000/api/comments/${id}`);

    dispatch({
      type: DELETE_COMMENT,
      payload: res.data.commentID,
    });

    dispatch(setAlert(res.data.msg, 'success'));
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
  document.getElementById('waiting-spinner').classList.add('display-none');
};

//  CREATE NEW COMMENT (AUTOMATICLY GENERATED)
