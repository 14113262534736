import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'

import { addNewUser } from '../../actions/management';

const NewUserBox = ({addNewUser, accountType = "normal", id}) => {

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        accountType: accountType,
        invitationCode: ''
      });
      
  const [pw, setPw] = useState('')
      
//   const { username, password, email, accountType } = formData;

  const onPasswordChange = e => {
    setPw(e.target.value);
    // document.getElementById('password-checked').classList.add('display')
  };
  
  const onPasswordConfirm = e => {
    if(e.target.value === pw){
      setFormData({
        ...formData,
        password: e.target.value
      })
    }else{
      setFormData({
        ...formData,
        password: ''
      })
    } 
  }

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value})
  }

    const onClose = (a,b) => {
        if(a){const aElement = document.getElementById(a);
            aElement.classList.add('display-none');}
        if(b){const bElement = document.getElementById(b);
            bElement.classList.remove('display-none');}
    }

    const onSubmit = e => {
        e.preventDefault();
        addNewUser(formData)
        // console.log(formData)
    }

  return (
        <div className="login-window new-user-box display-none"  id={id}>
            <div className="popup-title">
                <h1 className="large text-primary">建立新管理员级别账户</h1>
                <button className="btn btn-close" onClick={e=>onClose(id)}>
                <FontAwesomeIcon icon={faSquareXmark} />
                </button>
            </div>
            <div className="login-grid border-bottom mb-1">
                <form className="login-form" id='new-user-box-username' onSubmit={e => onSubmit(e)}>
                <div className="login-form-grid">
                    <label htmlFor="username">用户名：</label>
                    <input
                    name="username"
                    type="text"
                    placeholder="用户名"
                    onChange={e => onChange(e)}
                    required
                    />
                </div>
                <div className="login-form-grid">
                    <label htmlFor="email">e-mail: </label>
                    <input
                    name="email"
                    placeholder="e-mail"
                    type="email"
                    onChange={e => onChange(e)}
                    required
                    />
                </div>
                <div className="login-form-grid">
                    <label htmlFor="email">账户级别: </label>
                    <select name="accountType" id="new-user-box-accountType-select" value={formData.accountType} onChange={e=>onChange(e)}>
                        <option value="manager">总管理员/manager</option>
                        <option value="admin">管理员/admin</option>
                        <option value="agent">宣传员/agent</option>
                        <option value="vip">VIP</option>
                        <option value="normal">普通会员</option>
                    </select>
                </div>
                {formData.accountType === 'agent' ? <div className="login-form-grid">
                    <label htmlFor="invitationCode">折扣码: </label>
                    <input
                    name="invitationCode"
                    placeholder="*不填写则随机生成5位折扣码"
                    type="text"
                    length="5"
                    onChange={e => onChange(e)}
                    />
                </div> : ""}
                <div className="login-form-grid">
                    <label htmlFor="password">密码: </label>
                    <input
                    name="password"
                    placeholder="密码"
                    type="text"
                    minLength="8"
                    onChange={e => onPasswordChange(e)}
                    required
                    />
                </div>
                
                <div className="login-form-grid">
                    <label htmlFor="passwordConfirm">确认密码: </label>
                    <input
                    name="passwordConfirm"
                    placeholder="确认密码"
                    type="text"
                    minLength="8"
                    onChange={e => onPasswordConfirm(e)}
                    required
                    />
                </div>
                <div className='login-btn-grid-2'>
                    <button
                        className="btn btn-pink mr-1"
                        onClick={e=>onClose(id)}
                    >取消</button>
                    <input
                        type="submit"
                        className="btn btn-pink ml-1"
                        value='创建账户'
                    />
                </div>
            </form>
        </div>
    </div>
  )
}

NewUserBox.propTypes = {
    addNewUser: PropTypes.func.isRequired,
    accountType: PropTypes.string
}

export default connect(null, {addNewUser})(NewUserBox)