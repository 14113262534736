import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import imgPlaceholder from '../../img/imgPlaceholder.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { checkAttendance } from '../../actions/dancer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';



const AttendanceItem = ({dancer, formData, checkAttendance}) => {
    // console.log(dancer);
    useEffect(()=>{},[dancer, formData])
    const { club, session, date } = formData
    
    // console.log(dancer.attendance.filter(item => item.club === club && item.session === session && item.date === date).length > 0)
  return (
    <div className='attendance-item' >
        <div className="attendance-item-img"onClick={e=>checkAttendance(dancer._id, formData)}>
            <img src={dancer.files.photos.find(photo => photo.order === 0) ? `/uploads/photos/${dancer.files.photos.find(photo => photo.order === 0).photo}` : imgPlaceholder} alt="头像" />
            {dancer.attendance.filter(item => item.club === club && item.session === session && item.date === date).length > 0 ? <div className="attendance-item-text">
                <div className="check-mark-overlay-container"> 
                    <p className="check-mark-overlay">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </p>
                </div>
            </div> : ""}
        </div>
        <p className='nickname'>{dancer.dancerID}</p>
        <p className='nickname'>{dancer.codeName}</p>
        <p className='nickname'>{dancer.nickname}</p>
        <p className='nickname'>
            <Link 
            to={`/dancer/${dancer.dancerID}`} 
            target="_blank" 
            rel="noopener noreferrer"
            className='text-pink'
            >舞女页</Link>
            <Link 
            to={`/dancer/edit/${dancer.dancerID}`} 
            target="_blank" 
            rel="noopener noreferrer"
            className='text-info'
            >编辑</Link>


        </p>
    </div>
  )
}

AttendanceItem.propTypes = {
    dancer: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    checkAttendance: PropTypes.func.isRequired
}

export default connect(null, {checkAttendance})(AttendanceItem)