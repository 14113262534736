import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// FontAwesome
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from "@fortawesome/free-brands-svg-icons";

import "./App.css";


// Utilities
import setAuthToken from "./utilities/setAuthToken";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// library.add(fab, faTimes, faFire, faShareSquare, faThumbsUp);

// Components
import Navbar from "./components/layout/Navbar";
import Dancer from "./components/dancer/Dancer";
import Home from './components/pages/Home';
import Footer from './components/layout/Footer';
// import Management from './components/pages/Management';
// import Login from './components/auth/Login';
import Alert from './components/layout/Alert';
// import Contact from './components/contact/contact';
// import NewDancer from './components/girls/NewDancer';
import ControlPanel from './components/admin/ControlPanel';
import WaitingSpinner from "./components/layout/WaitingSpinner";
import User from './components/user/User';
import PleaseBuyVIP from "./components/layout/PleaseBuyVIP";
import ApplyForFreeVIP from "./components/layout/ApplyForFreeVIP";
import NewDancer from "./components/admin/NewDancer";
import BuyVIP from "./components/user/BuyVIP";
import Blogs from './components/pages/Blogs';
import Blog from './components/blog/Blog';
import Attendance from "./components/admin/Attendance";


// Actions
import { loadUser } from "./actions/user";
import { getDancers } from "./actions/dancer";
import { getApprovedComments } from "./actions/comment";
import CreateBlog from "./components/blog/CreateBlog";
// import WeChatReserve from "./components/dancer/WeChatReserve";

// Route Utilities
// import ProtectedRoute from "./routing/ProtectedRoute";


// console.log(token);

// if (token) {
//   setAuthToken(token);
//   store.loadUser()
// }



function App() {
  const token = localStorage.getItem("DCtoken")
  
  // console.log(token);
  useEffect(() => {
    if(token){
      store.dispatch(loadUser())
    };
    store.dispatch(getDancers());
    store.dispatch(getApprovedComments());
  },[loadUser]);

   return (
    <Provider store = {store}>
      <Router>
          <Navbar />
          <Alert /> 
          <Routes>
            {/* main routes */}
            <Route exact path="/" element={<Home/>} />

            {/* routes for dancers */}
            <Route exact path="/dancer/:dancerID" element={<Dancer />} />            
            <Route exact path="/dancer/edit/:dancerID" element={<NewDancer edit={true}/>} />

            {/* routes for manage page */} 
            <Route exact path='/control-panel' element={<ControlPanel />} /> 
            <Route exact path="/attendance" element={<Attendance />} />
            <Route exact path="/attendance/:searchText" element={<Attendance />} />
            <Route exact path='/user' element={<User />} /> 
            <Route exact path='/control-panel/new-dancer' element={<NewDancer edit={false}/>}/>
            <Route exact path='/buy-vip' element={<BuyVIP />}/>
            <Route exact path='/blog' element={<Blogs />} />            
            <Route exact path='/blog/c/:category' element={<Blogs />} />
            <Route exact path='/blog/:blogID' element={<Blog />} />
            <Route exact path='/blog/p/new' element={<CreateBlog />} />
            <Route exact path="/blog/edit/:blogID" element={<CreateBlog />} />
            {/* PrivateRoute for edit girl */}

            {/* routes for control panel*/}
            {/* <Route 
              path='/control-panel' 
              element={
                <ProtectedRoute>
                  <ControlPanel />
                </ProtectedRoute>
              } 
            /> */}

          </Routes>
          {/* <Contact />*/}
          <Footer />
          <PleaseBuyVIP />
          <ApplyForFreeVIP />
          {/* <WeChatReserve /> */}
          <WaitingSpinner />
      </Router>
    </Provider>
  );
}

export default App;
