import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import Sortable from 'sortablejs';
import { gsap } from "gsap";

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';

import {getDancer, clearDancer,createUpdateDancer,uploadFiles, deleteDancer} from '../../actions/dancer';
import { getClubs } from '../../actions/club';
import Spinner from '../layout/Spinner';

const NewDancer = ({
    isGranted,
    userLoading,
    edit,
    clubs, 
    getClubs,
    dancer, 
    getDancer,
    dancerLoading,
    clearDancer,
    uploadFiles,
    createUpdateDancer,
    deleteDancer
}) => {

    const navigate = useNavigate()

    const [ itsRenderTime , setItsRenderTime ] = useState(0)

    useEffect(()=>{    
        if(!userLoading && !isGranted){
            navigate('/');
        }
    },[isGranted])

    const { dancerID } = useParams();
    
    useEffect(()=>{
        getClubs();
        if(edit && !dancer){getDancer(dancerID)}
        setItsRenderTime(itsRenderTime + 1);
    },[getClubs, getDancer])

    const [formData, setFormData] = useState({
        dancerID: '',
        nickname: '',
        codeName: '',
        hotness: '',
        price: '',
        showupClub: "",
        overallRating: "",
        beautifulness: null,
        age: null,
        height: null,
        clothing: "",
        breasts: "",
        dragonGirl: false,
        weChat: false, 
        body: '',
        files: {listPhoto:null, dancerPageFirstPhoto:null, photos:[], video:null, videos:[]}
    })
    
    const {
        price,
        nickname,
        codeName,
        hotness,
        showupClub,
        overallRating,
        beautifulness,
        age,
        height,
        clothing,
        breasts,
        dragonGirl,
        weChat,
        files,
        body
    } = formData;

    const { videos } = files;
    const [photos, setPhotos] = useState([])

    const [showupClubs, setShowupClubs] = useState([]);

    const [photosPreviewElement, setPhotosPreviewElement] = useState([])
    const [src , setSrc ] = useState([])

        
    const [photosToUpload, setPhotosToUpload] = useState({
        listPhotoFile: null, 
        dancerPageFirstPhotoFile : null,
        photoFiles:[], 
        maskedPhotoFiles:[], 
        heavilyMaskedPhotoFiles:[]
    })
    const [videosToUpload, setVideosToUpload] = useState({
        videoFile: null, 
        videoFiles:[]
    }) 

    const {listPhotoFile, dancerPageFirstPhotoFile,photoFiles,maskedPhotoFiles, heavilyMaskedPhotoFiles} = photosToUpload;
    const {videoFile,videoFiles} = videosToUpload

    // const [ photosSelectOptions, setPhotosSelectOptions ] = useState([])

    useEffect(()=>{
        console.log(photosToUpload)
        setItsRenderTime(itsRenderTime + 1);
    },[photosToUpload,videosToUpload])

    useEffect(()=>{
        // console.log('useEffect[dancer] triggerred')
        if(dancer){
            setFormData(dancer);
            // if(dancer && dancer.files.photos.lenght > 0){
            const photosPrestage = [];
            if(dancer.files.photos.length > 0){
                const sortedPhotos = dancer.files.photos.sort((a,b)=> parseInt(a.order) - parseInt(b.order))
                sortedPhotos.map(item => {
                    photosPrestage.push({order: item.order, src: `/uploads/photos/${item.photo}`, filename: item.photo})
                })
            }
            setPhotos(photosPrestage);
            dancer.showupClub.includes('/') ? setShowupClubs([...dancer.showupClub.split('/')]) : setShowupClubs([dancer.showupClub])
        }
    },[dancer])

    useEffect(()=>{
        const newPhotos = []
        photos.map(photo => newPhotos.push({order: photo.order, photo: photo.filename}))
        const sortedPhotos = newPhotos.sort((a,b)=> parseInt(a.order) - parseInt(b.order));
        // console.log(sortedPhotos);
        setFormData({
            ...formData,
            files: {
                ...files,
                photos: sortedPhotos
            }
        })
    },[photos])
    
    useEffect(()=>{setItsRenderTime(itsRenderTime + 1);},[files, photos, videos])
    useEffect(()=>{setItsRenderTime(itsRenderTime + 1);},[dancerLoading])
    useEffect(()=>{setItsRenderTime(itsRenderTime + 1);},[showupClubs])  
    useEffect(()=>{
        // console.log(age, parseInt(age).toString(), toString(age), height, toString(height), beautifulness, toString(beautifulness))
        console.log(formData);
        setItsRenderTime(itsRenderTime + 1);
    },[formData, setFormData])  

    useEffect(()=>{setItsRenderTime(itsRenderTime + 1);},[src, setSrc])

    useEffect(()=>{
        setTimeout(()=>{
            setItsRenderTime(itsRenderTime + 1);
        },1000)
    },[photosPreviewElement, setPhotosPreviewElement])
    
    useEffect(()=>{   
        // console.log(showupClubs)            
        setFormData({
            ...formData,
            showupClub: showupClubs.join('/')
        })
        setItsRenderTime(itsRenderTime + 1);
    },[showupClubs])


    const overallRatingDB = ['一次即可','值得尝试','值得常跳','完美舞女'];
    const clothingDB = [
        {key: 'F',value:"难操作"},
        {key: 'T',value:"好操作"},
        {key: 'S',value:"性感暴露"}
    ];
    const breastsDB = [
        {key: 'B',value:"偏小"},
        {key: 'C',value:"适中"},
        {key: 'D',value:"丰满"},
        {key: 'E',value:"爆乳"}
    ];
    const keys ={ 
        clothing:['F','T','S'],
        breasts: ['B','C','D','E']
    }

    const bodyDB = ['苗条', '丰满', '肉感', '多肉']


    // let i = clubs.length + 2;
    // let n = overallRatingDB.length + 2;


    const onRadioSelect = e => {
        // e.preventDefault();
        const field = e.target.id.split("-")[0];
        const fieldValue = e.target.id.split('-')[1];
        const labelText = `${field}-label-${fieldValue}`
        // switch(field){
        //     case 'dragonGril':
        if(fieldValue === "0"){
            setFormData({
                ...formData,
                [field]: ''
            })
        }else{
            setFormData({
                ...formData,
                [field]: e.target.value==='false'? false : true
            })
        }   
        // }
    }
    
    const onChange = e => {
        // e.preventDefault();
        const field = e.target.id.split("-")[0];
        switch(field){
            case 'showupClub':
                showupClubs.includes(e.target.value)? setShowupClubs(showupClubs.filter(item=>item!==e.target.value)):setShowupClubs([...showupClubs,e.target.value])
            case 'age':
            case 'height':
            case 'beautifulness':
                setFormData({
                    ...formData,
                    [field]: parseInt(e.target.value)
                })
            default:
                setFormData({
                    ...formData,
                    [field]: e.target.value
                })
        }
    }


    // -------------------------- Drag and Drop -------------------------------


    // ----------------------------- Divider ----------------------------------
    const [element, setElement] = useState({
        id: null,
        value: null
    })

    const onOrderChange = (e) => {        
        // setElement(document.getElementById(e.target.id))
        const filename = e.target.id.split('_')[1]
        setElement({id: e.target.id, value: e.target.value})
        // setFormData({
        //     ...formData,
        //     files: {
        //         ...files,
        //         photos:[
        //             ...photos.filter(photo => photo.photo !== filename),
        //             {order: parseInt(e.target.value) ,photo: filename}
        //         ]
        //     }
        // })
    }


    useEffect(()=>{
        // console.log(element);
        setTimeout(()=>{
            // // e.preventDefault();
        if(photos){
            if(element.id && element.value){
            let photosPrestage = [];
            let formDataPhotosPrestage = [];
            const elementID = element.id
            const filename = elementID.split('@')[1]
            // console.log(photos, filename);
            // console.log(photos.find(item => item.filename === filename))
            const oldElement = photos.find(item => item.filename === filename)
            const oldOrder = oldElement.order
            // console.log('from:',oldOrder, 'to: ', element.value);
            // // console.log(oldOrder)
            // // console.log(Math.max(parseInt(element.value),oldOrder))
            // // console.log(Math.min(parseInt(element.value),oldOrder))
            const otherPhotos = photos.filter(item => item.order !== oldOrder && (item.order > Math.max(parseInt(element.value),oldOrder) || item.order < Math.min(parseInt(element.value),oldOrder)) );
            // console.log('otherPhotos: ',otherPhotos)
            const photosNeedChange = photos.filter(item => item.order !== oldOrder && !otherPhotos.includes(item))
            
            // console.log('photosNeedChange: ',photosNeedChange)
            if(photosNeedChange.length > 0){
                parseInt(element.value) > oldOrder ? photosNeedChange.map(item =>{ 
                    formDataPhotosPrestage.push({order: item.order - 1, photo: item.filename}) 
                    photosPrestage.push({...item, order: item.order - 1})
                }) : photosNeedChange.map(item=> {
                    formDataPhotosPrestage.push({order: item.order + 1, photo: item.filename})
                    photosPrestage.push({...item, order: item.order + 1})
                })
            }
            formDataPhotosPrestage.push({ order: parseInt(element.value), photo: filename});
            photosPrestage.push({...oldElement, order: parseInt(element.value)})
            if(otherPhotos.length > 0){otherPhotos.map(
                item => {
                    formDataPhotosPrestage.push({order: item.order, photo: item.filename});
                    photosPrestage.push(item)
                }
            )}
            // formDataPhotosPrestage = formDataPhotosPrestage.concat(otherPhotos);
            formDataPhotosPrestage.sort((a,b) => parseInt(a.order) - parseInt(b.order))
            photosPrestage.sort((a,b) => parseInt(a.order) - parseInt(b.order));
            // console.log('photosPrestage:',photosPrestage);
            setPhotos(photosPrestage);

            // console.log('formDataPhotosPrestage:',formDataPhotosPrestage);

            // formDataPhotosPrestage.map(item => {
            //     // console.log(item);
            //     const element = document.getElementById(`order_${item.order}-${item.photo}`)
            //     element.value = parseInt(item.order)
            // })

            // // console.log(formDataPhotosPrestage)
            setFormData({
                ...formData, 
                files: {
                    ...formData.files, 
                    photos: formDataPhotosPrestage
                }
            })
        }}
        setItsRenderTime(itsRenderTime + 1);
        }, 200);
    },[element])


    const onFilesChange = e => {
        const field = e.target.name.split('-')[0];        
        const fileField = e.target.id.split('-')[0];
        switch (field){
            case 'photos':
                const photoFilesArray = Array.from(e.target.files);
                // const photosPreview = document.getElementById("photoFiles-preview");
                // photosPreview.innerHTML = "";
                const photosPrestage=[]
                const srcPrestage = [];
                const previewArrayPreStage = [];
                if(photoFilesArray.length > 0){
                    // let i = 1;

                    const photosSelectOptions = []
                    for(let x = 0; x<photos.length + photoFilesArray.length; x++){
                        photosSelectOptions.push(<option key={x} value={x}>{x}</option>)
                    }
                    photoFilesArray.map(file => {
                        let i = photoFilesArray.indexOf(file) + photos.length;
                        // const photoPreview = document.getElementById(`dancer-photos-img-${i}`)
                        const reader = new FileReader();
                        // if(photos.find( photo => photo.photo === file.name)){
                        //     document.getElementById(`dancers-preview-wrap-${photo.photo}`).remove();

                        // }
                        // console.log('i:',i)
                            reader.addEventListener("load", function(){
                                // photoPreview.src = reader.result;
                                const photoPreviewWrap = (
                                    <div className="dancer-photos-preview-wrap" id={`photos-preview-wrap-${file.name}`} key={`dancer-photos-preview-${i}`}>
                                        <h1 className='dancer-photos-preview-order'>{i}</h1>
                                        <img src={reader.result} alt="preview" id={`dancer-photos-img-${i}`} className="dancer-photos-preview"/>
                                        <select 
                                            name="dancer-photos-order-input" 
                                            className='dancer-photos-preview-input' 
                                            id={`order_${i}@${file.name}`} 
                                            defaultValue={i}
                                            onChange={e=> onOrderChange(e)}
                                            // onChange={e=> // console.log(photos)}
                                        >
                                            {/* { 
                                            
                                            photoFilesArray.map(item => {
                                                const n = photoFilesArray.indexOf(item) + photos.length;
                                                // console.log('n: ', n)
                                                return <option value={n}>{n}</option>
                                            })}

                                            */}
                                            {photosSelectOptions}
                                        </select>
                                    </div>
                                );
                                    
                                    // setPreviewArray({order: i})
                                // previewArray.sort((a,b) => {return parseInt(a.order) - parseInt(b.order)});
                                // photoPreview.src=reader.result;

                                photoFiles.push(file);
                                photosPrestage.push({order: i, photo : file.name})
                                srcPrestage.push({filename: file.name, src: reader.result});
                                previewArrayPreStage.push({order: i, src: reader.result, filename:file.name})
                                // : photoPreviewWrap
                            })
                            reader.readAsDataURL(file);    
                            
                            // photosPreview.appendChild(previewWrap);
                            // console.log('photosPrestage:', photosPrestage);
                            // console.log(photos);
                            // console.log('photos.concat(photosPrestage):', photos.concat(photosPrestage))
                            // const newPhotos = photos.concat(photosPrestage)
                            // setFormData(formData => ({
                            //     ...formData, 
                            //     files:{
                            //         ...files, 
                            //         photos: newPhotos.sort((a,b)=>parseInt(a.order) - parseInt(b.order))
                            //     }
                            // }));
                        
                        // i+=1;
                    })
                    setTimeout(()=>{
                        setSrc(srcPrestage);
                        setPhotos(photos.concat(previewArrayPreStage))
                        // // console.log(previewArrayPreStage)
                        // setPhotosPreviewElement(photosPreviewElement.concat(previewArrayPreStage)); 
                        setPhotosToUpload({...photosToUpload, photoFiles: photoFilesArray})
                    },200)
                }
                break;
            case 'maskedPhotos':
                const maskedPhotoFilesArray = Array.from(e.target.files);
                setTimeout(()=>{
                    setPhotosToUpload({...photosToUpload, maskedPhotoFiles: maskedPhotoFilesArray})
                })
                break;
            case 'heavilyMaskedPhotos':
                const heavilyMaskedPhotoFilesArray = Array.from(e.target.files);
                setTimeout(()=>{
                    setPhotosToUpload({...photosToUpload, heavilyMaskedPhotoFiles: heavilyMaskedPhotoFilesArray})
                })
                break;
            case 'videos':
                const videoFilesArray = Array.from(e.target.files);
                if(videoFilesArray.length > 0){
                    videoFilesArray.map(file => {
                        videos.unshift(file.name)
                    })
                    setFormData({ ...formData, videos: videos})
                }
                setVideosToUpload({...videosToUpload, videoFiles: videoFilesArray})
                break;
            case 'video':
                const videoFile = e.target.files[0];
                
                setFormData({ ...formData, files:{...files, video: videoFile.name}})
                setVideosToUpload({...videosToUpload, videoFile: videoFile})

                break;
            default: 
                const file = e.target.files[0];
                const reader = new FileReader();
                const photoPreviewParent = document.getElementById(`${fileField}-preview`);
                // if(photoPreviewParent.firstChild){photoPreviewParent.firstChild.remove();}
                let photoPreview;
                if(photoPreviewParent.firstChild){
                    photoPreview = photoPreviewParent.firstChild;
                    reader.addEventListener("load", function(){
                        photoPreview.src=reader.result;
                    })
                    reader.readAsDataURL(file)
                }else{
                    photoPreview = document.createElement('img');
                    photoPreview.setAttribute("class","dancer-photo-preview")
                    reader.addEventListener("load", function(){
                        photoPreview.src=reader.result;
                    })
                    reader.readAsDataURL(file);
                    // photoPreview.src = URL.createObjectURL(file);
                    photoPreviewParent.appendChild(photoPreview);
                }
                setFormData({
                    ...formData,
                    files: {...formData.files, [fileField]: file.name}
                });
                setPhotosToUpload({...photosToUpload, [fileField]: file})
                break;

        }
    }

    const clearNewDancerForm = e => {
        // e.preventDefault();
        setFormData({
            dancerID: '',
            nickname: '',
            codeName: '',
            hotness: '',
            price: '',
            showupClub: "",
            overallRating: "",
            beautifulness: 6,
            age: null,
            height: null,
            clothing: "",
            breasts: "",
            dragonGirl: false,
            weChat: false, 
            body: '',
            files: {listPhoto:null, dancerPageFirstPhoto:null, photos:[], video:null, videos:[]}
        })
        setPhotos([]);
        setShowupClubs([]);
        setPhotosPreviewElement([]);
        setSrc([]);
        setPhotosToUpload({
            listPhotoFile: null, 
            dancerPageFirstPhotoFile : null,
            photoFiles:[], 
            maskedPhotoFiles:[], 
            heavilyMaskedPhotoFiles:[]
        });
        setVideosToUpload({
            videoFile: null, 
            videoFiles:[]
        });
        setItsRenderTime(0);
        setElement({id:null, value:null});
        clearDancer();
    }

    const onCreateNewDancer = e => {
        e.preventDefault();
        document.getElementById('waiting-spinner').classList.remove('display-none');
        const filesFormData = new FormData()
        filesFormData.append('listPhotoFile', listPhotoFile);
        filesFormData.append('dancerPageFirstPhotoFile', dancerPageFirstPhotoFile);
        const allPhotoFiles = [...photoFiles, ...maskedPhotoFiles, ...heavilyMaskedPhotoFiles]
        
        allPhotoFiles.map(item => {console.log('photoFiles: ', item);filesFormData.append('photoFiles', item)});
        // photoFiles.map(item => {console.log('photoFiles: ', item);filesFormData.append('photoFiles', item)});
        // maskedPhotoFiles.map(item => {console.log('maskedPhotoFiles: ', item);filesFormData.append('photoFiles', item)})
        // heavilyMaskedPhotoFiles.map(item => {console.log('heavilyMaskedPhotoFiles: ', item);filesFormData.append('photoFiles', item)})

        // console.log('filesFormData:', filesFormData)
        // const videosFormData = new FormData();
        // videosFormData.append('videoFile', videoFile);
        // videoFiles.map(item=>videosFormData.append('videoFiles', item));        
        // // console.log('videoFiles:', videoFiles)
        // setTimeout(()=>{uploadFiles(filesFormData,videosFormData);}, 500)

        setTimeout(()=>{
            console.log(filesFormData);
            uploadFiles(filesFormData);
        }, 3000)
        createUpdateDancer(formData, edit);

        if(edit === true){
            setTimeout(()=>{                
                clearNewDancerForm();
            },3000)
        }
        // console.log(photosPreviewElement);
    }
    
    const closeNewDancer = e => {
        e.preventDefault();
        clearNewDancerForm();
        // const newDancerElement = document.getElementById('new-dancer');
        // if(newDancerElement){newDancerElement.remove()}
        window.close();
        // navigate(-1)
    }

    const onDeleteDancer = e => {
        e.preventDefault();
        deleteDancer(dancer._id);
        setTimeout(()=>{
            navigate('/')
        },3000)
    }

    const onShowDeleteDancerConfirm = e => {
        e.preventDefault();
        const element = document.getElementById(`delete-dancer-confirm-box-for-${dancerID}`);
        element.classList.remove('display-none');
    }

    const onClose = id => {
        const element = document.getElementById(id);
        element.classList.add('display-none')
    }

    const onRemovePhoto = (e, order) => {
        e.preventDefault();
        // // console.log(order);
        const photoToRemove = photos.find(item => item.order === order );
        // // console.log('photoToRemove.filename: ', photoToRemove.filename)
        // const otherPhotos =  photos.filter(item => item.order !== order);
        // // console.log('otherPhotos: ', otherPhotos)
        // // console.log(otherPhotos.includes(item => item.filename === photoToRemove.filename));
        // if(!otherPhotos.includes(item => item.filename === photoToRemove.filename)){
            setPhotosToUpload({
                ...photosToUpload,
                photoFiles: photosToUpload.photoFiles.filter(item => item.src !== photoToRemove.src)
            })
        // } 
        const newPhotos = [];
        const leftOverPhotos = photos.filter(item => item.order !== order)
        // console.log(leftOverPhotos);
        leftOverPhotos.map(photo => 
            photo.order < order 
            ? newPhotos.push(photo)
            :newPhotos.push({...photo, order: photo.order - 1})
        )
        const sortedPhotos = newPhotos.sort((a,b) => parseInt(a.order) - parseInt(b.order))
        setPhotos(sortedPhotos);
    }

    // useEffect(()=>{// console.log(formData)},[])

    useEffect(()=>{},[itsRenderTime, photosPreviewElement])


    return edit && dancerLoading ? <Spinner /> : <div className="container" id='new-dancer'>
        <h2 className='text-align-center bg-pink py-1'>新建舞女信息</h2>
        <form action="" className='search-form new-dancer-form' id="new-dancer-form" onSubmit={e=>onCreateNewDancer(e)}>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">dancerID：</h4>
                <div className="form-input-group">                
                    <div className="form-input">
                        <input type="text" name="dancerID-input" id="dancerID-input" placeholder='dancerID' value={formData.dancerID ? formData.dancerID : ''}onChange={e=>onChange(e)}/>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">舞厅：</h4>
                <div className="form-input-group">
                    {clubs.length > 0 ? clubs.map( item => {
                        let i = clubs.indexOf(item); 
                        return (
                            <div className="form-input" key={`showupClub-key-${i}`}>
                                <input type="checkbox" className='check-box' id={`showupClub-${i}`} onChange={e => onChange(e)} value={item.name}/>
                                <label className={showupClubs.includes(item.name) ? 'form-input-label showupClub-class active' : 'form-input-label showupClub-class'} htmlFor={`showupClub-${i}`} id={`showupClub-label-${i}`}>{item.name}</label>
                            </div>
                        )
                    }): ""}
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">内部昵称：</h4>
                <div className="form-input-group">                
                    <div className="form-input">
                        <input type="text" name="nickname-input" id="nickname-input" placeholder='内部昵称' value={nickname ? nickname : ''}onChange={e=>onChange(e)}/>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">工号：</h4>
                <div className="form-input-group">                
                    <div className="form-input">
                        <input type="text" name="codeName-input" id="codeName-input" placeholder='工号' value={codeName ? codeName : ''}onChange={e=>onChange(e)}/>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">总评：</h4>
                <div className="form-input-group">
                    <div className="form-input">
                        <select name="overallRating-select" id="overallRating-select" value={overallRating ? overallRating : 'placeholder'} onChange={e => onChange(e)}>
                            <option value='placeholder' key={'overallRating-5'} disabled>请选择</option>
                            {overallRatingDB.map(item => {
                                let n = overallRatingDB.indexOf(item); 
                                return <option value={item} key={`overallRating-${n}`}>{item}</option>
                            })}
                        </select>
                    </div>
                    {/* {
                        overallRatingDB.map(item =>  
                            <div className="form-input" key={`overallRating-${overallRatingDB.indexOf(item)}`}>
                                <input type="checkbox" className='check-box' id={`overallRating-${overallRatingDB.indexOf(item)+1}`} onChange={e => onChange(e)} value={item}/>
                                <label className={overallRating.includes(item) ? 'form-input-label overallRating-class active' : 'form-input-label overallRating-class'} htmlFor={`overallRating-${overallRatingDB.indexOf(item)+1}`} id={`overallRating-label-${overallRatingDB.indexOf(item)+1}`} >{item}</label>
                            </div>
                        )
                    } */}
                </div>
            </div>
            
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">抢手程度：</h4>
                <div className="form-input-group">                
                    <div className="form-input">
                        <input type="text" name="hotness-input" id="hotness-input" placeholder='抢手程度' value={hotness ? hotness : ''}onChange={e=>onChange(e)}/>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">颜值：</h4>
                <div className="form-input">
                    {/* <label className='form-input-label' htmlFor="beautifulness-select" id='beautifulness-select-label'>全选</label> */}
                    <select name="beautifulness-select" id="beautifulness-select" value={beautifulness ? parseInt(beautifulness).toString() : 'placeholder'} onChange={e => onChange(e)}>
                        <option value='placeholder'  disabled>请选择</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                    </select>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">年龄：</h4>  
                <div className="form-input-group">                
                    <div className="form-input">
                        {/* <input type="number" name="age-input" id="age-input" placeholder='请输入年龄数字' onChange={e=>onChange(e)}/>  */}
                        <select name="age-select" id="age-select" value={age ? parseInt(age).toString() : 'placeholder'} onChange={e => onChange(e,"age")}>
                            <option value='placeholder'  disabled>请选择</option>
                            <option value='0'>0（0-20）</option>
                            <option value="1">1（21-25）</option>
                            <option value="2">2（26-30）</option>
                            <option value="3">3（31-35）</option>
                            <option value="4">4（36-40）</option>
                            <option value="5">5（41-50）</option>
                            <option value="6">6（50-99）</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">身高：</h4>
                <div className="form-input">
                    {/* <label className='form-input-label' htmlFor="height-select" id='height-select-label'>全选</label> */}
                    <select name="height-select" id="height-select" value={height ? parseInt(height).toString() : 'placeholder'} onChange={e => onChange(e,"height")}>
                        <option value='placeholder'  disabled>请选择</option>
                        <option value="4">4:≤155</option>
                        <option value="5">5:156-160</option>
                        <option value="6">6:161-165</option>
                        <option value="7">7:166-170</option>
                        <option value="8">8:&gt;170</option>
                    </select>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">衣着：</h4>
                <div className="form-input-group">
                    
                    <div className="form-input">
                        <select name="clothing-select" id="clothing-select" value={clothing ? clothing : 'placeholder'} onChange={e => onChange(e)}>
                            <option value='placeholder' key={`clothing-key-${clothingDB.length + 1}`} disabled>请选择</option>
                            {clothingDB.map(item => <option key={`clothing-key-${clothingDB.indexOf(item)}`} value={item.key}>{item.value}</option>)}
                        </select>
                    </div>
                    
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">胸部：</h4>
                <div className="form-input-group">                
                    <div className="form-input">
                        <select name="breasts-select" id="breasts-select" value={breasts ? breasts : 'placeholder'}  onChange={e => onChange(e)}>
                            <option value='placeholder' key={`breasts-key-${breastsDB.length + 1}`} disabled>请选择</option>
                            {breastsDB.map(item => <option key={`breasts-key-${breastsDB.indexOf(item)}`} value={item.key}>{item.value}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">体态：</h4>
                <div className="form-input-group">                
                    <div className="form-input">                                                
                        <select name="body-select" id="body-select" value={body ? body : 'placeholder'} onChange={e => onChange(e)}>
                            <option value='placeholder' key={'body-placeholder'} disabled>请选择</option>
                            {bodyDB.map(item => {
                                let n = bodyDB.indexOf(item); 
                                return <option value={item} key={`body-${n}`}>{item}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">龙女：</h4>
                <div className="form-input-group">
                    
                    <div className="form-input">
                        <input type="radio" name='dragonGirl-radio' className='check-box' id='dragonGirl-1' value='false' onChange={e=>onRadioSelect(e)} />
                        <label className={dragonGirl === false ?  'form-input-label dragonGirl-class active' : 'form-input-label dragonGirl-class'} htmlFor="dragonGirl-1" id="dragonGirl-label-1" >正常女</label>
                    </div>
                    
                    <div className="form-input">
                        <input type="radio" name='dragonGirl-radio' className='check-box' id='dragonGirl-2' value='true' onChange={e=>onRadioSelect(e)} />
                        <label className={dragonGirl === true ?  'form-input-label dragonGirl-class active' : 'form-input-label dragonGirl-class'} htmlFor="dragonGirl-2" id="dragonGirl-label-2" >龙女</label>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">微信：</h4>
                <div className="form-input-group">
                    
                    <div className="form-input">
                        {/* <input type="text" name="weChat-input" id="weChat-input" placeholder='请输入微信' onChange={e=>onChange(e)} />  */}
                        <input type="radio" name='weChat-radio' className='check-box' id='weChat-1' value={true} onChange={e=>onRadioSelect(e, 'weChat')} />
                        <label className={weChat === true || weChat === 'true' ?  'form-input-label weChat-class active' : 'form-input-label weChat-class'} htmlFor="weChat-1"  id="weChat-label-1">可加微信</label>
                    </div>
                    <div className="form-input">
                        <input type="radio" name='weChat-radio' className='check-box' id='weChat-2' value={false} onChange={e=>onRadioSelect(e, 'weChat')} />
                        <label className={weChat === false || weChat === 'false' ?  'form-input-label weChat-class active' : 'form-input-label weChat-class'}  htmlFor="weChat-2" id="weChat-label-2">暂无微信</label>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">级别（价格）：</h4>
                <div className="form-input-group">                
                    <div className="form-input">
                        <input type="text" name="price-input" id="price-input" placeholder='请输入级别（价格）' value={price ? price : ''}onChange={e=>onChange(e)}/>
                        {/* <span>元/曲</span> */}
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">列表页展示照片：</h4>
                <div className="form-input-group">                
                    <div className="form-input photos-input">
                        <input type="file" name="listPhoto-input" id="listPhotoFile-input" onChange={e=>onFilesChange(e)}/>
                    </div>
                    <div className="photos-preview-group">
                        <h4 className='sub-label'>照片预览：</h4>
                        <div className="dancer-photos-preview" id="listPhotoFile-preview"></div>
                    </div>
                </div>
            </div>
            {/* <div className="search-field search-field-new-dancer">
                <h4 className="form-label">舞女页首照片：</h4>
                <div className="form-input-group">                
                    <div className="form-input photos-input">
                        <input type="file" name="dancerPageFirstPhoto-input" id="dancerPageFirstPhotoFile-input" onChange={e=>onFilesChange(e)}/>
                    </div>
                    <div className="photos-preview-group">
                        <h4 className='sub-label'>照片预览：</h4>
                        <div className="dancer-photos-preview" id="dancerPageFirstPhotoFile-preview"></div>
                    </div>
                </div>
            </div> */}
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">无码照片：</h4>
                <div className="form-input-group">                
                    <div className="form-input photos-input">
                        <input type="file" multiple name="photos-input" id="photoFiles-input" onChange={e=>onFilesChange(e)}/>
                    </div>
                    {photos.length > 0 ? 
                        <div className="photos-preview-groups">
                        
                            <h4 className='sub-label'>照片预览：</h4>
                            <div className="photos-preview-group">
                                 
                                
                                {(photos.sort((a,b) => parseInt(a.order) - parseInt(b.order))).map(photo => 
                                    <div className="dancer-photos-preview-wrap" id={`photos-preview-wrap-${photo.order}`} key={`dancer-photos-preview-${photo.order}`}>
                                        <h1 className='dancer-photos-preview-order'>{photo.order + 1}</h1>
                                        {/* <img src={`/uploads/photos/${photo.photo}`} alt="preview" id={`dancer-photos-img-${photo.order}`} className="dancer-photos-preview"/> */}
                                        <img src={photo.src} alt="preview" id={`dancer-photos-img-${photo.order}`} className="dancer-photos-preview"/>
                                        <button className='btn remove-img-btn' id={`photoRemoveBtn-${photo.order}`} onClick={e=>onRemovePhoto(e,photo.order)}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                                        <p className="text-small dancer-photos-preview-name">{photo.filename}</p>
                                        <select 
                                            name="dancer-photos-order-input" 
                                            className='dancer-photos-preview-input' 
                                            id={`order_${photo.order}@${photo.filename}`} 
                                            value={photo.order}
                                            onChange={e=> onOrderChange(e)}
                                            // onChange={e=> // console.log(photos)}
                                        >
                                            {(photos.sort((a,b) => parseInt(a.order) - parseInt(b.order))).map(item => 
                                                <option key={`${item.photo}-${item.order}`} value={item.order}>{item.order + 1}</option>
                                            )}
                                        </select>
                                    </div>
                                )} 
                                {/* {photosPreviewElement}      */}
                            </div>
                        </div> : ''
                    }
                    {/* <div className="photos-preview-groups">
                        <h4 className='sub-label'>照片预览：</h4>
                        <div className="photos-preview-group">
                            
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">薄码照片：</h4>
                <div className="form-input-group">                
                    <div className="form-input photos-input">
                        <input type="file" multiple name="maskedPhotos-input" id="maskedPhotoFiles-input" onChange={e=>onFilesChange(e)}/>
                        <div className="display-flex">
                           {maskedPhotoFiles.map(file => <p className="display-flex mr-1" key={`maskedPhoto-${file.name}`}>{file.name}</p>)} 
                        </div>                        
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">厚码照片：</h4>
                <div className="form-input-group">                
                    <div className="form-input photos-input">
                        <input type="file" multiple name="heavilyMaskedPhotos-input" id="heavilyMaskedPhotoFiles-input" onChange={e=>onFilesChange(e)}/>   
                        <div className="display-flex">
                            {heavilyMaskedPhotoFiles.map(file => <p className="display-flex mr-1" key={`heavilyMaskedPhoto-${file.name}`}>{file.name}</p>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">主视频：</h4>
                <div className="form-input-group">                
                    <div className="form-input photos-input">
                        <input type="file" name="video-input" id="videoFile-input" onChange={e=>onFilesChange(e)}/>
                    </div>
                </div>
            </div>
            <div className="search-field search-field-new-dancer">
                <h4 className="form-label">更多视频：</h4>
                <div className="form-input-group">                
                    <div className="form-input photos-input">
                        <input type="file" multiple name="videos-input" id="videoFiles-input" onChange={e=>onFilesChange(e)}/>
                    </div>
                </div>
            </div>
            {/* <div className="search-field search-field-new-dancer">
                <div className="form-label"></div> */}
                <div className="display-flex">
                    <button type="submit" className='btn btn-pink new-dancer-submit-btn ml-auto mr-1'>{edit ? '更新' : '创建'}舞女信息</button>
                    <button className="btn btn-white new-dancer-submit-btn mx-1" onClick={e=>onShowDeleteDancerConfirm(e)}>删除舞女</button>
                    {edit ? '': <button className='btn btn-pink new-dancer-submit-btn mx-1' onClick={e=>clearNewDancerForm(e)}>清空表格信息</button>}
                    <button className='btn btn-pink new-dancer-submit-btn ml-1 mr-auto' onClick={e=>closeNewDancer(e)}>取消</button>
                </div>
            {/* </div> */}
        
        </form>
        <div className="please-buy-vip display-none" id={`delete-dancer-confirm-box-for-${dancerID}`}>
            <div className="info-box">
                <div className="info-box-title">
                    <h2>确认删除该舞女？</h2>
                    <button className="btn btn-close-white" onClick={e=>onClose(`delete-dancer-confirm-box-for-${dancerID}`)}>
                        <FontAwesomeIcon icon={faXmarkCircle} />
                    </button>
                </div>
                <div className="info-box-content">
                    <div className="grid-two">
                        <button className='btn btn-pink big-square-btn' onClick={e=>onDeleteDancer(e)}>
                            删除
                        </button>
                        <button className='btn btn-pink big-square-btn' onClick={e=>onClose(`delete-dancer-confirm-box-for-${dancerID}`)}>
                            取消
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
}

NewDancer.propTypes = {
    isGranted: PropTypes.bool.isRequired,
    userLoading: PropTypes.bool.isRequired,
    edit: PropTypes.bool,
    dancer: PropTypes.object,
    dancerLoading: PropTypes.bool,
    uploadFiles: PropTypes.func.isRequired,
    clubs: PropTypes.array.isRequired,
    getClubs: PropTypes.func.isRequired,
    clearDancer: PropTypes.func.isRequired,
    getDancer: PropTypes.func.isRequired,
    deleteDancer: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    dancer: state.dancer.dancer,
    dancerLoading: state.dancer.dancerLoading,
    clubs: state.club.clubs,
    isGranted: state.auth.isGranted,
    userLoading: state.user.userLoading
})

export default connect(mapStateToProps, {createUpdateDancer, getClubs, uploadFiles,clearDancer, getDancer, deleteDancer})(NewDancer)