import React,{Fragment, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";

import { clearBtnActive, changeBtnShow } from "../../functions/functions";

// import Spinner from '../layout/Spinner';
import DancersItem from '../dancer/DancersItem';
// import NewCommentBox from '../dancer/NewCommentBox';

const Dancers = ({filteredDancers}) => {

  const [currentPageDancers, setCurrentPageDancers ] = useState([])

  const [i, setI] = useState(0);

  const [ renders, setRenders ] = useState(0);

  useEffect(()=>{
    // if(renders === 0){window.scrollTo(0,0)}
    // console.log(renders)
  }, [renders])

  useEffect(()=>{
    try{
      if(renders < 3){
        window.scrollTo(0,0)
      }else{
        const scrollToElement = document.getElementById('scrollToElement');
        scrollToElement.scrollIntoView({ behavior: "smooth"});
      }
    }catch(err){
      console.log(err)
    }
    // setRenders(renders + 1);
  },[i])
  
  useEffect(()=>{
    setCurrentPageDancers(filteredDancers.slice(i,i+20))
    // setRenders(renders + 1);
  },[filteredDancers, i])


  let length = filteredDancers.length;
  let pagi = Math.ceil(length / 20);
  let pagiArr = [];
  for (let x = 0; x < pagi; x++) {
    pagiArr.push(x + 1);
  }

  // Pagination button controls
  const pageOne = async (e) => {
    try {
      clearBtnActive();
      setI(0);
      setRenders(renders + 1);
      const btn = await document.getElementById("pagi-btn-1");
      if (btn) {
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const pagePrev = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i - 20;
      if (pageStartAt < 0) {
        pageOne(e);
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }
      
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageNext = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i + 20;
      if (pageStartAt > length) {
        pageLast(e);
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        if (parseInt(btn.innerHTML) > 2 || parseInt(btn.innerHTML) < pagi - 1) {
          for (
            let h = parseInt(btn.innerHTML) - 2;
            h <= parseInt(btn.innerHTML) + 2;
            h++
          ) {
            document
              .getElementById(`pagi-btn-${pagi}`)
              .classList.add("pagi-btn-show");
          }
          changeBtnShow(pagi);
        }
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageLast = (e) => {
    try {
      clearBtnActive();
      setI((pagi - 1) * 20);
      setRenders(renders + 1);
      const btn = document.getElementById(`pagi-btn-${pagi}`);
      btn.classList.add("pagi-btn-active");
      changeBtnShow(pagi);
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageX = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = (parseInt(e.target.innerHTML) - 1) * 20;
      setI(pageStartAt);
      setRenders(renders + 1);
      const btn = document.getElementById(`pagi-btn-${e.target.innerHTML}`);
      btn.classList.add("pagi-btn-active");
      changeBtnShow(pagi);
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageNextFive = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i + 60;
      if (pageStartAt > length) {
        pageLast();
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const pagePrevFive = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i - 60;
      if (pageStartAt < 0) {
        pageOne();
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  
  const nums = [];
  for (let n = 0; n < 20; n++) {
    nums.push(n);
  }

  useEffect(()=>{
    // console.log('filteredDancers.length changed');
    pageOne()
  },[filteredDancers.length])

  return filteredDancers.length > 0 ? (
    
    <Fragment>
        <div className='grid-container-dancers' id='dancers-container'>
            {currentPageDancers.map(dancer => (
                <DancersItem dancer={dancer} key={`dancer-item-${dancer.dancerID}`} controlpanel={false}/>
            ))}
        </div>
        <div className="pagination-wrapper">
          <div className="pagination">
            <button
              className="pagi-btn"
              id="pagi-btn-first"
              onClick={(e) => pageOne(e)}
            >
              &laquo;
            </button>
            <button
              className="pagi-btn"
              id="pagi-btn-prev"
              onClick={(e) => pagePrev(e)}
            >
              &lt;
            </button>
            {pagiArr.length > 5 ? (
              <button
                id="pagi-prev-five"
                className="pagi-btn"
                onClick={(e) => pagePrevFive()}
              >
                ...
              </button>
            ) : (
              ""
            )}
            {pagiArr.map((page) => {
              if (page === 1) {
                return (
                  <button
                    key={`pagi-${page}`}
                    className="pagi-btn pagi-btn-show pagi-btn-active"
                    onClick={(e) => pageX(e)}
                    id={"pagi-btn-1"}
                  >
                    {page}
                  </button>
                );
              } else if (page > 1 && page <= 5) {
                return (
                  <button
                    key={`pagi-${page}`}
                    className="pagi-btn pagi-btn-show"
                    onClick={(e) => pageX(e)}
                    id={`pagi-btn-${page}`}
                  >
                    {page}
                  </button>
                );
              } else {
                return (
                  <button
                    key={`pagi-${page}`}
                    className="pagi-btn"
                    onClick={(e) => pageX(e)}
                    id={`pagi-btn-${page}`}
                  >
                    {page}
                  </button>
                );
              }
            })}
            {pagiArr.length > 5 ? (
              <button
                id="pagi-next-five"
                className="pagi-btn show"
                onClick={(e) => pageNextFive(e)}
              >
                ...
              </button>
            ) : (
              ""
            )}
            <button
              className="pagi-btn show"
              id="pagi-btn-next"
              onClick={(e) => pageNext(e)}
            >
              &gt;
            </button>
            <button
              className="pagi-btn show"
              id="pagi-btn-last"
              onClick={(e) => pageLast(e)}
            >
              &raquo;
            </button>
          </div>
        </div>
        </Fragment>) : <h3 className='mt-2 text-align-center'>搜索结果为0</h3>
}

Dancers.propTypes = {
  filteredDancers: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  filteredDancers: state.dancer.filteredDancers,
})

// export default Dancers
export default connect(mapStateToProps, {})(Dancers)