import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

import { updateUser } from '../../actions/user';
import { getDancer, addToFavDancers, removeFromFavDancers, setDancer } from '../../actions/dancer';
// import testImg from '../../img/test.jpg';

import { showLogin } from '../../functions/functions';
import WeChatReserve from './WeChatReserve';
import NewCommentBox from './NewCommentBox';
import Spinner from '../layout/Spinner';

const DancersItem = ({
  auth,
  dancer,
  user,
  userLoading,
  updateUser,
  addToFavDancers,
  removeFromFavDancers,
  controlpanel,
  getDancer,
  setDancer,
  comments
  // componentKey
}) => {

  const navigate = useNavigate();

  useEffect(()=>{},[dancer,user, userLoading, auth])

  // const { wantToReserveDancers } = user;
  const {
    _id,
    dancerID,
    nickname,
    codeName,
    favoritedTimes,
    overallRating,
    beautifulness,
    age,
    height,
    clothing,
    breasts,
    dragonGirl,
    weChat,
    showupClub,
    price,
    hotness,
    chidu,
    files,
    body
  } = dancer;

  const { listPhoto, photos } = files;

  const [photo, setPhoto ] = useState()

  useEffect(()=>{
    if(dancer && (files.listPhoto || files.photos.length > 0)){
      // console.log(dancer.files.photos)
      const firstPhoto = files.photos.find(photo => photo.order === 0)
      // const lightMosaicPhoto = `${(firstPhoto.photo).split('.')[0]}_M.${(firstPhoto.photo).split('.')[1]}`
      // const heavyMosaicPhoto = `${(firstPhoto.photo).split('.')[0]}_HM.${(firstPhoto.photo).split('.')[1]}`
      console.log(files.listPhoto)
      const noMosaicPhoto = firstPhoto.photo;
      const lightMosaicPhoto = `${(noMosaicPhoto).split('.')[0]}_M.${(noMosaicPhoto).split('.')[1]}`
      const heavyMosaicPhoto = `${(noMosaicPhoto).split('.')[0]}_HM.${(noMosaicPhoto).split('.')[1]}`
      
      // setTimeout(()=>{
        if(user){
          // switch(accountType){
          //   case 'normal':
          //     if(user.freeVipTimer){
          //       setPhoto(lightMosaicPhoto)
          //     }else{
          //       setPhoto(heavyMosaicPhoto)
          //     }
          //   case 'vip':
          //   case 'agent':
          //     setPhoto(lightMosaicPhoto)
          //   case 'manager':
          //   case 'super':
          //   case 'vvip':
          //     setPhoto(noMosaicPhoto)
          //   default:
          //     setPhoto(heavyMosaicPhoto)

          // }
          if(user.accountType === 'normal'){
            if(user.freeVipTimer){
              setPhoto(lightMosaicPhoto)
            }else{
              setPhoto(heavyMosaicPhoto)
            }
            
          // }else if(user.accountType === 'vip' || user.accountType === 'admin'){
          //   setPhoto(lightMosaicPhoto)
          // }else{
          //   setPhoto(noMosaicPhoto)
          // }
          
          }else if(user.accountType === 'super' || user.accountType === 'manager' || user.accountType === 'agent'){
            setPhoto(noMosaicPhoto)
          }else{
            setPhoto(lightMosaicPhoto)
          }
        }else{
          setPhoto(heavyMosaicPhoto)
        }
      // },500)
    }
  },[dancer, user])

  const [firstComment, setFirstComment ] = useState(); 
  useEffect(()=>{},[firstComment, photo, setPhoto])

  useEffect(()=>{
    const dancerComment = comments.length > 0 ? comments.find(comment => comment.dancerID === dancerID) : '';
    setFirstComment(dancerComment)
  },[comments])

  // const [reserveBox, setReserveBox] = useState()
  const reserveBox = <WeChatReserve dancerID={dancerID} weChat={weChat} key={dancerID}/>
  // const commentBox = <NewCommentBox dancerID={dancerID} elementID={`dancerItem-${dancerID}-commentBox`}/>

  // if(user && !user.wantToReserveDancers.includes(_id)){
  //     setReserveBtn(<button className='reserve-btn'>预约</button>)
  // }else{
  //     setReserveBtn('')
  // }

  const onGoToEditDancer = e => {
    getDancer(dancerID);
    navigate(`/dancer/edit/${dancerID}`)
  }

  const taggleFavoriteDancers = (e, id) => {
    // e.preventDefault();
    // console.log(user.user.wantToReserveDancers.includes(id))
    if(user.wantToReserveDancers.includes(id)){
      console.log('remove')
      updateUser({
        ...user,
        wantToReserveDancers: user.wantToReserveDancers.filter(item => item !== id)
      })
      removeFromFavDancers(id)
    }else{ 
      console.log('add')
      updateUser({
        ...user,
        wantToReserveDancers: [...user.wantToReserveDancers, id],
      });
      addToFavDancers(id)
    } 
  };

  const onReserve = e => {
    e.preventDefault();
    console.log('btn clicked')
    if (auth.isAuthenticated) {
      if(user.accountType === 'normal'){
        if(weChat === false || weChat === 'false'){

        }else{

        }
        const pleaseBuyVip = document.getElementById('please-buy-vip');
        pleaseBuyVip.classList.remove('display-none');
      }else{
        // if(reserveBox){
          const element = document.getElementById(`wechat-reserve-${dancerID}`);
          element.classList.remove('display-none')
        // }else{
        //   setReserveBox(<WeChatReserve dancerID={dancerID} weChat={weChat} key={dancerID}/>)
        // }
      }
    }else{
      showLogin('login')
    }
  };

  const onNoMosaicMode = e => {
    e.preventDefault();
    if (auth.isAuthenticated) {      
      const pleaseBuyVip = document.getElementById('please-buy-vip');
      pleaseBuyVip.classList.remove('display-none');    
    }else{
      showLogin('login')
    }
  }
  const showNewCommentBox = (id) => {
    // console.log(id);
    const commentBox = document.getElementById(id);
    auth.isAuthenticated ? commentBox.classList.remove('display-none') : showLogin('login');
  };

  const onCreateNewComment = e => {
    e.preventDefault();
    // showNewCommentBox(`dancerItem-${dancerID}-commentBox`);
    setDancer(dancerID);
    showNewCommentBox('homepage-commentBox')
  }

  // const navigate = useNavigate();
  // const goToDancer = (id) => {
  //   // // console.log('redirect function called');
  //   navigate(`/dancer/${dancerID}`);
  // };
  const clothingDB = {
    F: '难操作',
    T: '好操作',
    S: '性感暴露',
  };
  const breastsDB = {
    B: '胸部偏小',
    C: '胸部适中',
    D: '胸部丰满',
    E: '爆乳',
  };

  const agesDB = ["20-","21-25","26-30","31-35","36-40","41-50","50+"]
  const heightDB = ["<=155","156-160","161-165","166-170",">170"]

  function showEditDancer() {
    console.log('showEditDancer clicked');
  }

  //console.log('dancerID :',dancerID,'files.listPhoto:', files.listPhoto, 'photo:',photo);
  return (
    <div
      className={controlpanel ? 'dancer-item cursor-pointer' : 'dancer-item'}
      onClick={(e) => (controlpanel ? showEditDancer() : null)}
    >
      <div className="dancer-item-grid" >
        <Link 
          to={`/dancer/${dancerID}`} 
          target="_blank" 
          rel="noopener noreferrer" className="dancer-item-image"
        >
          <img
            src={`/uploads/photos/${photo}`
              // files.listPhoto
              //   ? `/uploads/photos/${files.listPhoto}`
              //   : `/uploads/photos/${photoName}`
            }
            alt="listPhoto"
          />
        </Link>
        <div className="dancer-item-briefs">
          <div className="grid-four">
            {/* <p>总体评价</p> */}
            <p
              className="dancer-item-first-line first-item"
              key={`${dancerID}-overallRating`}
            >
              {overallRating}
            </p>
            {/* <p>颜值评分</p> */}
            <p
              className="dancer-item-first-line second-item"
              key={`${dancerID}-beautifulness`}
            >
              颜值{beautifulness}
            </p>
            {/* <p>年龄范围</p> */}
            {/* <p className="dancer-item-first-line" key={`${dancerID}-age`}> */}
            <p className='mr-half' key={`${dancerID}-age`}>
              {agesDB[age]}岁
            </p>
            {/* <p>价格每曲</p> */}
            <p
              // className="dancer-item-first-line text-info mr-0 ml-auto"
              className="text-info mr-0 ml-auto"
              key={`${dancerID}-price`}
            >
              {price}卡路里/曲
            </p>
          </div>
          <div className="flex-wrap">
            {/* <p>身高范围</p> */}
            <p className="mr-half" key={`${dancerID}-height`}>
              {heightDB[height - 4]}
            </p>
            {/* <p className='mr-1'>衣着</p> */}
            <p className="mr-half" key={`${dancerID}-clothing`}>
              衣着{clothingDB[clothing]}
            </p>
            {/* <p className='mr-1'>胸部</p> */}
            <p className="mr-half" key={`${dancerID}-breasts`}>
              {breastsDB[breasts]}
            </p>
            <p className="mr-half" key={`${dancerID}-body`}>
              {body}
            </p>
            {/* <p className='mr-1'>龙女</p> */}
            <p key={`${dancerID}-dragonGirl`}>{dragonGirl ? '龙女' : ''}</p>
          </div>
          <div className="flex-wrap">
            <p key={`${dancerID}-showupClub`}>
              {showupClub}
            </p>
            {/* <p className='mr-1'>舞厅2</p>
                    <p className='mr-1'>舞厅3</p> */}
          {!userLoading && (user.accountType === 'super' || user.accountType === 'manager' || user.accountType === 'admin') ? 
            <Fragment>
              <p className="mr-half ml-auto text-white" key={`${dancerID}-dancerID`}>
                {dancerID}
              </p>
              <p className="mr-half ml-0 text-white" key={`${dancerID}-nickname`}>
                "{nickname ? nickname : ''}"
              </p> 
              <p className="mx-0 text-white" key={`${dancerID}-codeName`}>
                {codeName ? `${codeName.split('-')[0]}${codeName.split('-')[1]}${codeName.split('-')[2]}` : ''}
              </p>  
            </Fragment>
            : ""}
          </div>
          {/* <div className="grid-two"> */}
            <p key={`${dancerID}-attitude`}>
              抢手度：<span className="text-white">{!userLoading && user.accountType !== 'normal' && hotness ? hotness : '--付费用户可见--'}</span>
            </p>
            <p key={`${dancerID}-chidu`}>
              尺度：<span className="text-white">{!userLoading && user.accountType !== 'normal' && chidu ? chidu : '--付费用户可见--'}</span>
            </p>
          {/* </div> */}
          {firstComment ? <div className="text-brief">
            {/* 显示评论排序第一的评论的前半句，之后显示‘。。。’，后面显示 ‘---付费用户可见---’ */}
            <p key={`${dancerID}-brief`}>
              最新评论：
              {!userLoading && user.accountType !== 'normal' ? <span className="text-white">
                {/* Content to be determined... */}
                {firstComment.content.length > 20 ? `${firstComment.content.slice(0,20)}......` : firstComment.content}
                ----点击查看完整评论----
              </span> : <span className="text-white">--付费用户可见--</span> }
            </p>
          </div> : ''}
          <div className="mt-auto display-flex">{auth.isAuthenticated ? 
            <button className="btn reserve-btn btn-gold display-flex ml-auto mr-0" onClick={e=>onCreateNewComment(e)}><FontAwesomeIcon icon={faPenToSquare} />写评论</button>
           : ''}
           {auth.isGranted ? <Link to={`/dancer/edit/${dancerID}`} target="_blank" rel="noopener noreferrer" className="btn reserve-btn btn-gold" >
            <FontAwesomeIcon icon={faPenToSquare} />
            编辑
          </Link> : ""}
           
           </div>
          
      {controlpanel ? (
        ''
      ) : (
        <div className="reserve-btns">
          {/* 显示已添加该舞女进入收藏的总人数 */}
          <p className="want-to-reserve" key={`${dancerID}-favoratedTimes`}>
            已有{favoritedTimes ? favoritedTimes : 0}人收藏
          </p>
          {!userLoading && user.wantToReserveDancers.includes(_id) ? (
            <button
              className="btn btn-disabled already-favorated-btn"
              onClick={e=>taggleFavoriteDancers(e, _id)}
            >
              已收藏
            </button>
          ) : (
            <button
              className="btn reserve-btn"
              onClick={(e) => auth.isAuthenticated ? taggleFavoriteDancers(e, _id) : showLogin('login')}
            >
              收藏
            </button>
          )}
          {/* {!userLoading && user.accountType === 'normal' ? ( */}
            <button className={'btn reserve-btn'} onClick={e => onReserve(e)}>
              预约
            </button>
          {/* ) : (
            <button
              className={
                weChat ? 'btn reserve-btn' : 'btn reserve-btn btn-disabled'
              }
              onClick={(e) => onReserve()}
            >
              预约
            </button>
          )} */}
          {/* {auth.isGranted ? <button className="btn reserve-btn btn-gold" onClick={e => showEditDancer(_id)} disabled><FontAwesomeIcon icon={faPenToSquare} />编辑</button> : ""} */}
          
          
          
        </div>
      )}
        </div>
      </div>
      {((!user && userLoading) || (user && user.accountType === 'normal')) ? <button id={`${dancerID}-noMosaicMode-btn`} className="btn btn-pink no-mosaic-btn" onClick={e => onNoMosaicMode(e)}>擦除马赛克</button> : ""}
      {reserveBox}
      {/* {commentBox} */}
    </div>
  );
};

DancersItem.propTypes = {
  auth: PropTypes.object.isRequired,
  dancer: PropTypes.object.isRequired,
  user: PropTypes.object,
  userLoading: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  addToFavDancers: PropTypes.func.isRequired,
  removeFromFavDancers: PropTypes.func.isRequired,
  controlpanel: PropTypes.bool,
  getDancer: PropTypes.func.isRequired,
  setDancer: PropTypes.func.isRequired,
  comments: PropTypes.array
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userLoading: state.user.userLoading,
  auth: state.auth,
  comments: state.comment.comments
});

export default connect(mapStateToProps, { updateUser, addToFavDancers,removeFromFavDancers, getDancer,setDancer })(DancersItem);
