import React from 'react'

const Rules = () => {
    return (
        <div className='homepage-rules text-gold'>
            <p>①禁止未经女舞客授权发布她人资料，2次怀疑立即封号。</p>
            <p>②禁止发布抹黑舞客的恶意不实评论，3次怀疑立即封号。</p>
            <p>③禁止污蔑男女舞客存在有偿陪侍。</p>
            <p>④可用等级5/10/20来描述女舞客的跳舞水平。</p>
        </div>
    )
}

export default Rules;