import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSquarePlus} from '@fortawesome/free-regular-svg-icons';

import NewUserBox from './NewUserBox';
import AgentsList from './AgentsList'

import Spinner from '../layout/Spinner';

import {getAgents} from '../../actions/management';


const AgentTab = ({
    agents,
    agentsLoading,
    getAgents
}) => {

    useEffect(()=>{
        getAgents();
    },[getAgents])

    useEffect(()=>{},[agents])
    
    const onClickNewUser = (e) => {
        const newUserBox = document.getElementById('new-user-box');
        newUserBox.classList.remove('display-none')
    }

  return (
    agentsLoading ? <Spinner /> : <div id="userTab-content" className="tabcontent">
            <button className="btn btn-pink add-btn" id='add-user-btn' onClick={e => onClickNewUser(e)}><FontAwesomeIcon icon={faSquarePlus} />添加宣传员账户</button>
            <NewUserBox id='new-agent-box' accountType="agent"/>
            <table className='users-table'>
                <thead className='users-tablehead'>
                    <tr className="user-item">
                        <th scope='col' className='user-item-table-index'>index</th>
                        <th scope='col' className='user-item-username'>宣传员ID</th> 
                        <th scope='col' className='user-item-discount-code'>折扣码</th> 
                        <th scope='col' className='user-item-balance'>提成总额</th> 
                        <th scope='col' className='user-item-withdrew'>已提现金额</th> 
                        <th scope='col' className='user-item-withdrawable'>可提现金额</th> 
                        <th scope='col' className='user-item-daily-amount'>日单</th> 
                        <th scope='col' className='user-item-weekly-amount'>周单</th> 
                        <th scope='col' className='user-item-actions'>操作</th>         
                    </tr>   
                </thead>
                <tbody>
                    <AgentsList agents={agents}/>
                </tbody>
            </table>
        </div>
  )
}

AgentTab.propTypes = {
    agents: PropTypes.array.isRequired,
    agentsLoading: PropTypes.bool.isRequired,
    getAgents: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    agents: state.management.agents,
    agentsLoading: state.management.agentsLoading
})

export default connect(mapStateToProps, {getAgents})(AgentTab)