import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider'
import Dancers from '../dancer/Dancers';

import { showLogin } from '../../functions/functions';
import { setAlert } from '../../actions/alert';
import { getClubs } from '../../actions/club';
import { filterDancers, getDancerWithNicknameInDancer, showMyFavorite } from '../../actions/dancer';

const Search = ({
    isAuthenticated,
    isGranted, 
    user,
    userLoading,
    dancers,
    dancersUpdated,
    filteredDancers,
    clubs,
    getClubs,
    filterDancers,
    getDancerWithNicknameInDancer,
    showMyFavorite
}) => {

    useEffect(()=>{
        getClubs();
    },[getClubs]);

    useEffect(()=>{},[isGranted, user, userLoading])

    // const [filteredDancers, setFilteredDancers] = useState(dancers)
    const [preset, setPreset] = useState('默认')

    useEffect(()=>{
    //     setFilteredDancers(dancers);
        // console.log.log('dancers count: ', dancers.length, 'filteredDancers count: ', filteredDancers.length)
    },[dancers, filteredDancers])

    const [formData, setFormData] = useState(
        {
            showupClub: [],
            overallRating: [],
            beautifulness: [6,10],
            age: [0,5],
            height: [4,8],
            clothing: ['F','T','S'],
            breasts: ['B','C','D','E'],
            dragonGirl: null,
            weChat: null,
            favorite: null,
            period: 'all',
            body: ['苗条', '丰满', '肉感', '多肉']
        }
    )

    useEffect(()=>{
        // console.log.log("formData: ", formData, 'favorite?:', favorite, 'user?',user);
        // if(user){
        //     filterDancers( user.wantToReserveDancers, formData);
        // }else{
        filterDancers({wantToReserveDancers: user ? user.wantToReserveDancers : null, formData: formData});
        // }
    },[formData, user, dancersUpdated])

    useEffect(()=>{
        console.log('formData changed： ', formData);
    },[formData])

    const {
        // preset, 
        showupClub,
        overallRating,
        beautifulness,
        age,
        height,
        clothing,
        breasts,
        dragonGirl,
        weChat,
        favorite,
        period,
        body
    } = formData;


    const presetsDB = ["默认","颜值女神","极品萝莉","风韵熟女","高挑女神","性感爆乳"];
    const overallRatingDB = ['一次即可','值得尝试','值得常跳','完美舞女'];
    const clothingDB = [
        {key: 'F',value:"难操作"},
        {key: 'T',value:"好操作"},
        {key: 'S',value:"性感暴露"}
    ];
    const breastsDB = [
        {key: 'B',value:"偏小"},
        {key: 'C',value:"适中"},
        {key: 'D',value:"丰满"},
        {key: 'E',value:"爆乳"}
    ];
    const keys ={ 
        clothing:['F','T','S'],
        breasts: ['B','C','D','E']
    }
    
    const periods = [1, 2, 3, 4, 5, 6, 7, 8]
    const periodsDB = ['近半月', '近1月', '近2月','近3月', '近4月', '近5月', '近6月','6个月以上']

    const bodyDB = ['苗条', '丰满', '肉感', '多肉']

    const agesDB = ["不限-20","21-25","26-30","31-35","36-40","41-50","51-不限"]
    const ageMarks = [{value: 0, label: "18-20"},{value: 1, label: "21-25"},{value: 2, label: "26-30"},{value: 3, label: "31-35"},{value: 4, label: "36-40"},{value: 5, label: "41-50"},{value: 6, label: "50+"}]
    // const ageMarks = [{value: "<=20", label: "<=20"},{value: "21-25", label: "21-25"},{value: "26-30", label: "26-30"},{value: "31-35", label: "31-35"},{value: "36-40", label: "36-40"},{value: "41-50", label: "41-50"},{value: "50+", label: "50+"}]
    const heightsDB = ["不限-155","156-160","161-165","166-170","170-不限"]
    const heightMarks = [{value: 4, label: "不限-155"},{value: 5, label: "156-160"},{value: 6, label: "161-165"},{value: 7, label: "166-170"},{value: 8, label: "171-不限"}]

    let sliderStyle;
    window.innerWidth > 1000 ? sliderStyle = { width: 450, color: "#fe8291", margin:"auto 16px"} : sliderStyle={ width: "85%", color: "#fe8291", margin:"auto 16px"}
     

    const preset0 = {
        ...formData,
        preset: "默认", 
        showupClub: [],
        overallRating: [],
        beautifulness: [6,10],
        age: [0,5],
        height: [4,8],
        clothing: ['F','T','S'],
        breasts: ['B','C','D','E'],
        body:['苗条', '丰满', '肉感', '多肉'],
        dragonGirl: null,
        weChat: null,
        favorite: null,
        period: null
    };
    const preset1 = {
        ...preset0,
        // preset: "颜值女神", 
        beautifulness: [9,10]
    };
    const preset2 = {
        ...preset0,
        // preset: "极品萝莉", 
        beautifulness: [8,10],
        age: [0,0]
    };
    const preset3 = {
        ...preset0,
        // preset: "风韵熟女", 
        beautifulness: [8,10],
        age: [4,4]
    };
    const preset4 = {
        ...preset0,
        // preset: "高挑女神",
        overallRating: ['值得常跳','完美舞女'],
        height: [8,8]
    };
    const preset5 = {
        ...preset0,
        // preset: "性感爆乳", 
        clothing: ["S"],
        breasts: ["E"]
    };

    const onBeautfulnessChange = (e, newValue) => {
        e.preventDefault();
        // setBeautfulness(newValue);
        setFormData({
            ...formData, 
            beautifulness: newValue
        })
    }

    const onAgeChange = (e, newValue) => {
        e.preventDefault();
        // setAge(newValue);
        setFormData({
            ...formData, 
            age: newValue
        })
    }    
    const onHeightChange = (e, newValue) => {
        e.preventDefault();
        // setHeight(newValue);
        setFormData({
            ...formData, 
            height: newValue
        })
    }

    const onShowMyFavorite = e => {

    }

    const onSelectChange = e => {
        
        const field = e.target.name.split("-")[0];
        const fieldValue = e.target.value;
        setFormData({
            ...formData,
            [field]: fieldValue
        })

    }

    const onChange = e => {
        // e.preventDefault();
        const field = e.target.id.split("-")[0];
        const fieldValue = e.target.id.split('-')[1];
        const labelText = `${field}-label-${fieldValue}`
        const label = document.getElementById(labelText);
        const labelArray = Array.from(document.getElementsByClassName(`${field}-class`));
        const selectAllLabel = document.getElementById(`${field}-label-0`);
        const selectedAll = selectAllLabel.classList.contains('active');
        const selected = label.classList.contains('active');

        // // console.log.log('field: ', field, '; fieldValue: ',fieldValue, '; labelText: ',labelText, '; selectedAll: ',selectedAll, '; selected: ', selected);
        // // console.log.log('labelArray: ', labelArray);
        
        switch(field){
            case "preset":
                if(preset !== e.target.value){
                    switch(e.target.value){
                        case "默认":
                            setPreset("默认");
                            setFormData(preset0);
                            break;
                        case "颜值女神":
                            setPreset("颜值女神")
                            setFormData(preset1);
                            break;
                        case "极品萝莉":
                            setPreset("极品萝莉")
                            setFormData(preset2);
                            break;
                        case "风韵熟女":
                            setPreset("风韵熟女")
                            setFormData(preset3);
                            break;
                        case "高挑女神":
                            setPreset("高挑女神")
                            setFormData(preset4);
                            break;
                        case "性感爆乳":
                            setPreset("性感爆乳")
                            setFormData(preset5);
                            break;
                        // case "我的收藏":
                        //     setPreset("我的收藏");
                        //     showMyFavorite(user.wantToReserveDancers)    
                    }
                    Array.from(document.getElementsByClassName('overallRating-class')).map(item=>{
                        // // console.log.log('item.innerHTML: ', item.innerHTML, 'overallRating.includes(item.innerHTML): ', overallRating.includes(item.innerHTML))
                        overallRating.includes(item.innerHTML) ? item.classList.add('active') : item.classList.remove('active')
                    })
                }
                label.classList.add('active')
                break;
            case "clothing":
            case "breasts":
                if(fieldValue === "0"){
                    if(!label.classList.contains('active')){
                        // label.classList.add('active')
                        // labelArray.map(item => {
                        //     item.classList.add("active");
                        // })
                        
                        setFormData({
                            ...formData,
                            [field]: keys[field]
                        })
                    }else{
                        // label.classList.remove('active');
                        labelArray.map(item => {
                            item.classList.remove("active");
                        }) 
                        setFormData({
                            ...formData,
                            [field]: []
                        }) 
                    }
                    

                }else{

                    if(selectedAll){;
                        // selectAllLabel.classList.remove('active')
                        // labelArray.map(item => {
                        // formData[field].includes(e.target.value) ? label.classList.remove("active") : label.classList.add('active');
                        // })
                        // // console.log.log(e.target.value);
                        setFormData({
                            ...formData,
                            [field]: formData[field].filter(item=> item !== e.target.value)
                        });

                    }else{

                        if(selected){
                            // label.classList.remove('active');
                            setFormData({
                                ...formData,
                                [field]: formData[field].filter(item => item !==  e.target.value)
                            })
                        }else{
                            // label.classList.add('active');
                            if(!formData[field].includes( e.target.value)){
                                
                                setFormData({
                                    ...formData,
                                    [field]: [...formData[field],  e.target.value]
                                })
                            }
                        }
                    }
                }
                break;
            case 'body':
                if(fieldValue === "0"){
                    if(!label.classList.contains('active')){
                        // label.classList.add('active')
                        // labelArray.map(item => {
                        //     item.classList.add("active");
                        // })
                        
                        setFormData({
                            ...formData,
                            [field]: bodyDB
                        })
                    }else{
                        // label.classList.remove('active');
                        labelArray.map(item => {
                            item.classList.remove("active");
                        }) 
                        setFormData({
                            ...formData,
                            [field]: []
                        }) 
                    }
                    

                }else{

                    if(selectedAll){;
                        // selectAllLabel.classList.remove('active')
                        // labelArray.map(item => {
                        // formData[field].includes(e.target.value) ? label.classList.remove("active") : label.classList.add('active');
                        // })
                        // // console.log.log(e.target.value);
                        setFormData({
                            ...formData,
                            [field]: formData[field].filter(item=> item !== e.target.value)
                        });

                    }else{

                        if(selected){
                            // label.classList.remove('active');
                            setFormData({
                                ...formData,
                                [field]: formData[field].filter(item => item !==  e.target.value)
                            })
                        }else{
                            // label.classList.add('active');
                            if(!formData[field].includes( e.target.value)){
                                
                                setFormData({
                                    ...formData,
                                    [field]: [...formData[field],  e.target.value]
                                })
                            }
                        }
                    }
                }
                break;
            
            default: 
                if(fieldValue === "0"){
                    if(!label.classList.contains('active')){
                        label.classList.add('active')
                        const dataArray = [];
                        labelArray.map(item => {
                            item.classList.add("active");
                            dataArray.push(item.innerHTML);
                        })
                        setFormData({
                            ...formData,
                            [field]: [...dataArray]
                        })
                    }else{
                        label.classList.remove('active');
                        labelArray.map(item => {
                            item.classList.remove("active");
                        }) 
                        setFormData({
                            ...formData,
                            [field]: []
                        }) 
                    }
                    

                }else{

                    if(selectedAll){;
                        selectAllLabel.classList.remove('active')
                        // labelArray.map(item => {
                        formData[field].includes(e.target.value) ? label.classList.remove("active") : label.classList.add('active');
                        // })
                        setFormData({
                            ...formData,
                            [field]: formData[field].filter(item=>item!==e.target.value)
                        });

                    }else{

                        if(selected){
                            label.classList.remove('active');
                            setFormData({
                                ...formData,
                                [field]: formData[field].filter(item => item !==  e.target.value)
                            })
                        }else{
                            label.classList.add('active');
                            if(!formData[field].includes( e.target.value)){
                                
                                setFormData({
                                    ...formData,
                                    [field]: [...formData[field],  e.target.value]
                                })
                            }
                        }
                    }
                }
        }
            
    }

    const onRadioSelect = e => {
        e.preventDefault();
        const field = e.target.id.split("-")[0];
        const fieldValue = e.target.id.split('-')[1];
        const labelText = `${field}-label-${fieldValue}`
        console.log(field, fieldValue);
        if(fieldValue === "0"){
            setFormData({
                ...formData,
                [field]: null
            })
        }else{
            if(field === 'period'){
                setFormData({
                    ...formData,
                    [field]: fieldValue
                })
            }else{
                setFormData({
                    ...formData,
                    [field]: e.target.value==='false'? false : true
                })
            }
        }
    }


    const onDancerNicknameSearch = (e) => {
        e.preventDefault();
        document.getElementById('waiting-spinner').classList.remove('display-none');
        getDancerWithNicknameInDancer(document.getElementById('nickname-input').value);
    }

    const onResetDancerNicknameSearch = e => {
        e.preventDefault();
        document.getElementById('nickname-input').value = '';
        filterDancers({wantToReserveDancers : null, formData: formData});
    }
    
    const navigate = useNavigate();
    const goToAttendance = (id) => {
        const searchText = document.getElementById('nickname-input').value;
        navigate(`/attendance/${searchText}`)
    }

    useEffect(()=>{ console.log('window width changed')},[window.innerWidth])

    let i = clubs.length + 1;

  return (
    <Fragment>
    <form id='dancer-search-form' className='search-form'>
        <div className="search-field">
            <h4 className="form-label">预设组合：</h4>
            
            <div className="form-input-group">
                {presetsDB.map(item => <div className="form-input" key={`preset-${presetsDB.indexOf(item)}`}>
                    <input 
                        type="radio" 
                        name="presetRadio"
                        className='check-box' 
                        id={`preset-${presetsDB.indexOf(item)}`} 
                        onChange={e => onChange(e)} 
                        value={item}
                    />
                    <label 
                        className={preset===item ? "form-input-label preset-class active" : "form-input-label preset-class"}
                        htmlFor={`preset-${presetsDB.indexOf(item)}`} 
                        id={`preset-label-${presetsDB.indexOf(item)}`} 
                    >{item}</label>
                </div>
                )}
                {/* {userLoading ? "" : <div className="form-input" key='preset-myFavoriate' >
                <input 
                    type="radio" 
                    name="presetRadio"
                    className='check-box' 
                    id='preset-myFavoriate' 
                    onChange={e => onChange(e)} 
                    value='我的收藏'
                />
                <label 
                    className={preset==="我的收藏" ? "form-input-label preset-class active" : "form-input-label preset-class"}
                    htmlFor='preset-myFavoriate' 
                    id='preset-label-myFavoriate'
                >我的收藏</label>
                    </div>}                */}
            </div>
        </div>
        <div className="search-field">
            <h4 className="form-label">舞厅：</h4>
            <div className="form-input-group">
                <div className="form-input">
                    <input type="checkbox" className='check-box' id='showupClub-0' onChange={e=>onChange(e)} value="全选"/>
                    <label className='form-input-label-special' htmlFor="showupClub-0" id="showupClub-label-0">全选</label>
                </div>
                {clubs.length > 0 ? clubs.map( item => {
                    i -= 1; 
                    return (
                        <div className="form-input" key={`showupClub-${i}`}>
                            <input type="checkbox" className='check-box' id={`showupClub-${i}`} onChange={e => onChange(e)} value={item.name}/>
                            <label className={showupClub.includes(item) ? 'form-input-label showupClub-class active' : 'form-input-label showupClub-class'} htmlFor={`showupClub-${i}`} id={`showupClub-label-${i}`}>{item.name}</label>
                        </div>
                    )
                }): ""}
            </div>
            </div>
        <div className="search-field">
            <h4 className="form-label">总评：</h4>
            <div className="form-input-group">
                
                <div className="form-input">
                    <input type="checkbox" className='check-box' id='overallRating-0' onChange={e => onChange(e)}></input>
                    <label className='form-input-label-special' htmlFor="overallRating-0" id='overallRating-label-0'>全选</label>
                </div>
                {
                    overallRatingDB.map(item =>  
                        <div className="form-input" key={`overallRating-${overallRatingDB.indexOf(item)}`}>
                            <input type="checkbox" className='check-box' id={`overallRating-${overallRatingDB.indexOf(item)+1}`} onChange={e => onChange(e)} value={item}/>
                            <label className={overallRating.includes(item) ? 'form-input-label overallRating-class active' : 'form-input-label overallRating-class'} htmlFor={`overallRating-${overallRatingDB.indexOf(item)+1}`} id={`overallRating-label-${overallRatingDB.indexOf(item)+1}`} >{item}</label>
                        </div>
                    )
                }
            </div>
        </div>
        <div className="search-field">
            <h4 className="form-label">颜值：</h4>
            
            <Box sx={{display: "flex"}}>
                <span className="slide-limit-label" id="beautifulness-lower-label">{beautifulness[0]}</span>
                <Slider
                        aria-labelledby="beautifulness-slider"
                        name="beautifulness-slider"
                        // getAriaLabel={() => 'Temperature range'}
                        min={5}
                        max={10}
                        value={beautifulness}
                        onChange={onBeautfulnessChange}
                        valueLabelDisplay="off"
                        // getAriaValueText={valuetext}
                        sx={sliderStyle}
                />
                <span className="slide-limit-label" id="beautifulness-upper-label">{beautifulness[1]}</span>
            </Box>
        </div>
        <div className="search-field">
            <h4 className="form-label">年龄：</h4>            
            <Box sx={{display: "flex"}}>
                <span className="slide-limit-label" id="age-lower-label">{agesDB[age[0]].split('-')[0]}</span>
                <Slider
                    aria-labelledby="age-slider"
                    name="age-slider"
                    // getAriaLabel={() => 'Temperature range'}
                    min={0}
                    max={6}
                    value={age}
                    onChange={onAgeChange}
                    valueLabelDisplay="off"
                    step={null}
                    // getAriaValueText={agesDB}
                    marks={ageMarks}
                    sx={sliderStyle}
                />
                <span className="slide-limit-label" id="age-upper-label">{agesDB[age[1]].split('-')[1]}</span>
            </Box>
        </div>
        <div className="search-field">
            <h4 className="form-label">身高：</h4>
            <Box sx={{display: "flex"}}>
                <span className="slide-limit-label" id="height-lower-label">
                    {/* {heightsDB[(height[0] - 4)].split('-')[0]} */}
                    {(heightsDB[height[0] - 4]).split('-')[0]}
                </span>
                <Slider
                    aria-labelledby="height-slider"
                    name="height-slider"
                    // getAriaLabel={() => 'Temperature range'}
                    min={4}
                    max={8}
                    value={height}
                    onChange={onHeightChange}
                    valueLabelDisplay="off"
                    step={null}
                    // getAriaValueText={valuetext}
                    marks={heightMarks}
                    sx={sliderStyle}
                />
                <span className="slide-limit-label" id="height-upper-label">
                    {/* {heightsDB[(height[1] - 4)].split('-')[1]} */}
                    {(heightsDB[height[1] - 4]).split('-')[1]}
                </span>
            </Box>
        </div>
        <div className="search-field">
            <h4 className="form-label">衣着：</h4>
            <div className="form-input-group">
                
                <div className="form-input">
                    <input type="checkbox" className='check-box' id='clothing-0' value='全选' onChange={e=>onChange(e)}/>
                    <label className={`form-input-label-special ${clothing.length === clothingDB.length ? 'active' : ''}`} htmlFor="clothing-0" id="clothing-label-0">全选</label>
                </div>
                {
                    clothingDB.map(item =>  
                        <div className="form-input" key={`overallRating-${clothingDB.indexOf(item)}`}>
                            <input type="checkbox" className='check-box' id={`clothing-${clothingDB.indexOf(item)+1}`} onChange={e => onChange(e)}  value={item.key}/>
                            <label className={clothing.includes(item.key) ? 'form-input-label clothing-class active' : 'form-input-label clothing-class'} htmlFor={`clothing-${clothingDB.indexOf(item)+1}`} id={`clothing-label-${clothingDB.indexOf(item)+1}`} >{item.value}</label>
                        </div>
                    )
                }
            </div>
        </div>
        <div className="search-field">
            <h4 className="form-label">胸部：</h4>
            <div className="form-input-group">
                
                <div className="form-input">
                    <input type="checkbox" className='check-box' id='breasts-0' value='全选' onChange={e=>onChange(e)}/>
                    <label className={`form-input-label-special ${breasts.length === breastsDB.length ? 'active' : ''}`} htmlFor="breasts-0" id="breasts-label-0">全选</label>
                </div>
                {
                    breastsDB.map(item =>  
                        <div className="form-input" key={`breasts-${breastsDB.indexOf(item)}`}>
                            <input type="checkbox" className='check-box' id={`breasts-${breastsDB.indexOf(item)+1}`} onChange={e => onChange(e)}  value={item.key}/>
                            <label className={breasts.includes(item.key) ? 'form-input-label breasts-class active' : 'form-input-label breasts-class'} htmlFor={`breasts-${breastsDB.indexOf(item)+1}`} id={`breasts-label-${breastsDB.indexOf(item)+1}`} >{item.value}</label>
                        </div>
                    )
                }
            </div>
        </div>
        <div className="search-field">
            <h4 className="form-label">体态：</h4>
            <div className="form-input-group">
                <div className="form-input">
                    <input type="checkbox" className='check-box' id='body-0' value='全选' onChange={e=>onChange(e)}/>
                    <label className={`form-input-label-special ${body.length === bodyDB.length ? 'active' : ''}`} htmlFor="body-0" id="body-label-0">全选</label>
                </div>
                {
                    bodyDB.map(item =>  
                        <div className="form-input" key={`body-${bodyDB.indexOf(item)}`}>
                            <input type="checkbox" className='check-box' id={`body-${bodyDB.indexOf(item)+1}`} onChange={e => onChange(e)}  value={item}/>
                            <label className={body.includes(item) ? 'form-input-label body-class active' : 'form-input-label body-class'} htmlFor={`body-${bodyDB.indexOf(item)+1}`} id={`body-label-${bodyDB.indexOf(item)+1}`} >{item}</label>
                        </div>
                    )
                }
                    
                </div>
            </div>
        <div className="search-field">
            <h4 className="form-label">龙女：</h4>
            <div className="form-input-group">
                
                <div className="form-input">
                    <input type="radio" name='dragonGirl-radio' className='check-box' id='dragonGirl-0' defaultChecked onChange={e=>onRadioSelect(e)}/>
                    <label className={dragonGirl === null ?  'form-input-label-special active' : 'form-input-label-special'} htmlFor="dragonGirl-0" id="dragonGirl-label-0" >不限</label>
                </div>
                
                <div className="form-input">
                    <input type="radio" name='dragonGirl-radio' className='check-box' id='dragonGirl-1' value='false' onChange={e=>onRadioSelect(e)} />
                    <label className={dragonGirl === false ?  'form-input-label dragonGirl-class active' : 'form-input-label dragonGirl-class'} htmlFor="dragonGirl-1" id="dragonGirl-label-1" >正常女</label>
                </div>
                
                <div className="form-input">
                    <input type="radio" name='dragonGirl-radio' className='check-box' id='dragonGirl-2' value='true' onChange={e=>onRadioSelect(e)} />
                    <label className={dragonGirl === true ?  'form-input-label dragonGirl-class active' : 'form-input-label dragonGirl-class'} htmlFor="dragonGirl-2" id="dragonGirl-label-2" >龙女</label>
                </div>
            </div>
        </div>
        <div className="search-field">
            <h4 className="form-label">时效：</h4>
            <div className="form-input-group">
                <select name="period-select" id="period-select" value={period} onChange={e=>onSelectChange(e)}>
                    <option value='all' key='search-period-all'>不限</option>
                    {periods.map(item => <option value={item} key={`search-period-${periods.indexOf(item)}`}>{periodsDB[periods.indexOf(item)]}</option>)}
                </select>
                
                
            </div>
        </div>
        {/*
        <div className="search-field">
            <h4 className="form-label">时效：</h4>
            <div className="form-input-group">
                
                <div className="form-input">
                    <input type="radio" name='period-radio' className='check-box' id='period-0' defaultChecked onChange={e=>onRadioSelect(e)}/>
                    <label className={period === null ?  'form-input-label-special active' : 'form-input-label-special'} htmlFor="period-0" id="period-label-0" >不限</label>
                </div>
                
                <div className="form-input">
                    <input type="radio" name='period-radio' className='check-box' id='period-1' value='1' onChange={e=>onRadioSelect(e)} />
                    <label className={period === '1' ?  'form-input-label period-class active' : 'form-input-label period-class'} htmlFor="period-1" id="period-label-1" >近2周</label>
                </div>
                
                <div className="form-input">
                    <input type="radio" name='period-radio' className='check-box' id='period-2' value='2' onChange={e=>onRadioSelect(e)} />
                    <label className={period === '2' ?  'form-input-label period-class active' : 'form-input-label period-class'} htmlFor="period-2" id="period-label-2" >近1月</label>
                </div>

                <div className="form-input">
                    <input type="radio" name='period-radio' className='check-box' id='period-3' value='3' onChange={e=>onRadioSelect(e)} />
                    <label className={period === '3' ?  'form-input-label period-class active' : 'form-input-label period-class'} htmlFor="period-3" id="period-label-3" >近2月</label>
                </div>
            </div>
        </div>
        */}
        <div className="search-field">
            <h4 className="form-label">预约：</h4>
            <div className="form-input-group">
                
                <div className="form-input">
                    <input type="radio" name='weChat-radio' className='check-box' id='weChat-0' defaultChecked onChange={e=>onRadioSelect(e)}/>
                    <label className={weChat === null ?  'form-input-label-special active' : 'form-input-label-special'} htmlFor="weChat-0" id="weChat-label-0">不限</label>
                </div>
                
                <div className="form-input">
                    <input type="radio" name='weChat-radio' className='check-box' id='weChat-1' value={true} onChange={e=>onRadioSelect(e)} />
                    <label className={weChat === true || weChat === 'true' ?  'form-input-label weChat-class active' : 'form-input-label weChat-class'} htmlFor="weChat-1"  id="weChat-label-1">在线可约</label>
                </div>
                <div className="form-input">
                    <input type="radio" name='weChat-radio' className='check-box' id='weChat-2' value={false} onChange={e=>onRadioSelect(e)} />
                    <label className={weChat === false || weChat === 'false' ?  'form-input-label weChat-class active' : 'form-input-label weChat-class'}  htmlFor="weChat-2" id="weChat-label-2">暂无预约</label>
                </div>
                
            </div>
            
        </div>
        <div className="search-field">
            <h4 className="form-label">收藏：</h4>
            <div className="form-input-group">
                
                <div className="form-input">
                    <input type="radio" name='favorite-radio' className='check-box' id='favorite-0' defaultChecked onChange={e=>onRadioSelect(e)}/>
                    <label className={favorite === null ?  'form-input-label-special active' : 'form-input-label-special'} htmlFor="favorite-0" id="favorite-label-0">不限</label>
                </div>
                
                <div className="form-input">
                    <input type="radio" name='favorite-radio' className='check-box' id='favorite-1' value={true} onChange={e=>isAuthenticated ? onRadioSelect(e) : showLogin() } />
                    <label className={favorite === true || favorite === 'true' ?  'form-input-label favorite-class active' : 'form-input-label favorite-class'} htmlFor="favorite-1"  id="favorite-label-1">已收藏</label>
                </div>
                <div className="form-input">
                    <input type="radio" name='favorite-radio' className='check-box' id='favorite-2' value={false} onChange={e=>isAuthenticated ? onRadioSelect(e) : showLogin()} />
                    <label className={favorite === false || favorite === 'false' ?  'form-input-label favorite-class active' : 'form-input-label favorite-class'}  htmlFor="favorite-2" id="favorite-label-2">未收藏</label>
                </div>
                
            </div>
            
        </div>
        {isGranted && !userLoading ? <div className='form-input display-flex'id='search-dancer-with-nickname-form'>
            {/* <button className='btn btn-pink add-btn mx-1' onClick={e => goToAttendance()}>签到</button>
            <input className='mr-1 ml-auto' id='nickname-input' name="nickname-input" type="text" placeholder='请输入舞女内部代号'/>
            <button className="btn btn-pink add-btn mx-1" onClick={e=>onDancerNicknameSearch(e)}>查找</button>
            <button className="btn btn-pink add-btn mx-1" onClick={e=>onResetDancerNicknameSearch(e)}>重置</button> */}
            <button className='btn btn-pink admin-search-btn' onClick={e => goToAttendance('nickname-input')}>签到</button>
            <input className='ml-auto' id='nickname-input' name="nickname-input" type="text" placeholder='请输入舞女内部代号/昵称/ID'/>
            <button className="btn btn-pink admin-search-btn" onClick={e=>onDancerNicknameSearch(e)}>查找</button>
            <button className="btn btn-pink admin-search-btn" onClick={e=>onResetDancerNicknameSearch(e)}>重置</button>
        </div> : ""}
    </form>
    </Fragment>
  )
}

Search.propTypes = {
    isGranted: PropTypes.bool.isRequired,
    user: PropTypes.object,
    userLoading: PropTypes.bool.isRequired,
    clubs: PropTypes.array.isRequired,
    dancers: PropTypes.array.isRequired,
    filteredDancers: PropTypes.array.isRequired,
    filterDancers: PropTypes.func.isRequired,
    getDancerWithNicknameInDancer: PropTypes.func.isRequired,
    showMyFavorite: PropTypes.func.isRequired,
    dancersUpdated: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isGranted: state.auth.isGranted,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.user.user,
    userLoading: state.user.userLoading,
    dancers: state.dancer.dancers,
    filteredDancers: state.dancer.filteredDancers,
    clubs: state.club.clubs,
    dancersUpdated: state.dancer.dancersUpdated,
})

export default connect(mapStateToProps, {getClubs, filterDancers, getDancerWithNicknameInDancer, showMyFavorite})(Search)