import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSquarePlus} from '@fortawesome/free-regular-svg-icons';

import {getDancers, getDancerWithNickname} from '../../actions/dancer';
import {getComments} from '../../actions/comment';
import { getDancersCount, getReserves, getUsers, getUsersCount, getTransactions, getTotalIncome } from '../../actions/management';

import CommentsList from './CommentsList';
import Spinner from '../layout/Spinner';
import DataTab from './DataTab';
import UserTab from './UserTab';
import DancerTab from './DancerTab';
import AgentWithdrawTab from './AgentWithdrawTab';
import UserTransactionTab from './UserTransactionTab';
import CommentsTab from './CommentsTab';
import AgentTab from './AgentTab';
import { setAlert } from '../../actions/alert';


const ControlPanel = ({
    getUsers,
    getDancers,
    getComments,
    getReserves, 
    getDancersCount, 
    getUsersCount,
    getTotalIncome,
    getTransactions,
    isGranted,
    dancer: {
        dancers,
        dancerLoading,
        dancersLoading,
    },
    comment: {
        comments,
        commentsLoading
    },
    management: {
        users,
        usersLoading,
        editDancerLoading,
        reserveLoading,
        reservesLoading,
        errors
    },
    setAlert
}) => {
    window.scrollTo(0,0)
    // useEffect(()=>{},[dancer, dancers, users, comments, reserve,reserves, editDancer])

    const navigate = new useNavigate();
    if(!isGranted) {
        // console.log("Not granted");
        navigate('/');
    }
    

    useEffect(()=>{
        getDancersCount();
        getUsersCount();
        getTotalIncome();
        getDancers();
        getComments();
        // getReserves();
        getUsers();
        getTransactions();
    },[getDancers, getComments, getReserves, getDancersCount, getUsersCount, getUsers, getTransactions,getTotalIncome])

    useEffect(()=>{
        if(errors.length > 0){
            errors.map(err => setAlert(err.data.msg, 'danger'))
        }
    },[errors])

    useEffect(()=>{},[usersLoading,dancerLoading,dancersLoading,commentsLoading,reserveLoading,reservesLoading,editDancerLoading,dancers, users, comments])

    const [currentTab, setCurrentTab ] = useState({id: 'dataTab', component: <DataTab/>})


    const onSwitchTabs = (e, tab) => {
        // console.log(tab, `${tab}-content`)

        // console.log(currentTab.id)

        switch(tab){
            case 'dataTab':                
                setCurrentTab({id: 'dataTab', component: <DataTab/>});
                break;
            case 'userTab':                
                setCurrentTab({id: 'userTab', component: <UserTab/>});
                break;
            case 'dancerTab':                
                setCurrentTab({id: 'dancerTab', component: <DancerTab/>});
                break;
            case 'commentsTab':                
                setCurrentTab({id: 'commentsTab', component: <CommentsTab />});
                break;
            case 'userWithdrawTab':                
                setCurrentTab({id: 'userWithdrawTab', component: <UserTransactionTab/>});
                break;
            case 'reservesTab':                
                setCurrentTab({id: 'reservesTab', component: reservesTab});
                break;
            case 'agentTab':                
                setCurrentTab({id: 'agentTab', component: <AgentTab/>});
                break;
            case 'adminWithdrawTab':                
                setCurrentTab({id: 'adminWithdrawTab', component: <AgentWithdrawTab/>});
                break;
            default:
                return;
        }

    }


    const userWithdrawTab = (
        <div id="userWithdrawTab-content" className="tabcontent">
            <table className='control-panel-table'>
                <thead className='control-panel-tablehead'>
                    <tr className="control-panel-table-item">
                        <th scope='col' className='userWithdrawTab-item-table-index'>index</th>
                        <th scope='col' className='userWithdrawTab-item-username'>用户名</th> 
                        <th scope='col' className='userWithdrawTab-item-username'>微信号</th> 
                        <th scope='col' className='userWithdrawTab-item-email'>提现金额</th>
                        <th scope='col' className='userWithdrawTab-item-actions'>操作</th>         
                    </tr>   
                </thead>
                <tbody>
                    {/* {comments ? <CommentsList comments={comments} /> : ""} */}
                </tbody>
            </table>
        </div>
    );

    const reservesTab = (
        <div id="reservesTab-content" className="tabcontent">
            <table className='control-panel-table'>
                <thead className='control-panel-tablehead'>
                    <tr className="control-panel-table-item">
                        <th scope='col' className='reservesTab-item-table-index'>index</th>
                        <th scope='col' className='reservesTab-item-username'>用户名</th> 
                        <th scope='col' className='reservesTab-item-username'>微信号</th> 
                        <th scope='col' className='reservesTab-item-email'>舞女微信</th>
                        <th scope='col' className='reservesTab-item-email'>微信门票</th>
                        <th scope='col' className='reservesTab-item-actions'>操作</th>         
                    </tr>   
                </thead>
                <tbody>
                    {/* {comments ? <CommentsList comments={comments} /> : ""} */}
                </tbody>
            </table>
        </div>
    );

    // const adminWithdrawTab = (
    //     <div id="adminWithdrawTab-content" className="tabcontent">
    //         <table className='control-panel-table'>
    //             <thead className='control-panel-tablehead'>
    //                 <tr className="control-panel-table-item">
    //                     <th scope='col' className='adminWithdraw-item-table-index'>index</th>
    //                     <th scope='col' className='adminWithdraw-item-username'>用户名</th> 
    //                     <th scope='col' className='adminWithdraw-item-username'>微信号</th> 
    //                     <th scope='col' className='adminWithdraw-item-email'>今天以来未提现金额</th>
    //                     <th scope='col' className='adminWithdraw-item-actions'>操作</th>         
    //                 </tr>   
    //             </thead>
    //             <tbody>
    //                 {/* {comments ? <CommentsList comments={comments} /> : ""} */}
    //             </tbody>
    //         </table>
    //     </div>
    // );
    
    useEffect(()=>{},[usersLoading,dancerLoading,dancersLoading,commentsLoading,reserveLoading,reservesLoading,editDancerLoading])

    return (
        <div className='container'>
            <h1 className='text-align-center text-pink'>管理员控制面板</h1>
            {/* <!-- Tab links --> */}
            <div className="tab" id='contorl-panel-tabs'>
                <button className={currentTab.id === "dataTab" ? "tablinks ml-auto selected" : 'tablinks ml-auto'} id='dataTab' onClick={e =>onSwitchTabs(e,'dataTab')}>数据概览</button>
                <span className='tablinks-divider'></span>
                <button className={currentTab.id === "userTab" ? "tablinks selected" : 'tablinks'} id='userTab' onClick={e =>onSwitchTabs(e,'userTab')}>账户管理</button>
                <span className='tablinks-divider'></span>
                <button className={currentTab.id === "dancerTab" ? "tablinks selected" : 'tablinks'} id='dancerTab' onClick={e =>onSwitchTabs(e,'dancerTab')}>舞女管理</button>
                <span className='tablinks-divider'></span>
                <button className={currentTab.id === "commentsTab" ? "tablinks selected" : 'tablinks'} id='commentsTab' onClick={e =>onSwitchTabs(e,'commentsTab')}>评论管理</button>
                <span className='tablinks-divider'></span>
                <button className={currentTab.id === "userWithdrawTab" ? "tablinks selected" : 'tablinks'} id='userWithdrawTab' onClick={e =>onSwitchTabs(e,'userWithdrawTab')}>用户交易管理</button>
                <span className='tablinks-divider'></span>
                <button className={currentTab.id === "reservesTab" ? "tablinks selected" : 'tablinks'} id='reservesTab' onClick={e =>onSwitchTabs(e,'reservesTab')}>预约</button>
                <span className='tablinks-divider'></span>
                
                <button className={currentTab.id === "agentTab" ? "tablinks mr-auto selected" : 'tablinks mr-auto'} id='agentTab' onClick={e =>onSwitchTabs(e,'agentTab')}>宣传员管理</button>
                {/* <span className='tablinks-divider'></span>
                <button className={currentTab.id === "adminWithdrawTab" ? "tablinks mr-auto selected" : 'tablinks mr-auto'} id='adminWithdrawTab' onClick={e =>onSwitchTabs(e,'adminWithdrawTab')}>宣传员提现</button> */}
                {/* <button className={currentTab.id === "othersTab-content" ? "tablinks mr-auto selected" : 'tablinks mr-auto'} id='othersTab' onClick={e =>onSwitchTabs(e,'othersTab')}>其他管理</button>
                <span className='tablinks-divider'></span> */}
            </div>
            <div className="tabs">
                {/* {[currentTab.component]} */}
                {currentTab.component}
            </div>
        </div>
    )
    // }
}

ControlPanel.propTypes = {
    isGranted: PropTypes.bool.isRequired,
    dancer: PropTypes.object.isRequired,
    comment: PropTypes.object.isRequired,
    management: PropTypes.object.isRequired,
    getUsers: PropTypes.func.isRequired,
    getDancers: PropTypes.func.isRequired,
    getComments: PropTypes.func.isRequired,
    getReserves: PropTypes.func.isRequired,
    getDancersCount: PropTypes.func.isRequired,
    getUsersCount: PropTypes.func.isRequired,
    getTransactions: PropTypes.func.isRequired,
    getTotalIncome: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isGranted: state.auth.isGranted,
    dancer: state.dancer,
    comment: state.comment,
    management: state.management
})

export default connect(mapStateToProps, {getDancers, getComments, getReserves, getDancersCount, getUsersCount,getUsers, getTransactions,getTotalIncome, setAlert})(ControlPanel)