import { combineReducers} from 'redux';
import alert from './alert';
import dancer from './dancer';
import auth from './auth';
import club from './club';
import transaction from './transaction';
import user from './user'
import management from './management'
import comment from './comment';
import blog from './blog';
import agent from './agent';
import discountCode from './discountCode';

export default combineReducers({
    alert, 
    auth, 
    dancer,
    club,
    transaction,
    user,
    comment,
    management,
    blog,
    agent,
    discountCode
});