import {
    GET_COMMENT,
    GET_COMMENTS,
    COMMENT_ERROR,
    COMMENTS_ERROR,
    NEW_COMMENT,
    APPROVE_COMMENT,
    DELETE_COMMENT
} from '../actions/types';

const initialState = {
    comment: null,
    comments:[],
    commentLoading: true,
    commentsLoading: true,
    error: null
  };

  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
        case NEW_COMMENT:
            return {
                ...state,
                comments: [...state.comments, payload],
                commentsLoading: false
            }
        case APPROVE_COMMENT:
            console.log(payload)
            return {
                ...state,
                comments: [payload, ...state.comments.filter( item => item._id !== payload._id)],
                commentsLoading: false
            }
        case GET_COMMENT:
            return {
                ...state,
                comment: payload,
                commentLoading: false
            }
        case GET_COMMENTS:
            return {
                ...state,
                comments: payload.sort((a,b) => new Date(b.date) - new Date(a.date)),
                commentsLoading: false
            }
        case COMMENT_ERROR:
            return {
                ...state,
                error: payload,
                commentLoading: true
            }
        case COMMENTS_ERROR:
            return {
                ...state,
                error: payload,
                commentsLoading: true
            }
        case DELETE_COMMENT:
            console.log(payload);
            return {
                ...state, 
                comments: [...state.comments.filter( item => item._id !== payload)],
                commentsLoading: false
            }
        default:
            return state;
    }
  }
  