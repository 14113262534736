import { GET_CLUBS, CLUBS_ERROR, DELETE_CLUB, NEW_CLUB } from "../actions/types";

const initialState = {
  clubs:[],
  clubsLoading: true,
  error: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLUBS:
      return {
        ...state,
        clubs: payload.sort((a,b)=>a.displayOrder - b.displayOrder),
        // clubs:  [
        //     {_id: "club-1", name: "天涯"},
        //     {_id: "club-2", name: "红虹"},
        //     {_id: "club-3", name: "群众"},
        //     {_id: "club-4", name: "金卡罗"},
        //     {_id: "club-5", name: "情浓"},
        //     {_id: "club-6", name: "半生缘"},
        //     {_id: "club-7", name: "龙鑫娅"},
        //     {_id: "club-8", name: "兰馨"},
        //     {_id: "club-9", name: "爵尔顿"},
        //     {_id: "club-10", name: "梦幻世界"},
        //     {_id: "club-11", name: "心芳情"},
        //     {_id: "club-19", name: "新恋曲"},
        //     {_id: "club-20", name: "小百灵"},
        //     {_id: "club-21", name: "星星"},
        //     {_id: "club-22", name: "欢聚"},
        //     {_id: "club-23", name: "美乐迪"},
        //     {_id: "club-24", name: "蓝波湾"},
        //     {_id: "club-25", name: "爱悦"},
        //     {_id: "club-26", name: "新盈汇"},
        //     {_id: "club-27", name: "枫亚之夜"},
        //     {_id: "club-28", name: "新启点"}
        //   ],
        clubsLoading: false
      };
    case NEW_CLUB:
      return {
        ...state,
        clubs: [...state.clubs, payload],
        clubsLoading: false
      }
    case CLUBS_ERROR:
      return {
        ...state,
        clubsLoading: false,
        error: payload
      };

    case DELETE_CLUB: 
      return {
        ...state, 
        clubs: state.clubs.filter( club => club._id !== payload.deletedID)
      }

    default:
      return state;
  }
}
