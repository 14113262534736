import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_BLOGS,
  GET_BLOGS_COUNT,
  GET_BLOG,
  BLOGS_ERROR,
  BLOG_ERROR,
  CREATE_BLOG,
  DELETE_BLOG,
  SET_CATEGORY,
  SET_BLOG_TOP,
  CLEAR_BLOGS
} from './types';

export const getBlogCounts = (category) => async (dispatch) => {
    console.log("getBlogsCounts called in action");
    try {
      const res = await axios.get(`/api/blogs/count/${category}`);
      // const res = await axios.get(`http://localhost:5000/api/blogs/count/${category}`);
      console.log(res.data);
      dispatch({
        type: GET_BLOGS_COUNT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: BLOGS_ERROR,
        payload: err.response,
      });
    }
  };

  export const clearBlogs = () => async (dispatch) => {
    dispatch({
      type: CLEAR_BLOGS,
      payload: null
    })
  }
  
  export const getBlogs = (category, pageNumber) => async (dispatch) => {
    console.log("getBlogs called in action; category: ", category, '; pageNumber: ', pageNumber);
    try {
      const res = await axios.get(`/api/blogs/${category}/${pageNumber}`);
      // const res = await axios.get(`http://localhost:5000/api/blogs/${category}/${pageNumber}`);
      console.log(res);
      dispatch({
        type: GET_BLOGS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: BLOGS_ERROR,
        payload: err.response,
      });
    }
  };

  export const getBlog = id => async (dispatch) => {
    console.log('getBlog called in action');
    try{
      const res = await axios.get(`/api/blogs/${id}`);
      // const res = await axios.get(`http://localhost:5000/api/blogs/${id}`);
      dispatch({
        type: GET_BLOG,
        payload: res.data
      })
    }catch(err){
      dispatch({
        type: BLOG_ERROR,
        payload: err.response
      })
    }
  }

  export const createBlog = (formData, edit = false) => async (dispatch) => {
    try {
      // console.log("createBlog called in action");
      // console.log("formData got in createBlog: ", formData);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      
      const res = await axios.post("/api/blogs", formData, config);
      // const res = await axios.post("http://localhost:5000/api/blogs", formData, config);

      const {
        _id,
        title,
        author,
        date,
        category,
        coverImg,
        content,
      } = formData;
      if (!category || !title || !author || !content || !coverImg) {
        dispatch(setAlert("必填项目不能为空，请仔细检查！", "danger"));
      } else {
        dispatch(setAlert("文章已发布", "success"));
      }
      window.location.href = `/blog/${res.data._id}`;
      dispatch({
        type: CREATE_BLOG,
        payload: res.data,
      });
  
    } catch (err) {
      // console.log(err);
      dispatch({
        type: BLOG_ERROR,
        payload: err.response,
      });
    }
  };

  // upload photo
export const uploadImage = (photo) => async (dispatch) => {
  console.log("uploadImage called in action")
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    document.getElementById('waiting-spinner').classList.remove('display-none');
    const photoRes = await axios.post(
      '/api/blogs/upload-image',
      photo,
      config
    );
    document.getElementById('waiting-spinner').classList.add('display-none')
    console.log(photoRes)

  } catch (err) {
    console.log(err)
    dispatch({
      type: BLOG_ERROR,
      payload: { msg: 'File upload failed. 文件上传失败', status: '500' },
    });
    document.getElementById('waiting-spinner').classList.add('display-none');
  }
};

  export const deleteBlog = (id) => async (dispatch) => {
    console.log("deleteBlog called in action");
    try {
      await axios.delete(`/api/blogs/${id}`);
      // await axios.delete(`http://localhost:5000/api/blogs/${id}`);
      dispatch({
        type: DELETE_BLOG,
        payload: id,
      });
      dispatch(setAlert("成功删除文章", "success"));
    } catch (err) {
      dispatch({
        type: BLOG_ERROR,
        payload: err.response,
      });
    }
  };

  export const setCategory = category => async dispatch => {
    try {
        dispatch({
            type: SET_CATEGORY,
            payload: category
        });
    }catch(err){
        dispatch({
            type: BLOGS_ERROR,
            payload: '服务器错误'
        })
    }

  }

  export const setCurrentBlogTop = id => async dispatch => {
    try {
      const res = await axios.post(`/api/blogs/top/${id}`)
      dispatch({
        type: SET_BLOG_TOP,
        payload: res.data
      })
    }catch(err){
        dispatch({
            type: BLOGS_ERROR,
            payload: '服务器错误'
        })
    }
  }