import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import Moment from "react-moment";
import moment from 'moment';
import { connect } from 'react-redux'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faBan } from '@fortawesome/free-solid-svg-icons'
import { faXmarkCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';

// Functions
import { deleteComment, approveComment } from '../../actions/comment';

import { useEffect } from 'react';

const CommentsList = ({
    user,
    comments,
    dancers, 
    users,
    deleteComment, 
    approveComment,
    approved
}) => {

    // const [commentToEdit, setCommentToEdit ] = useState()

    const [ formData, setFormData ] = useState({
        id: '',
        score: 0,
    })
    useEffect(()=>{
        // console.log(formData);
    }, [formData])

    // const elementArray = [];
    useEffect(()=>{},[comments,dancers,users,user])



    const onDelete = (e,id) => {
        console.log('delete btn clicked')
        deleteComment(id);
    }


    const onEdit = (e, id) => {
        setFormData({...formData, id: id });
    }

    const onCancelEdit = () => {
        setFormData({id:'', score: 0});
    }

    const onScoreChange = (e, id)=> {
        setFormData({id: id, score: parseInt(e.target.value)})
    }

    const onApprove = (e) => {

        console.log('formData got in onApprove: ', formData);
        approveComment(formData);
        setTimeout(()=>{onCancelEdit()}, 500);
    }

    const accountTypeDB = {
        manager: 'VVIP/总管理员',
        admin:'VVIP/管理员',
        agent:'VVIP/宣传员',
        normal: '普通会员',
        vip:'VIP',
    }

    // for(let i = 0; i<comments.length; i++){ 
    //     elementArray.unshift(
            
    //     )
    // }
    let i = 0;
    // return elementArray;
    return  comments.map(comment => {
        const user = users.find(user=>user._id === comment.authorID);
        const dancer = dancers.find(item => item.dancerID === comment.dancerID);
        // console.log(comment, dancer)
        return (
            dancer ? <tr className="comment-item" key={comment._id}>
                <td scope='row' className='comment-item-table-index'>{ i += 1 }</td>                        
                <td className='comment-item-dancerID'>
                    <Link
                        to={`/dancer/${dancer.dancerID}`} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-decoration-none text-gold" 
                    >
                        {dancer.nickname}
                    </Link>
                </td>
                <td className='comment-item-accountType'>{user ? accountTypeDB[user.accountType] : "loading..."}</td>
                <td className='comment-item-overallRating'>{comment.overallRating}</td>
                <td className='comment-item-content'>{comment.content}</td>
                <td className='comment-item-approved'>{comment.approved ? <FontAwesomeIcon icon={faCircleCheck} className='text-success'/> : <FontAwesomeIcon icon={faXmarkCircle} className='text-danger'/>}</td>
                <td className="comment-item-score">
                    { formData.id !== comment._id ? comment.score : 
                        <Fragment>
                            <select name={`comment-item-score-select@${comment._id}`} id={`comment-item-score-select@${comment._id}`} className="comment-item-score-select" defaultValue={comment.score ? comment.score : 0} onChange={e=> onScoreChange(e, comment._id)}>
                                <option value={0}>不予通过</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                            {comment.approved ? <button className="btn btn-submit ml-1" onClick={e=>onApprove(e)}>更新</button> : ""}
                        </Fragment>
                    }
                </td>
                <td className='user-item-actions'>
                    {approved ? "" : <button className='btn btn-pink' onClick={e=>onApprove(e)}><FontAwesomeIcon icon={faCircleCheck} /></button>}
                    <button className='btn btn-pink' onClick={e=>onDelete(e, comment._id)}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                    {formData.id === comment._id ? <button className="btn btn-pink" onClick={e => onCancelEdit()}><FontAwesomeIcon icon={faBan} /></button> : <button className='btn btn-pink' onClick={e=>onEdit(e, comment._id)}><FontAwesomeIcon icon={faPenToSquare} /></button>}
                </td>
            </tr> : ""
        )
    })
}

CommentsList.propTypes = {
    user: PropTypes.object.isRequired,
    comments: PropTypes.array.isRequired,
    dancers: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    deleteComment: PropTypes.func.isRequired,
    approveComment: PropTypes.func.isRequired,
    approved: PropTypes.bool.isRequired
}


export default connect(null, {deleteComment, approveComment})(CommentsList)