import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'

import { startFreeVipTimer } from '../../actions/user';

const ApplyForFreeVIP = ({startFreeVipTimer, user}) => {

    const onClose = (a) => {
        const applyForFreeVIP = document.getElementById(a);
        applyForFreeVIP.classList.add('display-none');
    }

    const onCancel = (a,b) => {
        const applyForFreeVIP = document.getElementById(a);
        applyForFreeVIP.classList.add('display-none');
        const pleaseBuyVIP = document.getElementById(b);
        pleaseBuyVIP.classList.remove('display-none');
    }

    const onStartVipTimer=()=>{
        startFreeVipTimer();
    }

  return (
    <div className='please-buy-vip display-none' id='apply-for-free-vip'>
        <div className="info-box">
            <div className="info-box-title">
                <h2>申请免费VIP</h2>
                <button className="btn btn-close-white" onClick={e=>onClose('apply-for-free-vip')}>
                    <FontAwesomeIcon icon={faSquareXmark} />
                </button>
            </div>
            <div className="info-box-content">
                <div className="">
                    <p>（1）依据之前经历，48小时内写至少3条3-5分（5分制）舞女评论，即可升为VIP会员，有效期一年。</p>
                    <p>（2）开始计时后，网站图片去除大部分马赛克，方便识别舞女。</p>
                    <p>（3）如编造虚假评论，明显与事实不符，封号处理</p>
                    <p>（4）如未能在48小时内获得3条3-5分评论，则申请失败，失败后可重新申请</p>
                    <p>（5）48小时内完成的评论，将在之后24小时内完成审核，请耐心等待。</p>
                    <div className="grid-two">
                        {user && user.freeVipTimer ? <button className="btn btn-disabled width-100 m-auto" disabled onClick={e => onStartVipTimer()}>开始计时</button> : <button className="btn btn-pink width-100 m-auto" onClick={e => onStartVipTimer()}>开始计时</button>}
                        <button className="btn btn-pink width-100 m-auto" onClick={e => onClose('apply-for-free-vip')}>取消</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

ApplyForFreeVIP.propTypes = {
    startFreeVipTimer: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.user.user
})

export default connect(mapStateToProps, {startFreeVipTimer})(ApplyForFreeVIP);