import React from 'react'

// 引入模块
import Search from '../dancer/Search';
import Dancers from '../dancer/Dancers';
import Rules from '../layout/Rules';
import NewCommentBox from '../dancer/NewCommentBox';

export default () => {
  return (
    <div className='container'>
      <Rules />
        {/* 开通广告之后uncomment下面的三条代码即可显示广告区域 */}
        {/* <div className='grid-container'> */}
            <div>
                <Search/>
                <Dancers />
                
                <NewCommentBox elementID={'homepage-commentBox'}/>
            </div>
            {/* <div>推荐</div> */}
        {/* </div> */}
    </div>
  )
}