import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Moment from 'react-moment';

import { clearBlogs, getBlogs, setCurrentBlogTop } from '../../actions/blogs'
import { clearBtnActive, changeBtnShow } from "../../functions/functions";

import imgPlaceholder from '../../img/imgPlaceholder.png';
import Spinner from '../layout/Spinner';
import { useNavigate } from 'react-router-dom';

const BlogsList = ({    
    blogs,
    blogsCount,    
    blogsLoading,
    category,
    getBlogs,
    setCurrentBlogTop,
    isGranted,
    clearBlogs
}) => {

    const [page, setPage] = useState(1);
    
    const [i, setI] = useState(0);

    const [ renders, setRenders ] = useState(0);

    // useEffect(()=>{}, [renders])
    
    useEffect(()=>{
        try{
        if(renders < 3){
            window.scrollTo(0,0)
        }else{
            const scrollToElement = document.getElementById('scrollToBlogTop');
            scrollToElement.scrollIntoView({ behavior: "smooth"});
        }
        }catch(err){
        console.log(err)
        }
    },[i])


    useEffect(()=>{
      console.log('page changed called getBlogs')
        clearBlogs()
        getBlogs(category, page)
    },[page])

    useEffect(()=>{
      
      console.log('category changed called getBlogs')
      clearBlogs();
      getBlogs(category, 1)
    },[category])

    const navigate = useNavigate();

    const onNavigateTo = id => {
        navigate(`/blog/${id}`);
    }

    let pagiArr = [];
    let pagi = Math.ceil(blogsCount / 20)
    for (let x = 0; x < pagi; x++) {
      pagiArr.push(x + 1);
    }
    // Pagination button controls
  const pageOne = async (e) => {
    try {
      clearBtnActive();
      setI(0);
      setRenders(renders + 1);
      const btn = await document.getElementById("pagi-btn-1");
      if (btn) {
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }
      setPage(1);
      
    } catch (err) {
      console.log(err);
    }
  };

  const pagePrev = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i - 20;
      if (pageStartAt < 0) {
        pageOne(e);
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }
      setPage(page - 1)
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageNext = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i + 20;
      if (pageStartAt > blogsCount) {
        pageLast(e);
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        if (parseInt(btn.innerHTML) > 2 || parseInt(btn.innerHTML) < pagi - 1) {
          for (
            let h = parseInt(btn.innerHTML) - 2;
            h <= parseInt(btn.innerHTML) + 2;
            h++
          ) {
            document
              .getElementById(`pagi-btn-${pagi}`)
              .classList.add("pagi-btn-show");
          }
          changeBtnShow(pagi);
        }
      }
      setPage(page + 1)
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageLast = (e) => {
    try {
      clearBtnActive();
      setI((pagi - 1) * 20);
      setRenders(renders + 1);
      const btn = document.getElementById(`pagi-btn-${pagi}`);
      btn.classList.add("pagi-btn-active");
      changeBtnShow(pagi);
      setPage(pagi)
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageX = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = (parseInt(e.target.innerHTML) - 1) * 20;
      setI(pageStartAt);
      setRenders(renders + 1);
      const btn = document.getElementById(`pagi-btn-${e.target.innerHTML}`);
      btn.classList.add("pagi-btn-active");
      changeBtnShow(pagi);
      setPage(parseInt(e.target.innerHTML))
      
    } catch (err) {
      console.log(err);
    }
  };

  const pageNextFive = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i + 60;
      if (pageStartAt > blogsCount) {
        pageLast();
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }

      setPage(page + 5)
      
    } catch (err) {
      console.log(err);
    }
  };

  const pagePrevFive = (e) => {
    try {
      clearBtnActive();
      const pageStartAt = i - 60;
      if (pageStartAt < 0) {
        pageOne();
      } else {
        setI(pageStartAt);
      setRenders(renders + 1);
        const btn = document.getElementById(`pagi-btn-${pageStartAt / 20 + 1}`);
        btn.classList.add("pagi-btn-active");
        changeBtnShow(pagi);
      }
      setPage( page - 5)
      
    } catch (err) {
      console.log(err);
    }
  };

  

    // useEffect(()=>{
    //     pageOne();
    // },[blogs, blogsLoading, blogsCount])


  const nums = [];
  for (let n = 0; n < 20; n++) {
    nums.push(n);
  }

    const onTopCurrentBlog = (e,id) => {
        e.preventDefault();
        setCurrentBlogTop(id);
    }

  return !blogsLoading && blogsCount > 0 ?
    <div>
        <ul className='blogs-list'  id = 'scrollToBlogTop'>
            {(blogs.filter(a=>a.top === true)).map(blog=> 
                <li className='blogs-list-item' key={blog._id}>
                    <div className="blogs-list-item-thumbnail" onClick={e => onNavigateTo(blog._id)}>
                        <img src={`/uploads/blog/${blog.coverImg}`} alt="placeholder" />
                    </div>
                    <div className="blogs-list-item-brief" onClick={e => onNavigateTo(blog._id)}>
                        <h3 className="blogs-list-item-title">{blog.title}<span className='blog-top-badge'>置顶</span></h3>
                        <div className="blogs-list-item-footer">
                            <p className="small ml-0 mr-auto mt-auto mb-0">
                                <Moment format="YYYY年MM月DD日">
                                    {blog.date}
                                </Moment>发布
                            </p>
                            <p className="small ml-auto mr-0 mt-auto mb-0">{blog.author}</p>
                        </div>
                    </div>  
                    <div></div>              
                    {isGranted ? <div className="blogs-list-item-brief">
                        <button className="ml-auto mr-0" onClick={e => onTopCurrentBlog(e,blog._id)}>取消置顶</button>
                    </div> : ""}
                </li>)}
            {(blogs.filter(a=> a.top !== true)).map(blog=> 
                <li className='blogs-list-item' key={blog._id}>
                    <div className="blogs-list-item-thumbnail" onClick={e => onNavigateTo(blog._id)}>
                        <img src={`/uploads/blog/${blog.coverImg}`} alt="placeholder" />
                    </div>
                    <div className="blogs-list-item-brief" onClick={e => onNavigateTo(blog._id)}>
                        <h3 className="blogs-list-item-title">{blog.title}</h3>
                        <div className="blogs-list-item-footer">
                            <p className="small ml-0 mr-auto mt-auto mb-0">
                                <Moment format="YYYY年MM月DD日">
                                    {blog.date}
                                </Moment>发布
                            </p>
                            <p className="small ml-auto mr-0 mt-auto mb-0">{blog.author}</p>
                        </div>
                    </div>   
                    <div></div>                 
                    {isGranted ? <div className="blogs-list-item-brief">
                        <button className="ml-auto mr-0 text-pink" onClick={e => onTopCurrentBlog(e,blog._id)}>置顶本帖</button>
                    </div> : ""}
                </li>)}

        </ul>
        {blogsCount > 20 ? <div className="pagination-wrapper">
        <div className="pagination">
        <button
            className="pagi-btn show"
            id="pagi-btn-first"
            onClick={(e) => pageOne(e)}
        >
            &laquo;
        </button>
        <button
            className="pagi-btn show"
            id="pagi-btn-prev"
            onClick={(e) => pagePrev(e)}
        >
            &lt;
        </button>
        {pagiArr.length > 5 ? (
            <button
            id="pagi-prev-five"
            className="pagi-btn"
            onClick={(e) => pagePrevFive()}
            >
            ...
            </button>
        ) : (
            ""
        )}
        {pagiArr.map((page) => {
            if (page === 1) {
            return (
                <button
                key={`pagi-${page}`}
                className="pagi-btn pagi-btn-show pagi-btn-active"
                onClick={(e) => pageX(e)}
                id={"pagi-btn-1"}
                >
                {page}
                </button>
            );
            } else if (page > 1 && page <= 5) {
            return (
                <button
                key={`pagi-${page}`}
                className="pagi-btn pagi-btn-show"
                onClick={(e) => pageX(e)}
                id={`pagi-btn-${page}`}
                >
                {page}
                </button>
            );
            } else {
            return (
                <button
                key={`pagi-${page}`}
                className="pagi-btn"
                onClick={(e) => pageX(e)}
                id={`pagi-btn-${page}`}
                >
                {page}
                </button>
            );
            }
        })}
        {pagiArr.length > 5 ? (
            <button
            id="pagi-next-five"
            className="pagi-btn show"
            onClick={(e) => pageNextFive(e)}
            >
            ...
            </button>
        ) : (
            ""
        )}
        <button
            className="pagi-btn show"
            id="pagi-btn-next"
            onClick={(e) => pageNext(e)}
        >
            &gt;
        </button>
        <button
            className="pagi-btn show"
            id="pagi-btn-last"
            onClick={(e) => pageLast(e)}
        >
            &raquo;
        </button>
        </div>
    </div> : ''}
  </div>
    : blogsLoading ? <Spinner /> : <p>暂无文章</p>
}

BlogsList.propTypes = {
    blogs: PropTypes.array.isRequired,
    blogsCount: PropTypes.number.isRequired,
    blogsLoading: PropTypes.bool.isRequired,
    category: PropTypes.string.isRequired,
    getBlogs: PropTypes.func.isRequired,
    setCurrentBlogTop: PropTypes.func.isRequired,
    isGranted: PropTypes.bool.isRequired,
    clearBlogs: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    blogs: state.blog.blogs,
    blogsCount: state.blog.blogsCount,
    blogsLoading: state.blog.blogsLoading,
    category: state.blog.category,
    isGranted: state.auth.isGranted
})

export default connect(mapStateToProps, {getBlogs,setCurrentBlogTop, clearBlogs})(BlogsList)