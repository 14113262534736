import React, {Fragment, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Moment from "react-moment";
import moment from 'moment';
import { connect } from 'react-redux'


import AgentsListItem from './AgentsListItem';



const AgentsList = ({
    agents
}) => {


    const [editID, setEditID] = useState("")
    useEffect(()=>{
        setEditID("");
    },[agents])
    useEffect(()=>{},[editID])


    return agents.map(agent => 
            <tr className="user-item"  id={`tableraw-${agent._id}`} key={`tableraw-${agent._id}`}>
                <td scope='row' className='user-item-table-index'>{agents.indexOf(agent) + 1}</td>
                <AgentsListItem agent={agent}/>
            </tr>
        )
}

AgentsList.propTypes = {
    agents: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    agents: state.management.agents
})

export default connect(mapStateToProps,null)(AgentsList)