import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";

const DataTab = ({dancersCount,usersCount,totalIncome}) => {
    useEffect(()=>{},[dancersCount, usersCount])

  return (
    <div id="dataTab-content" className="tabcontent">
            <div className="data-container-grid">
                <div className="total-data-box">
                    <div className="total-data-box-title">
                        <h2>舞女总数</h2>
                    </div>                    
                    <div className="total-data-box-content">
                        <h2>
                            {dancersCount}人
                        </h2>
                    </div>
                </div>
                <div className="total-data-box">
                    <div className="total-data-box-title">
                    <h2>用户总数</h2>
                    </div>                    
                    <div className="total-data-box-content">
                        <h2>
                            {usersCount}人
                        </h2>
                    </div>
                </div>
                
                <div className="total-data-box">
                    <div className="total-data-box-title">
                    <h2>总收益</h2>
                    </div>
                    <div className="total-data-box-content">
                        <h2>
                            ￥{totalIncome}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
  )
}

DataTab.propTypes = {
    dancersCount: PropTypes.number, 
    usersCount: PropTypes.number,
    totalIncome: PropTypes.number
}

const mapStateToProps = state => ({
    dancersCount: state.management.dancersCount,
    usersCount: state.management.usersCount,
    totalIncome: state.management.totalIncome
})

export default connect(mapStateToProps, {})(DataTab)