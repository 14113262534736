// ----- ALERT -----
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//ANNOUNCEMENT
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const ANNOUNCEMENT_ERROR = 'ANNOUNCEMENT_ERROR';
export const NEW_ANNOUNCEMENT = 'NEW_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';

// AUTH
export const USER_INFO_LOADED = 'USER_INFO_LOADED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const MANAGEMENT_GRANTED = 'MANAGEMENT_GRANTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOGINED = 'USER_LOGINED';
export const CHANGE_ACTION = 'CHANGE_ACTION';

// CITY
export const GET_CITY = 'GET_CITY';
export const GET_CITIES = 'GET_CITIES';
export const CITY_ERROR = 'CITY_ERROR';
export const CITIES_ERROR = 'CITIES_ERROR';
export const NEW_CITY = 'NEW_CITY';
export const DELETE_CITY = 'DELETE_CITY';
// CLUB
export const GET_CLUB = 'GET_CLUB';
export const GET_CLUBS = 'GET_CLUBS';
export const CLUB_ERROR = 'CLUB_ERROR';
export const CLUBS_ERROR = 'CLUBS_ERROR';
export const NEW_CLUB = 'NEW_CLUB';
export const DELETE_CLUB = 'DELETE_CLUB';

//COMMENT
export const GET_COMMENT = 'GET_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const COMMENTS_ERROR = 'COMMENTS_ERROR';
export const NEW_COMMENT = 'NEW_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const APPROVE_COMMENT = 'APPROVE_COMMENT';

// DANCERS
export const GET_DANCERS = 'GET_DANCERS';
export const GET_DANCER = 'GET_DANCER';
export const NEW_DANCER = 'NEW_DANCER';
export const DANCER_ERROR = 'DANCER_ERROR';
export const DANCERS_ERROR = 'DANCERS_ERROR';
export const DELETE_DANCER = 'DELETE_DANCER';
export const HIDE_DANCER = 'HIDE_DANCER';
export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE';
export const REMOVE_FROM_FAVORITE = 'REMOVE_FROM_FAVORITE';
export const FILTER_DANCERS = 'FILTER_DANCERS';
export const GET_DANCERS_COUNT = 'GET_DANCERS_COUNT';
export const DANCERS_COUNT_ERROR = 'DANCERS_COUNT_ERROR';
export const GET_DANCER_FOR_EDITTING = 'GET_DANCER_FOR_EDITTING';
export const GET_DANCER_FOR_EDITTING_IN_DANCERS = 'GET_DANCER_FOR_EDITTING_IN_DANCERS';
export const GET_DANCER_FOR_EDITTING_IN_DANCERS_ERROR = 'GET_DANCER_FOR_EDITTING_IN_DANCERS_ERROR';
export const DANCER_FOR_EDITTING_ERROR = 'DANCER_FOR_EDITTING_ERROR';
export const SHOW_FAVORITE_DANCERS = 'SHOW_FAVORITE_DANCERS';
export const FILTER_DANCERS_ERROR= 'FILTER_DANCERS_ERROR';
export const CLEAR_DANCER = 'CLEAR_DANCER';
export const RESET_DANCERS_UPDATED = 'RESET_DANCERS_UPDATED';
export const SET_DANCER = 'SET_DANCER';
export const RESET_DANCER = 'RESET_DANCER';
export const CHECK_ATTENDANCE = 'CHECK_ATTENDANCE'

//NOTIFICATION
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

//TRANSACTION
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';
export const NEW_TRANSACTION = 'NEW_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const GET_AGENT_TRANSACTIONS = 'GET_AGENT_TRANSACTIONS';
export const APPROVE_TRANSACTION = 'APPROVE_TRANSACTION';

//SECURITYQUESTION
export const GET_SECURITYQUESTION = 'GET_SECURITYQUESTION';
export const GET_SECURITYQUESTIONS = 'GET_SECURITYQUESTIONS';
export const SECURITYQUESTION_ERROR = 'SECURITYQUESTION_ERROR';
export const SECURITYQUESTIONS_ERROR = 'SECURIRYQUESTIONS_ERROR';
export const NEW_SECURITYQUESTION = 'NEW_SECURITYQUESTION';
export const DELETE_SECURITYQUESTION = 'DELETE_SECURITYQUESTION';

// USER
export const UPDATE_USER = 'UPDATE_USER';
export const USER_ERROR = 'USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_NEW_USER_ERROR = 'CREATE_NEW_USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';
export const NEW_USER = 'NEW_USER';
// export const GET_FREE_VIP_QUALIFIED_COMMENTS_COUNT = 'GET_FREE_VIP_QUALIFIED_COMMENTS_COUNT'

// AGENT
export const AGENT_LOADED = 'AGENT_LOADED';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const AGENT_ERROR = 'AGENT_ERROR';
export const CLEAR_AGENT = 'CLEAR_AGENT';
export const NEW_AGENT = 'NEW_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';
export const AGENT_WITHDRAW = 'AGENT_WITHDRAW';

// FILES
export const UPLOAD_VIDEOS = 'UPLOADS_VIDEO';
export const VIDEOS_ERROR = 'VIDEOS_ERROR';
export const UPLOAD_PHOTOS = 'UPLOAD_PHOTOS';
export const PHOTOS_ERROR = 'PHOTOS_ERROR';

// RESERVE
export const NEW_RESERVE = 'NEW_RESERVE';
export const GET_RESERVE = 'GET_RESERVE';
export const GET_RESERVES = 'GET_RESERVES';
export const DELETE_RESERVE = 'DELETE_RESERVE';
export const RESERVE_PROCESSED = 'RESERVE_PROCESSED';
export const RESERVE_ERROR = 'RESERVE_ERROR';
export const RESERVES_ERROR = 'RESERVES_ERROR';

// OTHER MANAGEMENT
export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENTS_ERROR = 'GET_AGENTS_ERROR';
export const GET_USERS_COUNT = 'GET_USERS_COUNT';
export const USERS_COUNT_ERROR = 'USERS_COUNT_ERROR';
export const UPADTE_USER_ACCOUNTTYPE = 'UPADTE_USER_ACCOUNTTYPE';
export const UPDATE_USER_ACCOUNTTYPE_ERROR = 'UPDATE_USER_ACCOUNTTYPE_ERROR';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_TRANSACTIONS_MANAGEMENT = 'GET_TRANSACTIONS_MANAGEMENT';
export const TRANSACTIONS_MANAGEMENT_ERROR = 'TRANSACTIONS_MANAGEMENT_ERROR';
export const TOTAL_INCOME = 'TOTAL_INCOME';
export const TOTAL_INCOME_ERROR = 'TOTAL_INCOME_ERROR';
export const WITHDRAW_ERROR = 'WITHDRAW_ERROR';
export const DOWNLOAD_DANCERS = 'DOWNLOAD_DANCERS';

// BLOGS
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_COUNT = 'GET_BLOGS_COUNT';
export const GET_BLOG = 'GET_BLOG';
export const BLOGS_ERROR = 'BLOGS_ERROR';
export const BLOG_ERROR = 'BLOG_ERROR';
export const CREATE_BLOG = 'CREATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_BLOG_TOP = 'SET_BLOG_TOP';
export const CLEAR_BLOGS = 'CLEAR_BLOGS';


// DISCOUNT CODES
export const GET_DISCOUNTCODE="GET_DISCOUNTCODE";
export const POST_DISCOUNTCODE="POST_DISCOUNTCODE";
export const POST_DISCOUNTCODE_LIST="POST_DISCOUNTCODE_LIST";
export const DELETE_DISCOUNTCODE="DELETE_DISCOUNTCODE";
export const GET_DISCOUNTCODES = 'GET_DISCOUNTCODES';
export const DISCOUNTCODE_ERROR = 'DISCOUNTCODE_ERROR';