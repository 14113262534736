import {
    USER_INFO_LOADED,
    USER_LOADED,
    UPDATE_USER,
    USER_ERROR,
    CLEAR_USER,
    // GET_FREE_VIP_QUALIFIED_COMMENTS_COUNT
  } from "../actions/types";
  
  const initialState = {
    user: null,
    userLoading: true,  
    freeVipValidatedCommentsCount: undefined,
    errors: []
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case CLEAR_USER:
        return {
          ...state,
          user: null,
          userLoading: true
        }
      case USER_INFO_LOADED:
        return {
          ...state,
          userLoading: false,
          user: payload
        }
      case USER_LOADED:
        console.log(payload)
        return {
          ...state,
          userLoading: false,
          user: payload.user,
          freeVipValidatedCommentsCount: payload.freeVipValidatedCommentsCount
        }
      case UPDATE_USER:
        return {
          ...state,
          userLoading: false,
          user: payload
        };
      case USER_ERROR:
        return {
          ...state,
          errors: [payload]
        }
      // case GET_FREE_VIP_QUALIFIED_COMMENTS_COUNT: 
      //   return {
      //     ...state,
      //     freeVipValidatedCommentsCount: payload
      //   }
      default:
        return state;
    }
  }
  