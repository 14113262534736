import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { newComment } from '../../actions/comment';
import { resetDancer } from '../../actions/dancer';
import Spinner from '../layout/Spinner'

// const NewCommentBox = ({ dancerID, user, userLoading, newComment, elementID}) => {
  const NewCommentBox = ({ dancer, user, newComment, elementID}) => {
  
  const [commentFormData, setcommentFormData] = useState({
    authorID: '',
    dancerID: dancer ? dancer.dancerID : "",
    overallRating: '',
    content: '',
  });
  useEffect(() => {
    // console.log('commentFormData: ', commentFormData);

  }, [commentFormData]);

  useEffect(() => {
    if(dancer){setcommentFormData({ ...commentFormData, dancerID: dancer.dancerID });}
  }, [dancer]);

  useEffect(() => {
    if(user){setcommentFormData({ ...commentFormData, authorID: user._id });}
  }, [user]);

  const overallRatingDB = ['一次即可', '值得尝试', '值得常跳', '完美舞女'];

  const onCommentFormChange = (e) => {
    e.preventDefault();
    const field = e.target.id.split('_')[0];
    setcommentFormData({
      ...commentFormData,
      [field]: e.target.value,
    });
  };

  const onClose = () => {
    const element = document.getElementById(elementID);
    element.classList.add('display-none');
    setcommentFormData({
      ...commentFormData,
      // dancerID: "",
      overallRating: '',
      content: '',
    })
    
    document.getElementById('overallRating-warning-text').classList.add('display-none')
    document.getElementById("content_textarea-new-comment-box").value = '';
  };

  const onCreateNewComment = (e) => {
    e.preventDefault();
    if(commentFormData.overallRating === ""){
      document.getElementById('overallRating-warning-text').classList.remove('display-none')
    }else{
      newComment(commentFormData);
      setTimeout(()=>{
        onClose(elementID);
        setcommentFormData({
          ...commentFormData,
          // dancerID: '',
          overallRating: '',
          content: '',
        })
        resetDancer();
      },2000)
    }
  };


  return (
    <div className="new-comment-box-container display-none" id={elementID}>
      {user && dancer 
      ? ( 
        <div className="new-comment-box-overlay">
          <div className="new-comment-box" >
          
              <div className="info-box">
                <div className="info-box-title">
                  <h2>写评论</h2>
                  <button
                    className="btn btn-close-white"
                    onClick={(e) => onClose()}
                  >
                    <FontAwesomeIcon icon={faSquareXmark} />
                  </button>
                </div>
              <div className="info-box-content" >

                <h3 id='overallRating-title'>总评：<span id='overallRating-warning-text' className='text-danger text-small display-none'>*请选择总后递交评论！</span></h3>
                <form id='new-comment-box-form'>
                  <div className="form-input-group">
                    {overallRatingDB.map((item) => (
                      <div
                        className="form-input"
                        key={`overallRating_commentBox-${overallRatingDB.indexOf(item)}`}
                      >
                        <input
                          type="checkbox"
                          className="check-box"
                          id={`overallRating_commentBox-${overallRatingDB.indexOf(item) + 1}`}
                          onChange={(e) => onCommentFormChange(e)}
                          value={item}
                        />
                        <label
                          className={
                            commentFormData.overallRating === item
                              ? 'form-input-label overallRating-class active'
                              : 'form-input-label overallRating-class'
                          }
                          htmlFor={`overallRating_commentBox-${
                            overallRatingDB.indexOf(item) + 1
                          }`}
                          id={`overallRating_commentBox-label-${
                            overallRatingDB.indexOf(item) + 1
                          }`}
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="form-input-group">
                    <h3 className="mr-auto">评论</h3>
                    <h3>350字</h3>
                  </div>
                  <textarea
                    name="commentBox-content-textarea"
                    id="content_textarea-new-comment-box"
                    onChange={(e) => onCommentFormChange(e)}
                    required
                  ></textarea>
                  <button
                    className="btn btn-pink submit-btn"
                    onClick={(e) => onCreateNewComment(e)}
                  >
                  提交
                  </button>
                </form>
                <div className="display-flex">
                  <p className="text-small ml-auto mr-0">*评论将在审核通过后显示</p>
                </div>
                <div className="">
                  <p className='text-small'>【评论换VIP】【评论换现金】评分标准，满分5分:</p>
                  <p className='text-small'>（1分）所在舞厅、时间段，当时舞厅的客流、环境、特殊事件等。</p>
                  <p className='text-small'>（1分）女舞客与网站照片上感受的不同。如近看下颜值胸部身高年龄等。</p>
                  <p className='text-small'>（1分）服务态度与尺度的详细描述。</p>
                  <p className='text-small'>（1分）与女舞客的体验过程。</p>
                  <p className='text-small'>（1-5分）提供身临其境的细节描述和难忘瞬间。</p>
                  <p className='text-small'>（1-5分）提供参考价值高的内幕情报。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        ) : <Spinner />
      }
    </div>
  )
};

NewCommentBox.propTypes = {
  dancer: PropTypes.object,
  user: PropTypes.object,
  userLoading: PropTypes.bool.isRequired,
  newComment: PropTypes.func.isRequired,
  elementID: PropTypes.string.isRequired,
  resetDancer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  dancer: state.dancer.dancer,
  user: state.user.user,
  userLoading: state.user.userLoading
});

export default connect(mapStateToProps, { newComment, resetDancer })(NewCommentBox);
