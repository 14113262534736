import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Moment from 'react-moment';
// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';

import { approveTransaction, deleteTransaction} from '../../actions/transaction'
import { getTransactions, getUsers} from '../../actions/management'
import Spinner from '../layout/Spinner'

const UserTransactionTab = ({
    users,
    getUsers,
    transactions,
    transactionsLoading,
    getTransactions,
    approveTransaction,
    deleteTransaction
}) => {

    useEffect(()=>{
        getUsers();
        getTransactions()
    },[getUsers, getTransactions])
    // },[getUsers])

    const [ elementArray, setElementArray ] = useState({
        toBeProcessed: [],
        processed: []
    });

    const onShowConfirmBox = id => {
        const confirmBox = document.getElementById(id);
        confirmBox.classList.remove('display-none');
    }

    const onClose = id => {
        const element = document.getElementById(id);
        element.classList.add('display-none')
    }

    const onDeleteTransaction = id => {
        deleteTransaction(id);
        onClose(`approve-transaction-confirm-box-for-${id}`)
    }

    const onApproveTransaction = id => {
        approveTransaction(id);
        onClose(`approve-transaction-confirm-box-for-${id}`)
    }

    useEffect(()=>{
        let toBeProcessedTransactionsArray = [];
        let processedTransactionsArray = [];
        if(transactions.length > 0 && users.length > 0){
            const toBeProcessedTransactions = transactions.filter(trans => trans.approved === false);
            const processedTransactions = transactions.filter(trans => trans.approved === true);

            // for(let i = 0; i<toBeProcessedTransactions.length; i++){ 
                if(toBeProcessedTransactions.length > 0){
                    toBeProcessedTransactions.map(item => {
                        const transactionUser = users.find(user => user._id === item.accountID)
                        toBeProcessedTransactionsArray.unshift(
                            <tr className="user-item" key={`user-transaction-${toBeProcessedTransactions.indexOf(item)}`}>
                                <td scope='row' className='user-item-table-index'>{toBeProcessedTransactions.indexOf(item)}</td> 
                                <td className='user-item-username'>{transactionUser ? transactionUser.username : ''}</td>
                                <td className='user-item-date'><Moment format="YYYY/MM/DD">
                                    {item.date}
                                </Moment></td>
                                <td className='user-item-transactionType'>{item.transactionType}</td>
                                <td className='user-item-balance'>{item.amount}</td>
                                <td className='user-item-actions'>
                                    <button className='btn btn-success' onClick={e=>onShowConfirmBox(`approve-transaction-confirm-box-for-${item._id}`)}><FontAwesomeIcon icon={faPenToSquare} />通过</button>
                                    <div className="please-buy-vip display-none" id={`approve-transaction-confirm-box-for-${item._id}`}>
                                        <div className="info-box">
                                            <div className="info-box-title">
                                                <h2>确认通过该交易？</h2>
                                                <button className="btn btn-close-white" onClick={e=>onClose(`approve-transaction-confirm-box-for-${item._id}`)}>
                                                    <FontAwesomeIcon icon={faXmarkCircle} />
                                                </button>
                                            </div>
                                            <div className="info-box-content">
                                                <div className="grid-two">
                                                    <button className='btn btn-pink big-square-btn' onClick={e=>onApproveTransaction(item._id)}>
                                                        审核通过
                                                    </button>
                                                    <button className='btn btn-pink big-square-btn' onClick={e=>onClose(`approve-transaction-confirm-box-for-${item._id}`)}>
                                                        取消
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            // for(let i = 0; i<processedTransactions.length; i++){ 
                 
            if(processedTransactions.length > 0){
                processedTransactions.map(item => {
                    const transactionUser = users.find(user=>user._id === item.accountID)
                    processedTransactionsArray.unshift(
                        <tr className="user-item" key={`user-transaction-${processedTransactions.indexOf(item)}`}>
                            <td scope='row' className='user-item-table-index'>{processedTransactions.indexOf(item)}</td> 
                            <td className='user-item-username'>{transactionUser ? transactionUser.username : ''}</td>
                            <td className='user-item-date'><Moment format="YYYY/MM/DD">
                                {item.date}
                            </Moment></td>
                            <td className='user-item-transactionType'>{item.transactionType}</td>
                            <td className='user-item-balance'>{item.amount}</td>
                            <td className='user-item-actions'>
                                <button className='btn btn-pink' onClick={e=>onShowConfirmBox(`approve-transaction-confirm-box-for-${item.accountID}`)}><FontAwesomeIcon icon={faXmarkCircle} />删除</button>
                                <div className="please-buy-vip display-none" id={`approve-transaction-confirm-box-for-${item.accountID}`}>
                                    <div className="info-box">
                                        <div className="info-box-title">
                                            <h2>确认删除该交易？</h2>
                                            <button className="btn btn-close-white" onClick={e=>onClose(`approve-transaction-confirm-box-for-${item.accountID}`)}>
                                                <FontAwesomeIcon icon={faXmarkCircle} />
                                            </button>
                                        </div>
                                        <div className="info-box-content">
                                            <div className="grid-two">
                                                <button className='btn btn-pink big-square-btn' onClick={e=>onDeleteTransaction(item.accountID)}>
                                                    删除
                                                </button>
                                                <button className='btn btn-pink big-square-btn' onClick={e=>onClose(`approve-transaction-confirm-box-for-${item.accountID}`)}>
                                                    取消
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                })
        }
        setElementArray({
            toBeProcessed: toBeProcessedTransactionsArray,
            processed: processedTransactionsArray
        });
    }
    },[transactions])

    useEffect(()=>{},[elementArray])

  return transactionsLoading ? <Spinner /> : (
    <div id="userTransaction-content" className="tabcontent">
        <h3  className='control-panel-table-title text-pink'>待处理交易</h3>
        <table className='control-panel-table'>
            <thead className='control-panel-tablehead'>
                <tr className="control-panel-table-item">
                    <th scope='col' className='userTransaction-item-table-index'>index</th>
                    <th scope='col' className='userTransaction-item-username'>用户名</th> 
                    <th scope='col' className='userTransaction-item-date'>日期</th> 
                    <th scope='col' className='userTransaction-item-transactionType'>交易类型</th>
                    <th scope='col' className='userTransaction-item-balance'>金额</th>
                    <th scope='col' className='userTransaction-item-actions'>操作</th>         
                </tr>   
            </thead>
            <tbody>
                {elementArray.toBeProcessed}
            </tbody>
        </table>
        <h3  className='control-panel-table-title text-success'>已处理交易</h3>
        <table className='control-panel-table'>
            <thead className='control-panel-tablehead'>
                <tr className="control-panel-table-item">
                    <th scope='col' className='userTransaction-item-table-index'>index</th>
                    <th scope='col' className='userTransaction-item-username'>用户名</th> 
                    <th scope='col' className='userTransaction-item-date'>日期</th> 
                    <th scope='col' className='userTransaction-item-transactionType'>交易类型</th>
                    <th scope='col' className='userTransaction-item-balance'>金额</th>
                    <th scope='col' className='userTransaction-item-actions'>操作</th>         
                </tr>   
            </thead>
            <tbody>
                {elementArray.processed}
            </tbody>
        </table>
    </div>
  )
}

UserTransactionTab.propTypes = {
    users: PropTypes.array,
    getUsers: PropTypes.func.isRequired,
    transactions: PropTypes.array,
    transactionsLoading: PropTypes.bool.isRequired,
    approveTransaction: PropTypes.func.isRequired,
    getTransactions: PropTypes.func.isRequired,
    deleteTransaction: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    users: state.management.users,
    transactions: state.management.transactions,
    transactionsLoading: state.management.transactionsLoading
})

export default connect(mapStateToProps, {approveTransaction, getUsers, getTransactions, deleteTransaction})(UserTransactionTab)