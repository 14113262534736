import axios from "axios";
import { setAlert } from "./alert";

import {
    GET_DISCOUNTCODE,
    GET_DISCOUNTCODES,
    POST_DISCOUNTCODE,
    POST_DISCOUNTCODE_LIST,
    DELETE_DISCOUNTCODE,
    DISCOUNTCODE_ERROR
} from "./types";


import setAuthToken from "../utilities/setAuthToken";


export const getDiscountCodes = () => async (dispatch) => {
    console.log("getDiscountCodes called in action");
    try {
      const res = await axios.get('/api/discountCodes');
      console.log(res.data);
      dispatch({
        type: GET_DISCOUNTCODES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: DISCOUNTCODE_ERROR,
        payload: err.response,
      });
    }
  };

export const getDiscountCode = (discountCode) => async (dispatch) => {
    console.log('getDiscountCode called in action');

    try{
        const res = await axios.get(`/api/discountCodes/${discountCode}`);
        console.log(res.data);
        dispatch({
            type: GET_DISCOUNTCODE,
            payload: res.data
        })
    } catch (err) {
        dispatch({
          type: DISCOUNTCODE_ERROR,
          payload: err.response.data,
        });
      }
}

export const createDiscountCodes = (discountCodes) => async (dispatch) => {
    console.log('creatDiscountCodes called in action');
    try{
        const config = {
            headers: {
              "content-type": "application/json",
            },
          };
          
        const res = await axios.post("/api/discountCodes", discountCodes, config);
        console.log(res.data)
        dispatch(setAlert(res.data.msg, 'success'))

    } catch (err) {
        dispatch({
          type: DISCOUNTCODE_ERROR,
          payload: err.response,
        });
      }
}
