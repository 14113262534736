import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import BlogsList from '../blog/BlogsList'
import BlogNav from '../blog/BlogNav'
import { useParams } from 'react-router-dom'
import { setCategory } from '../../actions/blogs'

const Blogs = ({
  // blogs,
}) => {

  // const { category } = useParams();

  // useEffect(()=>{
    // if(category){setCategory(category)}
  // },[blogs])


  return (
      <div className='container blogs-container'>
        <BlogNav />
        <BlogsList />
      </div>
  );
}

// Blogs.propTypes = {
//   blogs: PropTypes.array.isRequired
// }

// const mapStateToProps = (state) => ({
//   blogs: state.blog.blogs,
// })

// export default connect(mapStateToProps, null)(Blogs)
export default Blogs