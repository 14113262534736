import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import Moment from "react-moment";
import moment from 'moment';
import { connect } from 'react-redux'

import {deleteUser} from '../../actions/user';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faBan } from '@fortawesome/free-solid-svg-icons'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';

import UsersListItem from './UsersListItem';
import { useEffect } from 'react';



const UsersList = ({
    users, 
    deleteUser
}) => {


    const [editID, setEditID] = useState("")
    useEffect(()=>{
        setEditID("");
    },[users])
    useEffect(()=>{},[editID])

    const elementArray = [];

    const onShowWithdrawDepositBox = (id, action) =>{
        console.log('show withdraw / deposit box btn pressed')
    }

    const onShowUserDeleteConfirm = id => {
        const element = document.getElementById(`delete-user-confirm-box-for-${id}`);
        element.classList.remove('display-none');
    }

    const onClose = id => {
        const element = document.getElementById(id);
        element.classList.add('display-none');
    }
        
    const onDelete = (id) => {
        // console.log(id);
        const element = document.getElementById(`delete-user-confirm-box-for-${id}`);
        deleteUser(id);
        element.classList.add('display-none');
    }


    const onEdit = (id) => {
        setEditID(id)
    }

    const onCancelEdit = () => {
        setEditID("")
    }

    // for(let i = 0; i<users.length; i++){ 
    //     elementArray.push(
            
    //         <tr className="user-item"  id={`tableraw-${users[i]._id}`} key={i}>
    //             <td scope='row' className='user-item-table-index'>{i}</td>
    //             <UsersListItem user={users[i]} edit={editID === users[i]._id} />
    //             <td className='user-item-actions'>
    //                 <button className='btn btn-pink' onClick={e => onDelete(users[i]._id)}><FontAwesomeIcon icon={faXmarkCircle} />删除</button>
    //                 {editID === users[i]._id ? <button className='btn btn-pink' onClick={e =>onCancelEdit()}><FontAwesomeIcon icon={faBan}/>取消</button> : <button className='btn btn-pink' onClick={e =>onEdit(users[i]._id)}><FontAwesomeIcon icon={faPenToSquare} />编辑</button>}
    //             </td>
    //         </tr>
    //     )
    // }


    // return elementArray;

    return users.map(user => 
            <tr className="user-item"  id={`tableraw-${user._id}`} key={`tableraw-${user._id}`}>
                {/* <td scope='row' className='user-item-table-index'>{users.indexOf(user) + 1}</td> */}
                <UsersListItem user={user} edit={editID === user._id} />
                <td className='user-item-actions'>
                    <button className='btn btn-pink' onClick={e => onShowWithdrawDepositBox(user._id, 'withdraw')}>提现</button>
                    <button className='btn btn-pink' onClick={e => onShowWithdrawDepositBox(user._id, "deposit")}>充值</button>
                    {/* <button className='btn btn-pink' onClick={e => onShowUserDeleteConfirm(user._id)}><FontAwesomeIcon icon={faXmarkCircle} />删除</button> */}
                    <button className='btn btn-pink' onClick={e => onShowUserDeleteConfirm(user._id)}>删除</button>
                    {/* {editID === user._id ? <button className='btn btn-pink' onClick={e =>onCancelEdit()}><FontAwesomeIcon icon={faBan}/>取消</button> : <button className='btn btn-pink' onClick={e =>onEdit(user._id)}><FontAwesomeIcon icon={faPenToSquare} />编辑</button>} */}
                    {editID === user._id ? <button className='btn btn-pink' onClick={e =>onCancelEdit()}>取消</button> : <button className='btn btn-pink' onClick={e =>onEdit(user._id)}>编辑</button>}
                    <div className="please-buy-vip display-none" id={`delete-user-confirm-box-for-${user._id}`}>
                        <div className="info-box">
                            <div className="info-box-title">
                                <h2>确认删除该账户？</h2>
                                <button className="btn btn-close-white" onClick={e=>onClose(`delete-user-confirm-box-for-${user._id}`)}>
                                    <FontAwesomeIcon icon={faXmarkCircle} />
                                </button>
                            </div>
                            <div className="info-box-content">
                                <div className="grid-two">
                                    <button className='btn btn-pink big-square-btn' onClick={e=>onDelete(user._id)}>
                                        删除
                                    </button>
                                    <button className='btn btn-pink big-square-btn' onClick={e=>onClose(`delete-user-confirm-box-for-${user._id}`)}>
                                        取消
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        )
}

UsersList.propTypes = {
    users: PropTypes.array.isRequired,
    deleteUser: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    users: state.management.users
})

export default connect(mapStateToProps, {deleteUser})(UsersList)