import {
    AGENT_LOADED,
    UPDATE_AGENT,
    AGENT_ERROR,
    CLEAR_AGENT,
    // GET_FREE_VIP_QUALIFIED_COMMENTS_COUNT
  } from "../actions/types";
  
  const initialState = {
    agent: null,
    agentLoading: true,  
    errors: []
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    // console.log(payload)

    switch (type) {

      case CLEAR_AGENT:
        return {
          ...state,
          agent: null,
          agentLoading: true,
          errors: []
        }
      case AGENT_LOADED:
        return {
          ...state,
          agentLoading: false,
          agent: payload
        }
      case UPDATE_AGENT:
        return {
          ...state,
          agentLoading: false,
          agent: payload
        };
      case AGENT_ERROR:
        return {
          ...state,
          errors: [...state.errors, payload]
        }
      default:
        return state;
    }
  }
  