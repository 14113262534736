import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'

import {newTransaction} from '../../actions/transaction';

const UserWithdrawBox = ({user, newTransaction}) => {

    const [formData, setFormData] = useState({
        accountID: user._id,
        action: 'withdraw',
        amount: 0
    })

    const onClose = (id) => {
        const element = document.getElementById(id);
        element.classList.add('display-none');
    }

    const onSubmitWithdrawRequest = e => {
        e.preventDefault();
        newTransaction(formData)
    }

  return (
    <div className='please-buy-vip display-none' id='user-withdraw-box'>
            <div className="info-box">
                <div className="info-box-title">
                    <h2>申请提现</h2>
                    <button className="btn btn-close-white" onClick={e=>onClose('user-withdraw-box')}>
                        <FontAwesomeIcon icon={faSquareXmark} />
                    </button>
                </div>
                <div className="info-box-content">
                    <form className="grid-two">
                        <input type="number" name="withdraw-input" id="user-withdraw-input" />
                        <button className='btn btn-pink big-square-btn' onClick={e=>onSubmitWithdrawRequest()}>
                            递交申请
                        </button>
                    </form>
                </div>
            </div>
            {/* <div className="dark-overlay"></div> */}
        </div>
  )
}

UserWithdrawBox.propTypes = {
    user: PropTypes.object.isRequired,
    newTransaction: PropTypes.func.isRequired
}

export default connect(null, newTransaction)(UserWithdrawBox)