import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { redirect, useNavigate } from "react-router-dom";
import {connect} from 'react-redux'
import Moment from "react-moment";
import moment from 'moment';

import Transactions from '../user/Transactions';
import FavoriteDancers from './FavoriteDancers';
import SharingTab from './SharingTab';
import Spinner from '../layout/Spinner';

import { loadUser, updateUser, updatePasswordWithOld } from '../../actions/user';
import { showLogin } from '../../functions/functions';
import { setAlert } from '../../actions/alert';
// import { getFreeVipQualifiedCommentsCount } from '../../actions/comment';


const User = ({
    isAuthenticated,
    user,
    userLoading,
    freeVipValidatedCommentsCount,
    loadUser,
    updateUser,
    updatePasswordWithOld,
    setAlert
}) => {
    const token = localStorage.getItem('DCtoken');
    useEffect(()=>{
        if(token){ loadUser(); }else{ 
            showLogin() 
        }
    },[loadUser]) 

    useEffect(()=>{
        if(!token && !isAuthenticated ){showLogin()}
    },[isAuthenticated])

    const [userFormData, setUserFormData] = useState({
        _id: "",
        weChat: "",
        // password: '',
    })    

    const [ remainingHours, setRemainingHours ] = useState(0);
    useEffect(()=>{
        setUserFormData(user)
        if(user){
            const today = new Date();
            const freeVipTimerDate = new Date(user.freeVipTimer);
            const hoursLeft = 48 - Math.floor((today - freeVipTimerDate)/3600000);
            setRemainingHours(hoursLeft);   
        }
    },[user, userLoading])

    const [currentTab, setCurrentTab] = useState('user-info-tab')
    
    const [pw, setPw] = useState({
        oldPassword: '',
        updatePassword: ''
    })

    const [confirmUpdatePassword, setConfirmUpdatedPassword ] = useState('')

    const { updatePassword } = pw;


    useEffect(()=>{
        // console.log(pw);
        // console.log(userFormData)
        // console.log(confirmUpdatePassword)
    },[pw,confirmUpdatePassword, userFormData])

    const onSetCurrentTab = id =>{
        setCurrentTab(id);
    }

    const onChange = e => {
        setUserFormData({
            ...userFormData,
            [e.target.name]: e.target.value
        })
    }

    const onUpdateUser = e =>{
        e.preventDefault();
        // console.log('updateUser function');
        updateUser(userFormData)
    }

    const dangerColor = 'rgb(255, 153, 153)';
    const successColor ="rgb(204, 255, 204)";

    const onPasswordChange = e => {
        // console.log(e.target.value)
        if(e.target.value.length < 8){
            // 显示input后方红叉
            e.target.style.backgroundColor = dangerColor
        }else{
            // 显示input后方绿对号
            switch (e.target.name){
                case 'oldPassword':                    
                    setPw({...pw, oldPassword: e.target.value});
                    e.target.style.backgroundColor = successColor;
                    break;
                case 'updatePassword': 
                    setPw({ ...pw, updatePassword: e.target.value});
                    e.target.style.backgroundColor = successColor;
                    break;
                case 'confirmUpdatePassword':
                    setConfirmUpdatedPassword(e.target.value)
                    if(e.target.value === updatePassword){
                        e.target.style.backgroundColor = successColor;
                    }else{
                        e.target.style.backgroundColor = dangerColor;
                    }
                    break;
                default: 
                    break;
            }
        };
        // document.getElementById('password-checked').classList.add('display')
      };
    
        const navigate = useNavigate()
      const onPasswordConfirm = e =>{
        e.preventDefault();
        // console.log(confirmUpdatePassword === updatePassword)
        if(confirmUpdatePassword === updatePassword){
            updatePasswordWithOld(pw)
            setTimeout(()=>{navigate('/')},3000)
            // console.log('matched')
        }else{
            setAlert('新密码两次输入不匹配，请检查后重新递交！', 'danger')
        }
      }

      const onShowInfobox = text => {
        const element = document.getElementById('sharingtab-infobox-content');
        element.innerText = text;
        const infobox = document.getElementById('sharingtab-infobox');
        infobox.classList.remove('display-none')
      }

      return ( 
    userLoading ? <Spinner /> : 
    <div className='container '>
        <div className="grid-container-user">
            <div className="tab user-tab">
                <button className={currentTab === 'user-info-tab' ? 'tablinks user-tablinks selected' : 'tablinks user-tablinks'} onClick={e=>onSetCurrentTab('user-info-tab')}>个人信息</button>
                {/* {user.user.accountType === 'agent' ? <button className={currentTab === 'sharing-tab' ? 'tablinks user-tablinks selected' : 'tablinks user-tablinks'} onClick={e=>onSetCurrentTab('sharing-tab')}>转发分成</button>:""} */}
                {/* <button className={currentTab === 'sharing-tab' ? 'tablinks user-tablinks selected' : 'tablinks user-tablinks'} onClick={e=>onSetCurrentTab('sharing-tab')}>转发分成</button> */}
                <button className={currentTab === 'sharing-tab' ? 'tablinks user-tablinks selected' : 'tablinks user-tablinks'} onClick={e=>onShowInfobox('转发分成功能近期上线，敬请期待！')}>转发分成</button>
                <button className={currentTab === 'transaction-history-tab' ? 'tablinks user-tablinks selected' : 'tablinks user-tablinks'} onClick={e=>onSetCurrentTab('transaction-history-tab')}>交易记录</button>
                <button className={currentTab === 'favorite-dancers-tab' ? 'tablinks user-tablinks selected' : 'tablinks user-tablinks'} onClick={e=>onSetCurrentTab('favorite-dancers-tab')}>美女收藏</button>
            </div>
            <div className="tabs user-tabs">
                <div className={currentTab === 'user-info-tab' ? "tabcontent" : "tabcontent display-none"} id='user-info-tab'>
                    <div className="tabcontent-user-item">
                        <h2 className="tabcontent-title">用户名</h2>
                        
                        <div className="tabcontent-user-input-group">{user.username ?<p className="tabcontent-text"> {user.username}</p>:<p className="tabcontent-text"></p>}</div>
                    </div>
                    <form className="tabcontent-user-item" id='user-nickname-update-form'>
                        <h2 className="tabcontent-title">昵称</h2>
                        <div className="tabcontent-user-input-group">
                            <input type="text" name="nickName" id="nickName-input" defaultValue={user.nickName ? user.nickName : ""} onChange={e => onChange(e)}/>
                            <button className='user-tabs-update-btn' onClick={e=>onUpdateUser(e)}>更新</button>
                        </div>
                    </form>
                    <form className="tabcontent-user-item" id='user-weChat-update-form'>           
                        <h2 className="tabcontent-title">微信号</h2>  
                        <div className="tabcontent-user-input-group">          
                            <input type="text" name="weChat" id="weChat-input" defaultValue={user.weChat ? user.weChat : ""} onChange={e => onChange(e)}/>
                            <button className='user-tabs-update-btn' onClick={e=>onUpdateUser(e)}>更新</button>
                        </div> 
                    </form>
                    <div className="tabcontent-user-item">
                        <h2 className="tabcontent-title">会员等级</h2>
                        <div className="tabcontent-user-input-group">
                            {user && user.accountType ? <p className="tabcontent-text">{user.accountType==='normal' ? "注册会员" : ""}{user.accountType==='super' || user.accountType==='manager' || user.accountType==='admin' ? "VVIP会员" : ""}{user.accountType==='vip' ? "VIP会员" : ""}{user.accountType==='experience-vip' || user.accountType === 'discount-experience' ? "体验版会员" : ""}</p> : ""}
                        </div>
                    </div>
                    <div className="tabcontent-user-item">
                        <h2 className="tabcontent-title">评论换VIP剩余时间: </h2>
                        <div className="tabcontent-user-input-group">
                            {user.freeVipTimer ? 
                                <p className="tabcontent-text text-pink">{remainingHours}小时</p> : <p className="tabcontent-text text-gray">无</p>
                            }
                        </div>
                        <h2 className="tabcontent-title">换取VIP达标评论数: </h2>
                        <div className="tabcontent-user-input-group">
                            {user.freeVipTimer ? 
                                <p className="tabcontent-text"><span className='text-pink'>{freeVipValidatedCommentsCount}条</span> <span className='ml-2'>还需{7 - freeVipValidatedCommentsCount}条达标评论</span></p> : <p className="tabcontent-text text-gray">无</p>
                            }
                        </div>
                    </div>
                    {/* {user.accountType === 'VIP' ?  */}
                        {user.accountType !== 'normal' && user.vipUntil ? <div className="tabcontent-user-item">
                            <h2 className="tabcontent-title">VIP有效期</h2>
                            
                            <div className="tabcontent-user-input-group"><Moment className='tabcontent-text' format="YYYY/MM/DD">{moment(user.vipUntil)}</Moment></div>
                        </div> : ""}
                        
                    {/*       : ""
                    }*/}
                    <form className="tabcontent-user-item" id="user-update-password-form">
                        <h2 className="tabcontent-title">更改密码</h2>
                        <div className="tabcontent-input-group">
                            <div className="input-group">
                                <label htmlFor="oldPassword">输入旧密码</label>
                                <input type="password" name="oldPassword" id="oldPassword" onChange={e => onPasswordChange(e)}/>
                            </div>
                            <div className="input-group">
                                <label htmlFor="updatePassword">输入新密码</label>
                                    <input type="password" name="updatePassword" id="updatePassword" onChange={e => onPasswordChange(e)}/>
                            </div>
                            <div className="input-group">
                                <label htmlFor="confirmUpdatePassword">确认新密码</label>
                                <input type="password" name="confirmUpdatePassword" id="confirmUpdatePassword" onChange={e => onPasswordChange(e)}/>
                            </div>
                            <button className='user-tabs-update-btn change-password-btn' onClick={e=>onPasswordConfirm(e)}>更新密码</button>
                        </div>           
                         
                    </form> 
                </div>
                <div  className={currentTab === 'transaction-history-tab' ? "tabcontent" : "tabcontent display-none"} id='transaction-history-tab'>
                    <Transactions userID={user._id}/>
                </div>
                <div  className={currentTab === 'favorite-dancers-tab' ? "tabcontent" : "tabcontent display-none"} id='favorite-dancers-tab'>
                    <FavoriteDancers favoriteDancers={user.wantToReserveDancers}/>
                </div>
                {/* {user.user.accountType === 'agent' ? <div  className={currentTab === 'sharing-tab' ? "tabcontent" : "tabcontent display-none"} id='sharing-tab'>
                    <SharingTab />
                </div> : ""} */}
                <div  className={currentTab === 'sharing-tab' ? "tabcontent" : "tabcontent display-none"} id='sharing-tab'>
                    <SharingTab user={user}/>
                </div>
            </div>
        </div>
        <div className=" confirmation-box-container display-none" id='sharingtab-infobox'>
            <div className="info-box">
                <div className="info-box-title">
                    <h2>通知</h2>
                </div>
                <div className="info-box-content">
                    <div className='my-3' id='sharingtab-infobox-content'></div>
                    <div className="display-flex">
                        <button className="btn btn-pink width-100 mx-auto" onClick={e => document.getElementById('sharingtab-infobox').classList.add('display-none')}>确定</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

User.propTypes = {
    user: PropTypes.object,
    userLoading: PropTypes.bool.isRequired,
    loadUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    updatePasswordWithOld: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.user.user,
    userLoading: state.user.userLoading,
    freeVipValidatedCommentsCount: state.user.freeVipValidatedCommentsCount
})

export default connect(mapStateToProps,{loadUser, updateUser, updatePasswordWithOld, setAlert})(User)