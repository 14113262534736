import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

import {getAgentWithdrawRequests, approveTransaction} from '../../actions/transaction'
import { getAgents } from '../../actions/management'
import Spinner from '../layout/Spinner'

const AgentWithdrawTab = ({
    agents,
    getAgents,
    agentTransactions,
    agentTransactionsLoading,
    getAgentWithdrawRequests,
    approveTransaction
}) => {

    useEffect(()=>{getAgents();getAgentWithdrawRequests()},[getAgents, getAgentWithdrawRequests])

    const [ elementArray, setElementArray ] = useState([]);

    // const onApprove = (id) => {
    //     approveTransaction(id);
    // }

    useEffect(()=>{
        let array = []
        if(agentTransactions.length > 0){for(let i = 0; i<agentTransactions.length; i++){ 
            array.unshift(
                <tr className="user-item" key={`agent-transaction-${i}`}>
                    <td scope='row' className='user-item-table-index'>{i}</td> 
                    <td className='user-item-username'>{agents.find(agent=>agent._id === agentTransactions[i].accountID).username}</td>
                    <td className='user-item-weChat'>{agents.find(agent=>agent._id === agentTransactions[i].accountID).weChat}</td>
                    <td className='user-item-balance'>{agentTransactions[i].amount}</td>
                    <td className='user-item-actions'>
                        <button className='btn btn-pink' onClick={e=>approveTransaction(agentTransactions[i]._id)}><FontAwesomeIcon icon={faPenToSquare} />通过</button>
                    </td>
                </tr>
            )
        };}
        setElementArray(array);
    },[agentTransactions])

    useEffect(()=>{},[elementArray])

  return agentTransactionsLoading ? <Spinner /> : (
    <div id="adminWithdrawTab-content" className="tabcontent">
        <table className='control-panel-table'>
            <thead className='control-panel-tablehead'>
                <tr className="control-panel-table-item">
                    <th scope='col' className='adminWithdraw-item-table-index'>index</th>
                    <th scope='col' className='adminWithdraw-item-username'>用户名</th> 
                    <th scope='col' className='adminWithdraw-item-weChat'>微信号</th> 
                    <th scope='col' className='adminWithdraw-item-balance'>今天以来未提现金额</th>
                    <th scope='col' className='adminWithdraw-item-actions'>操作</th>         
                </tr>   
            </thead>
            <tbody>
                {elementArray}
            </tbody>
        </table>
    </div>
  )
}

AgentWithdrawTab.propTypes = {
    agents: PropTypes.array.isRequired,
    getAgents: PropTypes.func.isRequired,
    agentTransactions: PropTypes.array.isRequired,
    agentTransactionsLoading: PropTypes.bool.isRequired,
    getAgentWithdrawRequests: PropTypes.func.isRequired,
    approveTransaction: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    agents: state.management.agents,
    agentTransactions: state.management.agentTransactions,
    agentTransactionsLoading: state.management.agentTransactionsLoading
})

export default connect(mapStateToProps, {approveTransaction, getAgents, getAgentWithdrawRequests})(AgentWithdrawTab)