import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Moment from "react-moment";
import moment from 'moment';

import {updateUserAccountType} from '../../actions/management';


const UsersListItem = ({
    user, 
    edit=false,
    updateUserAccountType
}) => {

    useEffect(()=>{},[user,edit])
    
    const [formData, setFormData] = useState({
        _id: user._id,
        accountType: user.accountType,
        vipType: 'yearly'
    })

    useEffect(()=>{},[formData])

    const onUpdateUser = e => {
        e.preventDefault();
        updateUserAccountType(formData);
    }


    const userAccountTypeInput = edit ? (
        <form className='user-accountType-form' id={`change-userAccount-form-${user._id}`} onSubmit={e=>onUpdateUser(e)}>
            <select name="change-userAccount-select" id={`change-userAccount-select-${user._id}`} defaultValue={user.accountType} onChange={e => setFormData({...formData, accountType: e.target.value})}>
                <option value="manager">总管理员/manager</option>
                <option value="admin">管理员/admin</option>
                <option value="agent">宣传员/agent</option>
                <option value="vip">VIP</option>
                <option value="normal">普通会员</option>
            </select>
            {formData.accountType === "vip" ? <select name="change-vipType-select" id={`change-vipType-select-${user._id}`} defaultValue='yearly' onChange={e => setFormData({...formData, vipType: e.target.value})}>
                <option value="yearly">年费会员</option>
                <option value="monthly">月费会员</option>
            </select> : ""}
            <button type="submit" className='btn btn-pink'>更新</button>
        </form>
    ) : user.accountType

  return (
    // <tr className="user-item"  id={`tableraw-${user._id}`} key={i}>
    //     <td scope='row' className='user-item-table-index'>{i}</td>
        <Fragment>
            <td className='user-item-username'>{user.username}</td> 
            {/* <td className='user-item-email'>{user.email}</td> */}
            <td className='user-item-weChat'>{user.weChat}</td>
            <td className={edit ? 'user-item-accountType-edit' : 'user-item-accountType'}>{userAccountTypeInput}</td>
            <td className='user-item-vipUntil text-align-center'>
                {user.vipUntil ? <Moment className='tabcontent-text' format="YYYY/MM/DD">{moment(user.vipUntil)}</Moment>:  user.accountType === 'normal' ? "-" : '永久'}
            </td>        
            <td className='user-item-balance'>{user.balance}</td>
            
            <td className='user-item-withdraw'>{user.withdrawInProcess}</td>
        </Fragment>
    //  </tr>
  )
}

UsersListItem.propTypes = {
    user: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    updateUserAccountType: PropTypes.func.isRequired
}

export default connect(null, {updateUserAccountType})(UsersListItem)