import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CommentsList from './CommentsList'
import Spinner from '../layout/Spinner'
import { connect } from 'react-redux'

const CommentsTab = ({
    comments,
    commentsLoading, 
    user,
    users,
    usersLoading, 
    dancers,
    dancersLoading
}) => {
    const [freeVipComments, setFreeVipComments] = useState([]);

    useEffect(()=>{
        let freeVipUsers = [];
        if(!usersLoading){
            freeVipUsers = users.filter(user => user.freeVipTimer)
        }
        const commentsForVip = comments.filter(comment => freeVipUsers.includes(user => user._id === comment.authorID))
        setFreeVipComments(commentsForVip);
    },[comments, users])

    useEffect(()=>{},[dancers])


  return !commentsLoading && !usersLoading && !dancersLoading  ? (
    <div id="commentsTab-content" className="tabcontent control-panel-tabcontent">
        {/* 所有评论以列表形式展现，只可见基本信息 */}
        <h3  className='control-panel-table-title'>待处理换取VIP评论</h3>
        {freeVipComments.length > 0 ? 
        <table className='control-panel-table'>
            <thead className='control-panel-tablehead'>
                <tr className="control-panel-table-item">
                    <th scope='col' className='comments-item-table-index'>i</th>
                    <th scope='col' className='comments-item-dancerID'>舞女昵称</th> 
                    <th scope='col' className='comments-item-accountType'>会员类别</th>
                    <th scope='col' className='comments-item-overallRating'>总评</th>
                    <th scope='col' className='comments-item-content'>评论内容</th>
                    <th scope='col' className='comments-item-approved'>状态</th>                    
                    <th scope='col' className='comments-item-score'>分数</th>
                    <th scope='col' className='comments-item-actions'>操作</th>         
                </tr>   
            </thead>
                
            <tbody>
                <CommentsList comments={freeVipComments} dancers={dancers} users={users} approved={false} user={user}/>
            </tbody>
        </table> : <p className='text-align-center text-gray mb-2'>暂无评论需要处理</p>}
        <h3  className='control-panel-table-title'>待处理评论</h3>
        {comments.filter(comment=> comment.approved === false || !comment.score).length > 0 ? 
        <table className='control-panel-table'>
            <thead className='control-panel-tablehead'>
                <tr className="control-panel-table-item">
                    <th scope='col' className='comments-item-table-index'>i</th>
                    <th scope='col' className='comments-item-dancerID'>舞女昵称</th> 
                    <th scope='col' className='comments-item-accountType'>会员类别</th>
                    <th scope='col' className='comments-item-overallRating'>总评</th>
                    <th scope='col' className='comments-item-content'>评论内容</th>
                    <th scope='col' className='comments-item-approved'>状态</th>                    
                    <th scope='col' className='comments-item-score'>分数</th>
                    <th scope='col' className='comments-item-actions'>操作</th>         
                </tr>   
            </thead>
                
            <tbody>
                <CommentsList comments={comments.filter(comment=> comment.approved === false)} dancers={dancers} users={users} approved={false} user={user}/>
            </tbody>
        </table> : <p className='text-align-center text-gray mb-2'>暂无评论需要处理</p>}
        <h3  className='control-panel-table-title'>已处理评论</h3>
        {comments.filter(comment=> comment.approved === true || comment.score).length > 0 ? <table className='control-panel-table'>
            <thead className='control-panel-tablehead'>
                <tr className="control-panel-table-item">
                    <th scope='col' className='comments-item-table-index'>i</th>
                    <th scope='col' className='comments-item-dancerID'>舞女昵称</th> 
                    <th scope='col' className='comments-item-accountType'>会员类别</th>
                    <th scope='col' className='comments-item-overallRating'>总评</th>
                    <th scope='col' className='comments-item-content'>评论内容</th>
                    <th scope='col' className='comments-item-approved'>状态</th>                    
                    <th scope='col' className='comments-item-score'>分数</th>
                    <th scope='col' className='comments-item-actions'>操作</th>         
                </tr>   
            </thead>
                
            <tbody>
                <CommentsList comments={comments.filter(comment=> comment.approved === true)} dancers={dancers} users={users} approved={true} user={user}/> 
            </tbody>
        </table>: <p className='text-align-center text-gray'>暂无评论</p>}
    </div>
  ) : <Spinner/>
}

CommentsTab.propTypes = {
    comments: PropTypes.array,
    users: PropTypes.array,
    user: PropTypes.object,
    dancers: PropTypes.array,
    usersLoading:PropTypes.bool.isRequired,
    commentsLoading: PropTypes.bool.isRequired,
    dancersLoading: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    comments: state.comment.comments,
    dancers: state.dancer.dancers,
    users: state.management.users,
    user: state.user.user,
    usersLoading: state.management.usersLoading,
    commentsLoading: state.comment.commentsLoading,
    dancersLoading: state.dancer.dancersLoading
})

export default connect(mapStateToProps, null)(CommentsTab)