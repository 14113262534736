import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRightFromBracket, faGear, faSquareXmark, faBars } from '@fortawesome/free-solid-svg-icons'

import { toggleSidenav, toggleMenuDropdown } from "../../functions/functions";
import { setAlert } from "../../actions/alert";
import { changeAction, logout } from '../../actions/auth';


import Login from '../auth/Login'
import { setCategory } from '../../actions/blogs';

const Navbar = ({
    setAlert,
    auth: { isAuthenticated, loading, action },
    user,
    userLoading,
    logout,
    setCategory,
    changeAction
}) => {

    const navigate = useNavigate();
    // const [action, setAction] = useState('login')

    const showLogin = text => {
        changeAction(text);
        document.getElementById(`${action}-box`).classList.remove('display-none')
    }

    const onMouseLeaveSiteHeader = e => {
        const elementStyle = document.getElementById('site-header-small').style;
        elementStyle.color = 'white';
        elementStyle.backgroundColor = '#fe8291';
    }

    const onMouseOverSiteHeader = e => {        
        const elementStyle = document.getElementById('site-header-small').style;
        elementStyle.color = '#fe8291';
        elementStyle.backgroundColor = 'white';
    }

    const showApplyForFreeVip = e => {
        const applyForFreeVip = document.getElementById('apply-for-free-vip');
        applyForFreeVip.classList.remove('display-none')
    }

    const showPleaseBuyVip = e => {
        const pleaseBuyVip = document.getElementById('please-buy-vip');
        pleaseBuyVip.classList.remove('display-none')
    }

    const showPersonalPublishNotice = e => {
        const personalPublish = document.getElementById('personal-publish-notice');
        personalPublish.classList.remove('display-none')
    }
    
    const onClose = (a) => {
        const applyForFreeVIP = document.getElementById(a);
        applyForFreeVIP.classList.add('display-none');
    }

    const showPersonalPublishError = e => {
        setAlert('账号发布次数异常，请等待管理员审核后继续上传。', 'danger')
        setTimeout(()=>{onClose('personal-publish-notice');},1000)
        
    }

    const onLogout = () => {
        logout();
        setTimeout(()=>{navigate('/')},500)
    }

    const toggleAndShowLogin = action => {
        showLogin(action);
        toggleSidenav();
    }

    useEffect(()=>{
    },[action, isAuthenticated, userLoading, user, ])

    useEffect(()=>{},[window.innerWidth])

    return (
        <Fragment>
            <nav className="navbar">
                <div className="navbar-container">
                    <div className="navbar-wrapper">
                        <NavLink className="site-header" to="/" reloadDocument onMouseOver={e=>onMouseOverSiteHeader(e)} onMouseLeave={e=>onMouseLeaveSiteHeader(e)}>
                        <span className='site-header-large' id='site-header-large'>苦笑</span><small className='site-header-small' id='site-header-small'>人间</small>
                        </NavLink>
                        <p className='site-header-smaller'>舞厅资讯站</p>
                    </div>
                    
                    { window.innerWidth <= 699 ? (<div className='navbar-wrapper'>
                        <button className='navbar-navlink btn-link' onClick={e=>toggleSidenav()}><FontAwesomeIcon icon={faBars} /></button>
                        </div>
                     ) : (
                        <div className="navbar-wrapper">
                        {isAuthenticated && !loading ? 
                            <Fragment>
                                {!userLoading && (user.accountType === 'super' || user.accountType === 'manager' || user.accountType === 'admin') ?<NavLink
                                to="/control-panel"
                                id="control-panel-navlink"
                                className="navbar-navlink"
                                >
                                    <span className='hide-on-small'>控制中心</span>
                                    <FontAwesomeIcon className='navlink-icon' icon={faGear} />
                                </NavLink> :""}
                                <NavLink
                                to="/user"
                                id="user-navlink"
                                className="navbar-navlink"
                                >
                                    <span className='hide-on-small'>个人中心</span>
                                    <FontAwesomeIcon className='navlink-icon' icon={faUser} />
                                </NavLink>
                                <a
                                className="nav-logout navbar-navlink mr-1"
                                onClick={(e) => onLogout()}
                                >
                                    <span className='hide-on-small'>退出登录</span>
                                    <FontAwesomeIcon className='navlink-icon' icon={faRightFromBracket} />
                                </a>
                            </Fragment>
                         : (
                            <Fragment>
                                <NavLink
                                to="#"
                                className="navbar-navlink mr-1"
                                onClick={e=>showLogin('login')}
                                >
                                    登录
                                </NavLink>
                                <NavLink
                                to="#"
                                className="navbar-navlink mr-1"
                                onClick={e=>showLogin('register')}
                                >
                                    注册
                                </NavLink>
                            </Fragment>
                        )}
                    </div>)}
                </div>
                
                {window.innerWidth <= 699 ? '' : <div className='navbar-container secondary-manu'>
                    <NavLink className='btn btn-text-pink left-most-btn' to='/'>女舞客</NavLink>     
                    <button className='btn btn-text-pink middle-btn' onClick={e=>user ? (user.accountType === 'normal' ? showPleaseBuyVip(e) : showPersonalPublishNotice(e)) : showLogin('login')}>个人发布</button>
                    <NavLink className='btn btn-text-pink middle-btn' onClick={e => setCategory('latest')} to='/blog'>舞厅攻略</NavLink>               
                    {user ? 
                        <NavLink className='btn btn-text-pink middle-btn' to='/buy-vip' >{user.accountType==='normal' ? '升级VIP' : 'VIP续费'}</NavLink>
                         : <button className='btn btn-text-pink middle-btn' onClick={e=>showLogin('login')}>升级VIP</button>
                    }                    
                    <button className='btn btn-text-pink middle-btn' onClick={e=>user ? (user.accountType==='normal' ? showApplyForFreeVip(e) : setAlert('您已经是VIP','success')) : showLogin('login')}>申请免费VIP</button>
                    {/* <button className="btn btn-text-pink right-most-btn">招聘</button> */}
                    <NavLink className='btn btn-text-pink right-most-btn' onClick={e => setCategory('recruit')} to='/blog/c/recruit'>招聘</NavLink>
                </div>}
                <div className="display-none" id="side-nav-underlay" onClick={e=>toggleSidenav()}></div>
                {window.innerWidth <= 699 ? <div className="side-nav" id="side-nav">
                    <ul>
                        <li>
                            <NavLink to="/" onClick={e=>toggleSidenav()}>
                            女舞客
                            </NavLink>
                        </li>
                        <li onClick={e=>{toggleSidenav(); user ? (user.accountType === 'normal' ? showPleaseBuyVip(e) : showPersonalPublishNotice(e)) : showLogin('login')}}>
                            个人发布
                        </li>
                        <li>
                            <NavLink onClick={e=>{setCategory('latest'); toggleSidenav()}} to='/blog'>舞厅攻略</NavLink>    
                        </li>               
                        {user ? 
                            <li><NavLink to='/buy-vip' onClick={e=>toggleSidenav()}>{user.accountType==='normal' ? '升级VIP' : 'VIP续费'}</NavLink></li> : <li onClick={e=>{toggleSidenav(); showLogin('login')}}>升级VIP</li>}                    
                        <li onClick={e=>{toggleSidenav(); user ? (user.accountType==='normal' ? showApplyForFreeVip(e) : setAlert('您已经是VIP','success')) : showLogin('login')}}>申请免费VIP</li>
                        <li>
                            <NavLink onClick={e=>{setCategory('recruit'); toggleSidenav()}} to='/blog/c/recruit'>招聘</NavLink>    
                        </li>
                        {user && (user.accountType === 'super' || user.accountType === 'manager' || user.accountType === 'admin') ?
                        <li>
                            <NavLink
                            to="/control-panel"
                            onClick={e=>toggleSidenav()}
                            >
                            控制中心
                            </NavLink>
                        </li> : '' }
                        {isAuthenticated ? (
                            <Fragment>
                                <li>
                                    <NavLink
                                    to="/user"
                                    onClick={e=>toggleSidenav()}
                                    >
                                    个人中心
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/" className="nav-logout" onClick={(e) => {toggleSidenav(); logout()}}>
                                        退出登录
                                    </NavLink>
                                </li>
                            </Fragment>
                        ) : (
                            <li className="nav-login">
                                <span onClick={(e) => toggleAndShowLogin('login')}>登录</span> / <span onClick={(e) => toggleAndShowLogin('register')}>注册</span>
                            </li>
                        )}
                    </ul>
                </div> : ''}
            </nav>
            
            <Login action={action}/>
            <div className='please-buy-vip display-none' id='personal-publish-notice'>
                <div className="info-box">
                    <div className="info-box-title">
                        <h2>个人发布提示</h2>
                        <button className="btn btn-close-white" onClick={e=>onClose('personal-publish-notice')}>
                            <FontAwesomeIcon icon={faSquareXmark} />
                        </button>
                    </div>
                    <div className="info-box-content">
                        <div className="">
                            <p>① 禁止未经女舞客授权发布她人资料;</p>
                            <p>② 图片最好为舞厅灯效下，以免与实际差异过大;</p>
                            <p>③ 请女舞客留下联系方式，接受预约的须注明加微信价格;</p>
                            <p>④ 每账号仅发布1女舞客资料。可在删除个人条目后重新发布;</p>
                            <p>⑤ 禁止PS修图美化，严谨露点。</p>
                        </div>
                        <div className="grid-two">
                            <button className="btn btn-pink width-100 m-auto" onClick={e => showPersonalPublishError(e)}>新建</button>
                            <button className="btn btn-pink width-100 m-auto" onClick={e => onClose('personal-publish-notice')}>取消</button>
                        </div>
                    </div>
                </div>
            </div>  
        </Fragment>
    )
}

Navbar.propTypes = {
    auth: PropTypes.object.isRequired,
    user: PropTypes.object,
    userLoading: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,    
    setAlert: PropTypes.func.isRequired,
    changeAction: PropTypes.func.isRequired
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.user.user,
    userLoading: state.user.userLoading,
    alert: state.alert,
  });
  
  export default connect(mapStateToProps, { logout, setAlert, setCategory, changeAction })(Navbar);