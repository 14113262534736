import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';

import { updateUser } from '../../actions/user';
import { getDancerComments } from '../../actions/comment';
import { getDancer, addToFavDancers, removeFromFavDancers } from '../../actions/dancer';

import Spinner from '../layout/Spinner';
import NewCommentBox from './NewCommentBox';
import WeChatReserve from './WeChatReserve';
// import DancerPhotos from './DancerPhotos';

const Dancer = ({
  auth,
  dancer,
  dancerLoading,
  comments,
  commentsLoading,
  getDancerComments,
  getDancer,
  updateUser,
  user,
  userLoading,
  addToFavDancers, 
  removeFromFavDancers,
  isAuthenticated,
}) => {
  const { dancerID } = useParams();

  useEffect(() => {
      getDancer(dancerID);
  }, [getDancer, dancerID]);

  const [ photos, setPhotos] = useState([])

  // const {
  //   noMosaicPhotos,
  //   lightMosaicPhotos,
  //   heavyMosaicPhotos
  // } = photos;

  const [dancerComments, setDancerComments] = useState([])
  
  const vvipComments = dancerComments.filter(comment => comment.authorAccountLevel === 'VVIP')
  const otherComments = (dancerComments.filter(comment => comment.authorAccountLevel !== 'VVIP')).sort((a,b)=> b.score - a.score);

  useEffect(()=>{ 
    if(user && user.accountType === 'normal'){
      if(!commentsLoading){
        const filterdComments = comments.filter(comment => comment.dancerID === dancerID);
        // console.log(filterdComments)
        const vvipCommentsFirst = [
          ...filterdComments.filter(comment => comment.authorAccountLevel === 'VVIP'), 
          ...filterdComments.filter(comment => comment.authorAccountLevel === 'VIP'),
          ...filterdComments.filter(comment => comment.authorAccountLevel === '普通会员'),
        ]
        // console.log(vvipCommentsFirst)
        setDancerComments(vvipCommentsFirst.slice(0,2))
      }
    }else{
      if(!commentsLoading){
        setDancerComments(comments.filter(comment => comment.dancerID === dancerID))
      }
    }
    // if(!commentsLoading){setDancerComments(comments.filter(comment => comment.dancerID === dancerID))}
  },[comments, commentsLoading, user])
  // console.log(dancerID);


  // const [fileElements , setFileElements] = useState({
  //   listPhotoElement: "",
  //   photoElements: [],
  //   dancerPageFirstPhotoElement: "",
  //   videoElement: "",
  //   videoElements: []
  // })
  // useEffect(()=>{console.log(fileElements)},[fileElements])
  // const {listPhotoElement, photoElements, dancerPageFirstPhotoElement, videoElement,videoElements } = fileElements;


  useEffect(() => {
    getDancerComments(dancerID);
  }, [getDancerComments, dancerID]);

  useEffect(()=>{
  },[dancerComments])

  useEffect(()=>{
    if(dancer){
      const noMosaicPhotosPrestage = []
      const lightMosaicPhotosPrestage = []
      const heavyMosaicPhotosPrestage = []
      dancer.files.photos.map(photo => {
        noMosaicPhotosPrestage.push(photo);
        lightMosaicPhotosPrestage.push({order: photo.order, photo: `${(photo.photo).split('.')[0]}_M.${(photo.photo).split('.')[1]}`})
        heavyMosaicPhotosPrestage.push({order: photo.order, photo: `${(photo.photo).split('.')[0]}_HM.${(photo.photo).split('.')[1]}`})
        // lightMosaicPhotosPrestage.push(photo);
        // heavyMosaicPhotosPrestage.push({order: photo.order, photo: `${(photo.photo).split('_')[0]}_HM.${(photo.photo).split('.')[1]}`})
      })
      setTimeout(()=>{
        if(user){
          if(user.accountType === 'normal'){
            
            if(user.freeVipTimer){
              // console.log('light');
              setPhotos(lightMosaicPhotosPrestage)
            }else{
              // console.log('heavy');
              setPhotos(heavyMosaicPhotosPrestage)
            }
            
          // }else if(user.accountType === 'vip'){
          //   console.log('light')
          //   setPhotos(lightMosaicPhotosPrestage)
          // }else{
          //   console.log('no')
          //   setPhotos(noMosaicPhotosPrestage)
          // }
          }else if(user.accountType === 'super' || user.accountType === 'admin' || user.accountType === 'manager' || user.accountType === 'agent'){
            setPhotos(noMosaicPhotosPrestage)
          }else{
            setPhotos(lightMosaicPhotosPrestage)
          }
        }else{          
          // console.log('heavy')
          setPhotos(heavyMosaicPhotosPrestage)
        }
      },500)
    }
  },[dancer, dancerLoading, user, userLoading, auth])

  useEffect(()=>{
    // console.log(user ? user.accountType : 'no user')
  },[photos])

  // const { user.wantToReserveDancers } = user;

  const taggleFavoriteDancers = (e, id) => {
    // e.preventDefault();
    // console.log(user.user.wantToReserveDancers.includes(id))
    if(user.wantToReserveDancers.includes(id)){
      // console.log.log('remove')
      updateUser({
        ...user,
        wantToReserveDancers: user.wantToReserveDancers.filter(item => item !== id)
      })
      removeFromFavDancers(id)
    }else{ 
      
      // console.log('add')
      updateUser({
        ...user,
        wantToReserveDancers: [...user.wantToReserveDancers, id],
      });
      addToFavDancers(id)
    } 
  };
 
  function showLogin(){
    document.getElementById("login-box").classList.remove('display-none')
  }

  
  const onReserve = () => {
    if (auth.isAuthenticated) {
      if(user.accountType === 'normal'){
      const pleaseBuyVip = document.getElementById('please-buy-vip');
      pleaseBuyVip.classList.remove('display-none');
      }else{
        if(user.accountType === 'normal'){
          const pleaseBuyVip = document.getElementById('please-buy-vip');
          pleaseBuyVip.classList.remove('display-none');
        }else{
          document.getElementById(`wechat-reserve-${dancerID}`).classList.remove('display-none')
            
        }
      }
    }else{
      showLogin()
    }
  };


  const clothingDB = {
    F: '难操作',
    T: '好操作',
    S: '性感暴露',
  };
  const breastsDB = {
    B: '胸部偏小',
    C: '胸部适中',
    D: '胸部丰满',
    E: '爆乳',
  };
  const agesDB = ["0-20","21-25","26-30","31-35","36-40","41-50","50-99"]
  const heightDB = ["<=155","156-160","161-165","166-170",">170"]

  const showNewCommentBox = (id) => {
    // console.log(id);
    const commentBox = document.getElementById(id);
    auth.isAuthenticated ? commentBox.classList.remove('display-none') : showLogin();
  };

  // const commentsArray = (
  //   <div className="dancer-comments-array">
  //     <div className="comment-item"></div>
  //   </div>
  // );


  

  return dancerLoading ? (
    <Spinner />
  ) : (
    <div className="container">
      <div className="dancer">
        <img
          className="dancer-firstImg"
          src={photos.find(item => item.order === 0) ? `/uploads/photos/${photos.find(item => item.order === 0).photo}` : ''}
          alt="Dancer First"
          key={photos.find(item => item.order === 0) ? `dancerPageFirstPhoto-${photos.find(item => item.order === 0).photo}` : ''}
        />
        <div className="dancer-item-briefs">
          <div className="grid-four">
            {/* <p>总体评价</p> */}
            <p
              className="dancer-item-first-line first-item"
              key={`${dancerID}-overallRating`}
            >
              {dancer.overallRating}
            </p>
            {/* <p>颜值评分</p> */}
            <p
              className="dancer-item-first-line"
              key={`${dancerID}-beautifulness`}
            >
              颜值{dancer.beautifulness}
            </p>
            {/* <p>年龄范围</p> */}
            <p className="dancer-item-first-line" key={`${dancerID}-age`}>
            {agesDB[dancer.age]}岁
            </p>
            {/* <p>价格每曲</p> */}
            <p
              className="dancer-item-first-line text-info mr-0 ml-auto"
              key={`${dancerID}-price`}
            >
              {dancer.price}卡路里/曲
            </p>
          </div>
          <div className="flex-wrap">
            {/* <p>身高范围</p> */}
            <p className="mr-1" key={`${dancerID}-height`}>
            {heightDB[dancer.height - 4]}
            </p>
            {/* <p className='mr-1'>衣着</p> */}
            <p className="mr-1" key={`${dancerID}-clothing`}>
              衣着{clothingDB[dancer.clothing]}
            </p>
            {/* <p className='mr-1'>胸部</p> */}
            <p className="mr-1" key={`${dancerID}-breasts`}>
              {breastsDB[dancer.breasts]}
            </p>
            <p className="mr-1" key={`${dancerID}-body`}>
              {dancer.body}
            </p>
            {/* <p className='mr-1'>龙女</p> */}
            <p key={`${dancerID}-dragonGirl`}>
              {dancer.dragonGirl ? '龙女' : ''}
            </p>
          </div>
          <div className="flex-wrap">
            <p className="mr-1" key={`${dancerID}-showupClub`}>
              {dancer.showupClub}
            </p>
          {/* </div> */}
          {!userLoading && (user.accountType === 'super' || user.accountType === 'manager' || user.accountType === 'admin') ? 
            <Fragment>
              <p className="mr-half ml-auto text-white" key={`${dancerID}-dancerID`}>
                {dancer.dancerID}
              </p>
              <p className="mr-half ml-0 text-white" key={`${dancerID}-nickname`}>
                "{dancer.nickname ? dancer.nickname : ''}"
              </p> 
              <p className="mx-0 text-white" key={`${dancerID}-codeName`}>
                {dancer.codeName ? `${dancer.codeName.split('-')[0]}${dancer.codeName.split('-')[1]}${dancer.codeName.split('-')[2]}` : ''}
              </p>  
            </Fragment>
            : ""}
          </div>
          <div className="grid-two">
            <p className="mr-1" key={`${dancerID}-attitude`}>
              抢手度：<span className="text-white">{!userLoading && user.accountType !== 'normal' && dancer.hotness ? dancer.hotness : '--付费用户可见--'}</span>
            </p>
            <p className="mr-1" key={`${dancerID}-chidu`}>
              尺度：<span 
              className="text-white">{!userLoading && user.accountType !== 'normal' && dancer.chidu ? dancer.chidu : '--付费用户可见--'}</span>
            </p>
          </div>
          <div className="attendance-list">
            <p>目击报告：</p>
            {!dancerLoading && dancer.attendance.length > 0 ? 
              dancer.attendance.map(item => 
              <p className='text-white' key={`${dancerID}-attendance-${dancer.attendance.indexOf(item)}`}>
                <Moment format="YY年MM月DD日">{item.date}</Moment>前后 {item.club} {item.session}</p>
                // console.log(dancer.attendance.indexOf(item))
              ) 
              // <p>{dancer.attendance}</p>
              // console.log(dancer.attendance)
              :
              <p className="text-white">暂无目击纪录</p>
            }
          </div>
        </div>
        <div className="reserve-btns">
          {/* 显示已添加该舞女进入收藏的总人数 */}
          <p className="want-to-reserve" key={`${dancerID}-favoratedTimes`}>
            已有{dancer.favoritedTimes ? dancer.favoritedTimes : 0}人收藏
          </p>
          {!userLoading && user.wantToReserveDancers.includes(dancer._id) ? (
            <button
              className="btn btn-disabled already-favorated-btn"
              onClick={e=>taggleFavoriteDancers(e, dancer._id)}
            >
              已收藏
            </button>
          ) : (
            <button
              className="btn reserve-btn"
              onClick={(e) => auth.isAuthenticated ? taggleFavoriteDancers(e, dancer._id) : showLogin()}
            >
              收藏
            </button>
          )}
          {!userLoading && user.accountType === 'normal' ? (
            <button className={'btn reserve-btn'} onClick={(e) => onReserve()}>
              预约
            </button>
          ) : (
            <button
              className={
                dancer.weChat ? 'btn reserve-btn' : 'btn reserve-btn btn-disabled'
              }
              onClick={(e) => onReserve()}
            >
              预约
            </button>
          )}
        </div>
        <div className="dancer-announcement">
          <p>舞女公告</p>
        </div>
        <div className="dancer-comments mb-2">
          <div className="dancer-comments-header">
            <h3 className="dancer-comments-title">舞女评论</h3>
            <button
              onClick={(e) =>
                showNewCommentBox(`dancer-${dancerID}-commentBox`)
              }
              className="btn btn-gold"
            >
              我要写评论
            </button>
          </div>
          {vvipComments.map((comment) => (
                <div className="comment-item" key={`comment-item-${comment._id}`}>
                  <div className="comment-item-title-grid">
                    {comment.authorAccountLevel === '普通会员' ? (
                      <p className="text-align-left">注册会员</p>
                    ) : (
                      <p className="text-align-left text-gold">
                        {comment.authorAccountLevel}
                      </p>
                    )}
                    <p className="text-align-center text-blue">
                      {comment.authorAccountLevel === 'VVIP' || !comment.authorNickname
                        ? '匿名会员'
                        : comment.authorNickname}
                    </p>
                    <p className="text-align-center">
                      <Moment format="YYYY/MM">
                        {comment.date}
                      </Moment>
                      {new Date(comment.date).getDate() <=15 ? '上旬' : '下旬'}
                    </p>
                    <p className="text-align-right ml-auto text-overallRating">
                      {comment.overallRating}
                    </p>
                  </div>
                  <div className="dancer-page-comment-item-content">
                    {user && user.accountType !== 'normal' ? <p>{comment.content}</p> : <p>{comment.content.slice(0,20)}<span className="text-small ml-2">————付费用户可见————</span></p>}
                    {/* {auth.isAuthenticated && user.accountType !== 'normal' ? <p>{comment.content}</p> : <p>{comment.content.slice(0,20)}... */}
                    {/* <span className='cursor-pointer text-small text-blue ml-2' onClick={e=>showLogin()}>————VIP可见————</span> */}
                    {/* <span className="gray-text text-small ml-2">————VIP可见————</span> */}
                    {/* </p>} */}
                  </div>
                </div>
              ))
            }
            {otherComments.map((comment) => (
                <div className="comment-item" key={`comment-item-${comment._id}`}>
                  <div className="comment-item-title-grid">
                    {comment.authorAccountLevel === '普通会员' ? (
                      <p className="text-align-left">注册会员</p>
                    ) : (
                      <p className="text-align-left text-gold">
                        {comment.authorAccountLevel}
                      </p>
                    )}
                    <p className="text-align-center text-blue">
                      {comment.authorAccountLevel === 'VVIP' || !comment.authorNickname
                        ? '匿名会员'
                        : comment.authorNickname}
                    </p>
                    <p className="text-align-center">
                      <Moment format="YYYY/MM">
                        {comment.date}
                      </Moment>
                      {new Date(comment.date).getDate() <=15 ? '上旬' : '下旬'}
                    </p>
                    <p className="text-align-right ml-auto text-overallRating">
                      {comment.overallRating}
                    </p>
                  </div>
                  <div className="dancer-page-comment-item-content">
                    {user && user.accountType !== 'normal' ? <p>{comment.content}</p> : <p>{comment.content.slice(0,20)}<span className="text-small ml-2">————付费用户可见————</span></p>}
                    {/* {auth.isAuthenticated && user.accountType !== 'normal' ? <p>{comment.content}</p> : <p>{comment.content.slice(0,20)}... */}
                    {/* <span className='cursor-pointer text-small text-blue ml-2' onClick={e=>showLogin()}>————VIP可见————</span> */}
                    {/* <span className="gray-text text-small ml-2">————VIP可见————</span>
                    </p>} */}
                  </div>
                </div>
              ))
            }
          {/* <button className="btn-link text-blue">{'>>>更多评论'}</button> */}
        </div>
        <div className="dancer-files">
          {dancer.files.video ? (
            <video
              controls
              className="dancer-video"
              key={`video-${dancer.files.video}`}
            >
              <source
                src={`/uploads/videos/${dancer.files.video}`}
                type="video/mp4"
              />
            </video>
          ) : (
            ''
          )}
          {dancer.files.videos.length > 0
            ? dancer.files.videos.map((video) => {
                return (
                  <video
                    controls
                    className="dancer-video"
                    key={`videos-${video}`}
                  >
                    <source
                      src={`/uploads/videos/${video}`}
                      type="video/mp4"
                    />
                  </video>
                );
              })
            : ''}
          {/* {photos.length > 0 ? photos.sort(function(a,b){return a.order - b.order;}).map(item => */}
          {photos.length > 0 ? photos.map(item =>
              <img
                className="dancer-photos"
                src={`/uploads/photos/${item.photo}`}
                alt={item.photo}
                key={`dancer-photos-${item.photo}`}
              /> 
            ) : ""}
        </div>
        
      </div>
      {/* {dancer._id ? (*/}
      <NewCommentBox elementID={`dancer-${dancerID}-commentBox`}/>
      {/*}) : (
        ''
      )} */}
      {/* {console.log('dancer._id: ', dancer._id)} */}
      <WeChatReserve dancerID={dancerID} weChat={dancer.weChat} key={dancerID}/>
    </div>
  );
};

Dancer.propTypes = {
  getDancerComments: PropTypes.func.isRequired,
  getDancer: PropTypes.func.isRequired,
  dancer: PropTypes.object,
  user: PropTypes.object,
  userLoading: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  comments: PropTypes.array.isRequired,
  commentsLoading: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  addToFavDancers: PropTypes.func.isRequired, 
  removeFromFavDancers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user.user,
  userLoading: state.user.userLoading,
  dancer: state.dancer.dancer,
  dancerLoading: state.dancer.dancerLoading,
  comments: state.comment.comments,
  commentsLoading: state.comment.commentsLoading,
});

export default connect(mapStateToProps, { getDancer, updateUser, getDancerComments,addToFavDancers, removeFromFavDancers})(
  Dancer
);
