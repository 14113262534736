import { filterDancers } from '../actions/dancer';
import {
  GET_DANCER,
  GET_DANCERS,
  SET_DANCER,
  NEW_DANCER,
  DANCER_ERROR,
  DANCERS_ERROR,
  DELETE_DANCER,
  HIDE_DANCER,
  ADD_TO_FAVORITE,
  REMOVE_FROM_FAVORITE,
  UPLOAD_PHOTOS,
  UPLOAD_VIDEOS,
  FILTER_DANCERS,
  GET_DANCERS_COUNT,
  GET_DANCER_FOR_EDITTING_IN_DANCERS,
  SHOW_FAVORITE_DANCERS,
  FILTER_DANCERS_ERROR,
  RESET_DANCERS_UPDATED,
  CHECK_ATTENDANCE
} from '../actions/types';
import { differentInDays } from '../functions/functions';


const initialState = {
  dancer: null,
  dancers: [],
  dancersUpdated: false,
  // dancers: [{_id: "D00001"},{_id: "D00002"},{_id: "D00003"},{_id: "D00004"}],
  filteredDancers: [],
  dancerLoading: true,
  dancersLoading: true,
  uploadedVideoExt: '',
  dancersCount: 0,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DANCER:
      return {
        ...state,
        dancer: payload,
        dancerLoading: false,
      };
    case SET_DANCER:
      return {
        ...state, 
        dancer: state.dancers.find(dancer => dancer.dancerID === payload),
        dancerLoading: false
      }
    case NEW_DANCER:
      return {
        ...state,
        dancer: payload,
        dancers: [...state.dancers, payload],
        dancerLoading: false
      }
    // case GET_DANCERS_COUNT:
    //   return {
    //     ...state,
    //     dancersCount: payload
    //   }
    
    case GET_DANCER_FOR_EDITTING_IN_DANCERS:

      return {
        ...state,
        filteredDancers: state.dancers.filter(dancer => dancer.nickname.includes(payload) || `${dancer.codeName.split('-')[0]}${dancer.codeName.split('-')[1]}${dancer.codeName.split('-')[2]}`.includes(payload) || dancer.dancerID.includes(payload))
      }
    case SHOW_FAVORITE_DANCERS:
      return {
        ...state, 
        filteredDancers: state.dancers.filter(item => payload.includes(item._id))
      }
    case GET_DANCERS:
    //   filterDancers(null, {
    //     showupClub: [],
    //     overallRating: [],
    //     beautifulness: [6,10],
    //     age: [18,45],
    //     height: [156,177],
    //     clothing: ['F','T','S'],
    //     breasts: ['B','C','D','E'],
    //     dragonGirl: null,
    //     weChat: null,
    //     favorite: null
    // })
      return {
        ...state,
        dancers: payload.sort((a, b) => {
          // console.log(a.beautifulness,b.beautifulness)
          return b.hotness - a.hotness;
        }),
        dancersUpdated: true,
        // filteredDancers: payload.sort((a, b) => {
        //   // console.log(a.beautifulness,b.beautifulness)
        //   return b.hotness - a.hotness;
        // }),
        dancersLoading: false,
      };
    case RESET_DANCERS_UPDATED:
      return {
        ...state,
        dancersUpdated: false
      }
    case DELETE_DANCER:
      return {
        ...state,
        dancers: state.dancers.filter((dancer) => dancer._id !== payload),
        dancer: null,
        dancerLoading: true,
        dancersLoading: false,
      };
    // case UPLOAD_PHOTOS:
    // case UPLOAD_VIDEOS:
    //   setAlert(payload, 'success');
    //   setTimeout(()=>{window.close();},5000)      
    //   return {
    //     ...state,
    //     dancers: [...state.dancers, payload].sort((a, b) => {
    //       return b.hotness - a.hotness;
    //     }),
    //     dancersUpdated: true
    //   };
    case HIDE_DANCER:
      return {
        ...state,
        dancer: {
          ...state.dancer,
          hide: true,
        },
      };
    case DANCER_ERROR:
      return {
        ...state,
        dancer: null,
        dancerLoading: true,
        error: payload,
      };
    case DANCERS_ERROR:
      return {
        ...state,
        dancers: [],
        dancerLoading: true,
        error: payload,
      };

    case ADD_TO_FAVORITE:
    case REMOVE_FROM_FAVORITE:
      const oldDancer = state.dancers.find(item => item._id === payload._id);
      const oldFilteredDancer = state.filteredDancers.find(item => item._id === payload._id)
      return {
        ...state,
        dancer: payload,
        dancers: [
          ...state.dancers.slice(0, state.dancers.indexOf(oldDancer)), 
          payload, 
          ...state.dancers.slice(state.dancers.indexOf(oldDancer) + 1, state.dancers.length)
          // filter((item) => item._id !== payload._id),
          // payload
        ],
        filteredDancers: [
          ...state.filteredDancers.slice(0, state.filteredDancers.indexOf(oldFilteredDancer)), 
          payload, 
          ...state.filteredDancers.slice(state.filteredDancers.indexOf(oldFilteredDancer) + 1, state.filteredDancers.length)
        ]
      };
      
    case FILTER_DANCERS:

      // console.log(payload)

      const breastsDB = {
        B: 1,
        C: 2,
        D: 3,
        E: 4
      }

      const dancers1 = state.dancers.filter(dancer=> dancer.overallRating === "完美舞女").sort((a, b) => {
        return b.beautifulness - a.beautifulness;
      });
      // console.log('dancers1:',dancers1)
      let dancers1final = [];
      for(let i = 10; i>=5; i--){
        const dancersWithGivenBeautfulness = dancers1.filter(dancer => dancer.beautifulness === i)
        // console.log('i=', i , 'dancersWithGivenBeautfulness:',dancersWithGivenBeautfulness)

        if(dancersWithGivenBeautfulness.length > 0){
          const sortedVer = dancersWithGivenBeautfulness.sort((a,b)=>{ return breastsDB[b.breasts] - breastsDB[a.breasts]})
          dancers1final = dancers1final.concat(sortedVer)
        }
        // i -= 1;
      }
      // console.log('dancers1final:',dancers1final)


      const dancers2 = state.dancers.filter(dancer=> dancer.overallRating === "值得常跳").sort((a, b) => {
        return b.beautifulness - a.beautifulness;
      });
      // console.log('dancers2:',dancers2)
      let dancers2final = [];
      for(let i = 10; i>=5; i--){
        const dancersWithGivenBeautfulness = dancers2.filter(dancer => dancer.beautifulness === i)
                // console.log('i=', i , 'dancersWithGivenBeautfulness:',dancersWithGivenBeautfulness)

        if(dancersWithGivenBeautfulness.length > 0){
          const sortedVer = dancersWithGivenBeautfulness.sort((a,b)=>{ return breastsDB[b.breasts] - breastsDB[a.breasts]})
          dancers2final = dancers2final.concat(sortedVer)
        }
        // i -= 1;
      }
      // console.log('dancers2final:',dancers2final)


      const dancers3 = state.dancers.filter(dancer=> dancer.overallRating === "值得尝试").sort((a, b) => {
        return b.beautifulness - a.beautifulness;
      });
      // console.log('dancers3:',dancers3)
      let dancers3final = [];
      for(let i = 10; i>=5; i--){
        const dancersWithGivenBeautfulness = dancers3.filter(dancer => dancer.beautifulness === i)
                // console.log('i=', i , 'dancersWithGivenBeautfulness:',dancersWithGivenBeautfulness)

        if(dancersWithGivenBeautfulness.length > 0){
          const sortedVer = dancersWithGivenBeautfulness.sort((a,b)=>{ return breastsDB[b.breasts] - breastsDB[a.breasts]})
          dancers3final = dancers3final.concat(sortedVer)
        }
        // i -= 1;
      }
      // console.log('dancers3final:',dancers3final)


      const dancers4 = state.dancers.filter(dancer=> dancer.overallRating === "一次即可").sort((a, b) => {
        return b.beautifulness - a.beautifulness;
      });
      // console.log('dancers4:',dancers4)
      let dancers4final = [];
      for(let i = 10; i>=5; i--){
        const dancersWithGivenBeautfulness = dancers4.filter(dancer => dancer.beautifulness === i)
                // console.log('i=', i , 'dancersWithGivenBeautfulness:',dancersWithGivenBeautfulness)

        if(dancersWithGivenBeautfulness.length > 0){
          // console.log('dancersWithGivenBeautfulness.length > 0')
          const sortedVer = dancersWithGivenBeautfulness.sort((a,b)=>{ return breastsDB[b.breasts] - breastsDB[a.breasts]})
          // console.log('sortedVer: ', sortedVer);
          dancers4final = [...dancers4final, ...sortedVer]
        }
        // i -= 1;
      }
      // console.log('dancers4final:',dancers4final)

      // setTimeout(()=>{
        let results = [...dancers1final, ...dancers2final, ...dancers3final, ...dancers4final];

        // console.log('results:', results);
        const {wantToReserveDancers, formData} = payload;
        const {
          showupClub,
          overallRating,
          beautifulness,
          age,
          height,
          clothing,
          breasts,
          dragonGirl,
          weChat,
          favorite,
          period,
          body
        } = formData;
        // console.log(breasts)

        function hasIntersection(dancerItem) {
          const clubs = dancerItem.showupClub.split('/');
          let value = false;
          let i = 0;
          clubs.forEach((club) => {
            if (showupClub.includes(club)) {
              value = true;
            }
            i += 1;
          });
          return value;
        }
        
        if(favorite !== null && wantToReserveDancers && wantToReserveDancers.length > 0){
          favorite === true
          ? results = results.filter( result => wantToReserveDancers.includes(result._id) )
          : results = results.filter( result => !wantToReserveDancers.includes(result._id) )
        }
            // console.log('results:', results);
        
        if (showupClub.length > 0) {
          results = results.filter((result) => {
            return hasIntersection(result);
          });
          // console.log('results:', results);
        }

        if (overallRating.length > 0) {
          results = results.filter((item) =>
            overallRating.includes(item.overallRating)
          );
          // console.log('results:', results);
        }

        if (beautifulness.length > 0) {
          // console.log(beautifulness);
          results = results.filter((item) => {

            return (
              item.beautifulness >= beautifulness[0] &&
              item.beautifulness <= beautifulness[1]
            );
          });
          
          // console.log('results:', results);
        }
        if (age.length > 0) {
          // if(age[0] === 6 ){
          //   results = results.filter(item => parseInt(item.age) >=6)
          // }else if(age[1] === 0){


          // }else{
            results = results.filter(item => parseInt(item.age) >= parseInt(age[0]) && parseInt(item.age) <= parseInt(age[1]))
          // }
          
            // console.log('results:', results);
        }
          
        //   if (age[1] === 51 || age[1] === 52) {
        //     results = results.filter(
        //       (item) => parseInt(item.age.split('/')[0]) >= parseInt(age[0])
        //     );
        //   } else {
        //     results = results.filter(
        //       (item) =>
        //       parseInt(item.age.split('/')[1]) >= parseInt(age[0]) && parseInt(item.age.split('/')[0]) <= parseInt(age[1])
        //         // (
        //         //   parseInt(item.age.split('/')[0]) >= parseInt(age[0]) &&
        //         //   parseInt(item.age.split('/')[1]) <= parseInt(age[1])
        //         // ) || (
        //         //   parseInt(item.age.split('/')[0]) <= parseInt(age[0]) &&
        //         //   parseInt(item.age.split('/')[1]) >= parseInt(age[1])
        //         // )
        //     );
        //   }
        // }

        if (height.length > 0) {
          results = results.filter( item => parseInt(item.height) >= height[0] && parseInt(item.height) <= height[1])
          
          // console.log('results:', results);
        }
        //   if(parseInt(height[0])===149){
        //     results = results.filter(
        //       item => parseInt(item.height.split('/')[1]) >= 0 && parseInt(item.height.split('/')[0]) <= parseInt(height[1])
        //     )
        //   }else{
        //     results = results.filter(
        //       (item) =>
        //       parseInt(item.height.split('/')[1]) >= parseInt(height[0]) && parseInt(item.height.split('/')[0]) <= parseInt(height[1])
        //       // (
        //       //   parseInt(item.height.split('/')[0]) >= parseInt(height[0]) &&
        //       //   parseInt(item.height.split('/')[1]) <= parseInt(height[1])
        //       // ) || (
        //       //   parseInt(item.height.split('/')[0]) <= parseInt(height[0]) &&
        //       //   parseInt(item.height.split('/')[1]) >= parseInt(height[1])
        //       // )
        //     );
        //   }
        // }
        if (clothing.length > 0) {
          results = results.filter((item) => clothing.includes(item.clothing));
        }
        if (breasts.length > 0) {
          // console.log(results)
          results = results.filter((item) => breasts.includes(item.breasts));
        }
        if(body.length > 0){
          results = results.filter((item) => body.includes(item.body));
        }

        if (dragonGirl !== null) {
          results = results.filter((item) => item.dragonGirl === dragonGirl);
        }
        if (weChat !== null) {
          weChat === true || weChat === 'true'
            ? (results = results.filter((item) => item.weChat === true || item.weChat === 'true'))
            : (results = results.filter((item) => item.weChat === false || item.weChat === 'false'));
        }

        // 筛选时效性
        const now = new Date();
            
        if(period !== 'all'){
            // const noAttendanceDancer = results.filter(item => item.attendance.length = 0)
            results = results.filter(item => {
              if(item.attendance.length > 0){
                //console.log(item.dancerID, item.attendance[0])
                //console.log(item.attendance[0].date)
                // console.log(`${item.attendance[0].date.slice(0,4)}-${item.attendance[0].date.slice(4,6)}-${item.attendance[0].date.slice(-2)}`)
                // const attendanceDate = new Date(`${item.attendance[0].date.slice(0,4)}-${item.attendance[0].date.slice(4,6)}-${item.attendance[0].date.slice(-2)}`);
                const attendanceDate = new Date(item.attendance[0].date)
                const days = differentInDays(now, attendanceDate);
                const daysToCompare = Math.pow(2, parseInt(period));
                //console.log(days, daysToCompare)

                switch(period){
                  case '1':
                  case 1:
                    return days <= 15
                  case '2':
                  case 2:
                    return days <= 30
                  case '3':
                  case 3:
                    return days <= 60
                  case '4':
                  case 4:
                    return days <= 90
                  case '5':
                  case 5:
                    return days <= 120
                  case '6':
                  case 6:
                    return days <= 150
                  case '7':
                  case 7:
                    return days <= 180
                  case '8':
                  case 8:
                    if(days > 180){console.log(period, 'attendance found: ', item.attendance[0], item.dancerID, days, days > 180)}
                    return days > 180
                  default:
                    return days <= 7 * Math.pow( 2, parseInt(period) )
                }
                // return days <= 7 * Math.pow( 2, parseInt(period) )

              }else{
                switch(period){
                  case '8':
                  case 8:
                    return true;
                  default:
                    return false;
                }
              }
          })
        }

          
        console.log('results: ', results)
        return {
          ...state,
          filteredDancers: results,
        };
      // }, 500)
      
    case FILTER_DANCERS_ERROR:
      // console.log(payload)
      return {
        ...state, 
        error: payload,
        filteredDancers: []
      }
    case CHECK_ATTENDANCE: 
      // let newDancers = state.dancers;
      // const indexOfDancer = state.dancers.indexOf(item => item._id === payload._id);
      // newDancers[indexOfDancer] = payload;
      return {
        ...state,
        // dancer: payload,
        dancers: [...state.dancers.filter(dancer => dancer._id !== payload._id), payload],
        // dancers: newDancers,
        dancersUpdated: true
      }
    default:
      return state;
  }
}
